import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import FetchService from "../Fetch/FetchService";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "app/shared/Constants";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export default class RegistryHistoryService {
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<TaskInstanceListDto>>({
            url: `${ApiPaths.QUA_API}/RegistryHistory`,
            paginationParams: params,
        });
    }

    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/RegistryHistory/export`,
            paginationParams,
            csvExport: true,
        });
    }
}
