import { faCircleXmark, faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { SelectActionsLastItemsModel, SelectActionsModel } from "app/components_v2/SelectWithActions/types";
import { Input } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { AssetFieldRangeModel } from "app/models/05-QUA/AssetModels/AssetFieldRangeModel";
import { MeasurementUnitModel } from "app/models/05-QUA/MeasurementUnitModels/MeasurementUnitModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import MeasurementUnitService from "app/services/05-QUA/MeasurementUnitService";
import { SecScreen } from "app/shared/Constants";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeasurementUnitModal } from "../MeasurementUnitModal/MeasurementUnitModal";
import { ErrorDynamicAssetModel } from "../models/AssetFormModel";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { DynamicFieldDropdownItems } from "app/components_v2/WizardTask/DynamicFields/DynamicFieldDropdownItems/DynamicFieldDropdownItems";
import { v4 } from "uuid";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { AssetCategory } from "../../AssetGrid/models/AssetGridModel";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";

type AssetDynamicFieldProps = {
    data: AssetFieldInsertModel;
    assetCategory: AssetCategory;
    disabled?: boolean;
    customerError: ErrorDynamicAssetModel;
    onChange: (data: AssetFieldInsertModel) => void;
    onDelete: (id: string) => void;
    measurementTypeOptions: MeasurementUnitModel[];
    onChangeMeasurementTypeOptions: (value: MeasurementUnitModel[]) => void;
};

export const AssetDynamicField: FC<AssetDynamicFieldProps> = ({
    onChange,
    data,
    disabled,
    onDelete,
    customerError,
    measurementTypeOptions,
    onChangeMeasurementTypeOptions,
    assetCategory,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { handleSuccessManager } = useSuccessManager();
    const { getErrorMessage } = useFetchErrors();

    const { assetDynamicField, label, assetFieldRange, id, assetFieldOption, isRequired } = data;
    const { errorLabel, errorMin, errorUnit, errorOption, errorMinMax } = customerError;
    const filteredAssetFieldsOptions = assetFieldOption.filter(({ isDeleted }) => !isDeleted);
    const assetPermission = assetCategory === "ASSET" ? SecScreen.ASSETS : SecScreen.ASSET_RANGE;

    const [measurementUnit, setMeasurementUnit] = useState<MeasurementUnitModel>({
        id: assetFieldRange?.fK_MeasurementUnit || 0,
        unit: assetFieldRange?.unit || assetFieldRange?.unit || "",
    });
    const [showSaveMeasurementUnitModal, setShowSaveMeasurementUnitModal] = useState(false);
    const [showDeleteMeasurementUnitModal, setShowDeleteMeasurementUnitModal] = useState(false);
    const [isLoadingDeleteMeasureUnitConfirmModal, setIsLoadingDeleteMeasureUnitConfirmModal] =
        useState<boolean>(false);

    const measurementUnitLastItems: SelectActionsLastItemsModel[] = [
        {
            title: t(TranslationKeys.ADD_MEASUREMENT_UNIT),
            icon: faPlus,
            onClick: () => {
                setMeasurementUnit({ id: 0, unit: "" });
                setShowSaveMeasurementUnitModal(true);
            },
            hidden: !hasPermissionToEdit(assetPermission),
        },
    ];

    const measurementUnitActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: ({ label, value }: OptionsSearch) => {
                setMeasurementUnit({ id: Number(value), unit: label });
                setShowSaveMeasurementUnitModal(true);
            },
            hidden: !hasPermissionToEdit(assetPermission),
        },
        {
            icon: faTrash,
            onClick: ({ label, value }: OptionsSearch) => {
                setMeasurementUnit({ id: Number(value), unit: label });
                setShowDeleteMeasurementUnitModal(true);
            },
            hidden: !hasPermissionToEdit(assetPermission),
        },
    ];

    const handleInputChange = (values: Partial<AssetFieldInsertModel>) => onChange({ ...data, ...values });

    const handleRangeChange = (values: Partial<AssetFieldRangeModel>) => {
        if (!assetFieldRange) return;
        onChange({ ...data, assetFieldRange: { ...assetFieldRange, ...values } });
    };

    const handleSaveMeasurementUnit = (value: MeasurementUnitModel) => {
        onChangeMeasurementTypeOptions([...measurementTypeOptions, value]);
        setShowSaveMeasurementUnitModal(false);
    };

    const handleEditMeasurementUnit = async (value: MeasurementUnitModel) => {
        const updatedMeasurementTypeOptions = measurementTypeOptions.map(({ id, unit }) => ({
            id: id === value.id ? value.id : id,
            unit: id === value.id ? value.unit : unit,
        }));
        onChangeMeasurementTypeOptions(updatedMeasurementTypeOptions);
        setShowSaveMeasurementUnitModal(false);
    };

    const handleDeleteMeasurementUnit = async () => {
        setIsLoadingDeleteMeasureUnitConfirmModal(true);
        const { status, getParsedError } = await MeasurementUnitService.Delete(measurementUnit.id);
        setIsLoadingDeleteMeasureUnitConfirmModal(true);

        const error = getErrorMessage(getParsedError());
        if (!status()) {
            handleToast({
                title: t(TranslationModals.CANNOT_DELETE_MEASUREMENT_UNIT_TOAST_TITLE),
                subtitle: error,
                variant: "danger",
                type: "alert",
            });
            return;
        }

        handleSuccessManager(t(TranslationModals.MEASUREMENT_UNIT_DELETED_TOAST));

        const filteredMeasurementTypeOptions = measurementTypeOptions.filter(({ id }) => id !== measurementUnit.id);
        onChangeMeasurementTypeOptions(filteredMeasurementTypeOptions);
        setShowDeleteMeasurementUnitModal(false);
    };

    const handleAddNewDropdrownItem = () => {
        onChange({
            ...data,
            assetFieldOption: [
                ...data.assetFieldOption,
                { id: v4(), dbId: null, name: "", fK_AssetField: 0, isDeleted: false },
            ],
        });
    };

    const handleChangeDropDownItem = (itemId: string, value: string) => {
        onChange({
            ...data,
            assetFieldOption: assetFieldOption.map((afo) => (afo.id === itemId ? { ...afo, name: value } : afo)),
        });
    };

    const handleDeleteItem = (itemId: string) => {
        const selectedItem = filteredAssetFieldsOptions.find(({ id }) => id === itemId);

        if (!selectedItem) return;

        const isSelectedItemFromDb = !!selectedItem.dbId;

        if (isSelectedItemFromDb) {
            onChange({
                ...data,
                assetFieldOption: assetFieldOption.map((afo) =>
                    afo.id === itemId ? { ...afo, isDeleted: true } : afo
                ),
            });
            return;
        }

        onChange({ ...data, assetFieldOption: assetFieldOption.filter((afo) => afo.id !== itemId) });
    };

    return (
        <>
            {showDeleteMeasurementUnitModal && (
                <ConfirmModal
                    title={t(TranslationModals.MEASUREMENT_UNIT_DELETE_TITLE)}
                    description={t(TranslationModals.MEASUREMENT_UNIT_DELETE_DESCRIPTION).replace(
                        "{0}",
                        measurementUnit.unit
                    )}
                    onConfirm={handleDeleteMeasurementUnit}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onClose={() => setShowDeleteMeasurementUnitModal(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    type="delete"
                    isLoading={isLoadingDeleteMeasureUnitConfirmModal}
                />
            )}
            {showSaveMeasurementUnitModal && (
                <MeasurementUnitModal
                    unit={measurementUnit}
                    variant="appcc"
                    onSave={handleSaveMeasurementUnit}
                    onEdit={handleEditMeasurementUnit}
                    onCancel={() => setShowSaveMeasurementUnitModal(false)}
                />
            )}
            <WhiteBox>
                <Collapsable
                    title={t(TranslationKeys.ASSET_FIELD_LABEL)}
                    background="transparent"
                    border="none"
                    variant="appcc"
                >
                    <div className="assetDynamicField">
                        {!disabled && (
                            <div className="assetDynamicField__delete-icon">
                                <FontAwesomeIcon
                                    className="deleteInputIcon"
                                    onClick={() => onDelete(id)}
                                    icon={faCircleXmark}
                                />
                            </div>
                        )}
                        <div className="assetDynamicField__title-input">
                            <div className="assetDynamicField__title__header">
                                <p className="assetDynamicField__title__header__text">
                                    {t(TranslationKeys.DATA_REPORT_TITLE)}
                                </p>
                                <CheckBox
                                    checked={isRequired}
                                    onChange={(isChecked) => handleInputChange({ isRequired: isChecked })}
                                    label={t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_REQUIRED)}
                                    htmlFor={`AssetDynamicField-${id}`}
                                />
                            </div>
                            <Input
                                value={t(label)}
                                onChange={(value) => handleInputChange({ label: value })}
                                placeholder={t(TranslationKeys.ASSET_FIELD_LABEL)}
                                errorMessage={errorLabel}
                                disabled={disabled}
                                focus={!data.dbId}
                            />
                        </div>
                        {assetFieldRange && assetDynamicField === "NUMBER" && (
                            <div className="assetDynamicField__container">
                                <div className="assetDynamicField__minMax">
                                    <div className="assetDynamicField__minMax__inputs">
                                        <Input
                                            value={assetFieldRange.min != null ? String(assetFieldRange.min) : ""}
                                            onChange={(value) =>
                                                handleRangeChange({
                                                    min: !value.length ? undefined : Number(value),
                                                })
                                            }
                                            label={t(TranslationKeys.ASSET_MIN)}
                                            placeholder={t(TranslationKeys.INPUT_TEXT_PLACEHOLDER_MIN_ASSET)}
                                            type="number"
                                            errorMessage={errorMin}
                                            disabled={disabled}
                                        />
                                        <Input
                                            value={assetFieldRange.max != null ? String(assetFieldRange.max) : ""}
                                            onChange={(value) =>
                                                handleRangeChange({
                                                    max: !value.length ? undefined : Number(value),
                                                })
                                            }
                                            label={t(TranslationKeys.ASSET_MAX)}
                                            placeholder={t(TranslationKeys.INPUT_TEXT_PLACEHOLDER_MAX_ASSET)}
                                            type="number"
                                            disabled={disabled}
                                        />
                                    </div>
                                    {!!errorMinMax.length && <ErrorMessage errorMessage={errorMinMax} />}
                                </div>
                                <div className="assetDynamicField__unit">
                                    <SelectWithActions
                                        actions={measurementUnitActions}
                                        lastItems={measurementUnitLastItems}
                                        label={t(TranslationKeys.ASSET_UNIT_OF_MEASURE)}
                                        placeholder={t(TranslationKeys.ASSET_UNIT_OF_MEASURE)}
                                        selectedValue={String(assetFieldRange?.fK_MeasurementUnit)}
                                        options={measurementTypeOptions.map(({ unit, id }) => ({
                                            label: unit,
                                            value: String(id),
                                        }))}
                                        onChange={({ label, value }) =>
                                            handleRangeChange({ unit: label, fK_MeasurementUnit: Number(value) })
                                        }
                                        disabled={disabled}
                                        errorMessage={errorUnit}
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                        )}

                        {assetDynamicField === "DROPDOWN" && (
                            <div className="assetDynamicField__dropdown">
                                <DynamicFieldDropdownItems
                                    hideAddNew={filteredAssetFieldsOptions.length > 10}
                                    items={filteredAssetFieldsOptions.map(({ dbId, name, id }) => ({
                                        id,
                                        label: name,
                                        dbId: dbId || undefined,
                                    }))}
                                    onAddNewItem={handleAddNewDropdrownItem}
                                    onChangeItem={handleChangeDropDownItem}
                                    onDeleteItem={handleDeleteItem}
                                    disabled={disabled}
                                    error={errorOption.map(({ errorLabel, id }) => ({
                                        id,
                                        errorMessage: errorLabel,
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                </Collapsable>
            </WhiteBox>
        </>
    );
};
