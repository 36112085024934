import { SortedTypeModel } from "app/components_v2/Table/types";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { IssueTypeCounters } from "app/dtos/05-QUA/Issue/IssueTypeCounters";
import { useToast } from "app/hooks/Toast/useToast";
import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import IssueService from "app/services/05-QUA/IssueService";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    INITIAL_ISSUE_SF_VALUES,
    INITIAL_ISSUE_SORT_DIRECTION,
    INITIAL_ISSUE_SORT_FIELD,
} from "../constants/issueConstants";
import { fillExtraparams } from "../helpers/fillExtraparams";
import { IssueGridFetchModel } from "../models/IssueGridModels";
import { IssueGridContext, IssueGridSFModel } from "../state/context/issueGridContext";
import { currentTabToIssueType } from "../helpers/currentTabToIssueType";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { useSession } from "app/hooks/useSession";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useSearchParams } from "react-router-dom";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";
import { isEqual } from "lodash";

const INITIAL_COUNTER_VALUES: IssueTypeCounters = {
    audit: 0,
    generic: 0,
    task: 0,
    taskAsset: 0,
};

export const useIssueGrid = () => {
    const { handleToast } = useToast();
    const session = useSession();
    const context = useContext(IssueGridContext);
    const { t } = useTranslation();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        pageIndex,
        currentTab,
        onChangePageIndex,
        onChangeCurrentTab,
        onSortChange,
        sortDirection,
        sortField,
        secondaryFilterValues,
        onSFChange,
        selectCompany,
        onCompanyChange,
    } = context;

    const [data, setData] = useState<IssueListDto[]>([]);
    const [counters, setCounters] = useState<IssueTypeCounters>(INITIAL_COUNTER_VALUES);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);
    const { query } = secondaryFilterValues;

    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query?.length);

    const taskInstanceId = searchParams.get("FK_TaskInstance");

    const getData = async ({ pi, tab, sortD, sortF, issueSf, selectedCompany }: IssueGridFetchModel = {}) => {
        setIsLoading(true);

        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const filterData = issueSf ? issueSf : secondaryFilterValues;
        const selectedCompanyFetch = selectedCompany !== undefined ? selectedCompany : selectCompany.company.value;
        const currentTabFech = tab !== undefined ? tab : currentTab;

        const extraParams = fillExtraparams({
            currentTab: currentTabToIssueType(currentTabFech),
            sortDirection: sortD || sortDirection,
            sortField: sortF || sortField,
            companyId: selectedCompanyFetch,
            ...filterData,
        });

        const issuesPromise = IssueService.GetData({
            pageIndex: pageIndexFetch,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams,
        });
        const countersPromise = IssueService.GetCounters({ extraParams });

        Promise.all([issuesPromise, countersPromise])
            .then(([issueSr, countersSr]) => {
                issueDataResponseManager(issueSr);
                counterDataResponseManager(countersSr);
            })
            .catch(() => {
                handleToast({
                    title: t(TranslationModals.ISSUES_TOAST_FAILED_LOAD_DATA),
                    variant: "danger",
                    type: "alert",
                });
                setData([]);
                setCounters(INITIAL_COUNTER_VALUES);
                setTotal(0);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const issueDataResponseManager = ({ status, data }: ServiceResponse<PaginatedList<IssueListDto>>) => {
        if (!status()) {
            handleToast({
                title: t(TranslationModals.ISSUES_TOAST_FAILED_LOAD_DATA),
                variant: "danger",
                type: "alert",
            });
            setData([]);
            setTotal(0);
            return;
        }
        setData(data.list);
        setTotal(data.count);
    };

    const counterDataResponseManager = ({ status, data }: ServiceResponse<IssueTypeCounters>) => {
        if (!status()) {
            setCounters(INITIAL_COUNTER_VALUES);
            return;
        }

        setCounters(data);
    };

    const handleCurrentTabChange = (tab: number) => {
        if (tab === currentTab) return;
        const newSfvalues: IssueGridSFModel = {
            ...secondaryFilterValues,
            assetId: INITIAL_ISSUE_SF_VALUES.assetId,
            auditInstanceId: INITIAL_ISSUE_SF_VALUES.auditInstanceId,
        };
        onSFChange(newSfvalues);
        getData({ pi: 0, tab, issueSf: newSfvalues });
        onChangePageIndex(1);
        onChangeCurrentTab(tab);
        onSortChange(INITIAL_ISSUE_SORT_FIELD, INITIAL_ISSUE_SORT_DIRECTION);
    };

    const handleSortChange = (sortF: string, sortD: SortedTypeModel) => {
        onSortChange(sortF, sortD);
        getData({ sortF, sortD });
    };

    const tabs: TabPropsReduced[] = [
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.task,
            text: t(TranslationKeys.ISSUE_TYPE_TASK_TAB),
            type: "dark",
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.taskAsset,
            text: t(TranslationKeys.ISSUE_TYPE_ASSET_TAB),
            type: "dark",
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.generic,
            text: t(TranslationKeys.ISSUE_TYPE_GENERIC_TAB),
            type: "dark",
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.audit,
            text: t(TranslationKeys.ISSUE_TYPE_AUDIT_TAB),
            type: "dark",
            hidden: !hasPermissionToView(session, SecScreen.AUDIT_INST_REV_NC),
        },
    ];

    const handleCleanFetch = () => {
        onSFChange({ query: "" });
        setShowSearchInput(false);
        getData({ issueSf: { ...INITIAL_ISSUE_SF_VALUES, query: "" }, pi: 0 });
        onChangePageIndex(1);
    };

    const onQuerySearch = () => {
        if (isLoading) return;
        if (!!query?.length) {
            getData({ issueSf: secondaryFilterValues, pi: 0 });
            onChangePageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraParams = fillExtraparams({
            currentTab: currentTabToIssueType(currentTab),
            sortDirection: sortDirection === "default" ? INITIAL_ISSUE_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_ISSUE_SORT_FIELD : sortField,
            companyId: selectCompany.company.value,
            ...secondaryFilterValues,
        });

        return IssueService.Export({
            query,
            extraParams,
            ...params,
        });
    };

    const handleCompanyChange = ({ label, value }: OptionModel) => {
        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onChangePageIndex(1);
        getData({ pi: 0, selectedCompany: value });

        if (value !== "-1") changeWorkingCompanyByCompanyId(Number(value));
    };

    const handleSaveSecondaryFilter = (values: IssueGridSFModel) => {
        const isEqualFilterData = isEqual(INITIAL_ISSUE_SF_VALUES, values);
        onSFChange(values);
        setIsSecondaryFilterOpen(false);
        getData({ pi: 0, issueSf: values });
        onChangePageIndex(1);

        if (isEqualFilterData) setSearchParams("");
    };

    const handleChangePageIndex = (pi: number) => {
        onChangePageIndex(pi);
        getData({ pi: pi - 1 });
    };

    useEffect(() => {
        if (!!taskInstanceId) {
            const filterData: IssueGridSFModel = { ...secondaryFilterValues, taskInstanceId };
            onSFChange(filterData);
            getData({ issueSf: filterData });
            return;
        }
        getData();
    }, []);

    return {
        ...context,
        data,
        total,
        isLoading,
        tabs,
        handleSortChange,
        showSearchInput,
        onQuerySearch,
        setShowSearchInput,
        handleCleanFetch,
        handleExport,
        selectCompany,
        handleCompanyChange,
        isSecondaryFilterOpen,
        setIsSecondaryFilterOpen,
        handleSaveSecondaryFilter,
        handleChangePageIndex,
    };
};
