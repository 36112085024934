import { RegexPatterns } from "app/shared/Constants";
import i18n from "app/translation/i18n";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";

const getMonthNames = () => [
    i18n.t(TranslationKeys.MONTH_NAME_JANUARY),
    i18n.t(TranslationKeys.MONTH_NAME_FEBRAURY),
    i18n.t(TranslationKeys.MONTH_NAME_MARCH),
    i18n.t(TranslationKeys.MONTH_NAME_APRIL),
    i18n.t(TranslationKeys.MONTH_NAME_MAY),
    i18n.t(TranslationKeys.MONTH_NAME_JUNE),
    i18n.t(TranslationKeys.MONTH_NAME_JULY),
    i18n.t(TranslationKeys.MONTH_NAME_AUGUST),
    i18n.t(TranslationKeys.MONTH_NAME_SEPTEMBER),
    i18n.t(TranslationKeys.MONTH_NAME_OCTOBER),
    i18n.t(TranslationKeys.MONTH_NAME_NOVEMBER),
    i18n.t(TranslationKeys.MONTH_NAME_DECEMBER),
];

const abbreviatedMonthNames = () => [
    i18n.t(TranslationKeys.MONTH_NAME_JANUARY_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_FEBRAURY_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_MARCH_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_APRIL_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_MAY_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_JUNE_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_JULY_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_AUGUST_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_SEPTEMBER_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_OCTOBER_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_NOVEMBER_SHORT),
    i18n.t(TranslationKeys.MONTH_NAME_DECEMBER_SHORT),
];

const days = () => [
    i18n.t(TranslationKeys.WEEK_SUNDAY),
    i18n.t(TranslationKeys.WEEK_MONDAY),
    i18n.t(TranslationKeys.WEEK_TUESDAY),
    i18n.t(TranslationKeys.WEEK_WEDNESDAY),
    i18n.t(TranslationKeys.WEEK_THURSDAY),
    i18n.t(TranslationKeys.WEEK_FRIDAY),
    i18n.t(TranslationKeys.WEEK_SATURDAY),
];

export const dayLetters = () => [
    i18n.t(TranslationKeys.DAY_WEEK_MONDAY_FIRST_LETTER),
    i18n.t(TranslationKeys.DAY_WEEK_TUESDAY_FIRST_LETTER),
    i18n.t(TranslationKeys.DAY_WEEK_WEDNESDAY_FIRST_LETTER),
    i18n.t(TranslationKeys.DAY_WEEK_THURSDAY_FIRST_LETTER),
    i18n.t(TranslationKeys.DAY_WEEK_FRIDAY_FIRST_LETTER),
    i18n.t(TranslationKeys.DAY_WEEK_SATURDAY_FIRST_LETTER),
    i18n.t(TranslationKeys.DAY_WEEK_SUNDAY_FIRST_LETTER),
];

const todayName = i18n.t(TranslationCommon.TODAY);
const yesterdayName = i18n.t(TranslationCommon.YESTERDAY);
export const getDayOfWeek = (dateString: string) => {
    const d = new Date(dateString);
    return days()[d.getDay()];
};

export const getMonthName = (dateString: string) => {
    const d = new Date(dateString);
    return getMonthNames()[d.getMonth()];
};

export const getDayMonthFormated = (dateString: string, lang = "es-ES") => {
    const d = new Date(dateString);
    const hoursAndMinutes = new Intl.DateTimeFormat(lang, {
        month: "long",
    }).format(d);

    return `${d.getDate()} de ${hoursAndMinutes}`;
};

export const getDayMonthFormatedUTC = (dateString: Date, lang = "es-ES") => {
    const hoursAndMinutes = new Intl.DateTimeFormat(lang, {
        month: "short",
    }).format(dateString);

    return `${dateString.getDate()} ${hoursAndMinutes}`;
};

export const getDayMonthAndHourFormated = (date: Date, lang = "es-ES") => {
    const hoursAndMinutes = new Intl.DateTimeFormat(lang, {
        hour: "2-digit",
        month: "long",
        minute: "2-digit",
        hourCycle: "h23",
    }).format(date);

    return `${date.getDate()} de ${hoursAndMinutes} h`;
};

export const getDayMonthAndHourFormatedShort = (date: Date, lang = "es-ES") => {
    const hoursAndMinutes = new Intl.DateTimeFormat(lang, {
        hour: "2-digit",
        month: "short",
        minute: "2-digit",
        hourCycle: "h23",
    }).format(date);

    return `${date.getDate()} ${hoursAndMinutes} h`;
};

export const getDayMonthFormatedWithYear = (dateString: string) => {
    const d = new Date(dateString);

    return i18n
        .t(TranslationKeys.FULL_DATE)
        .replace("{1}", `${d.getDate()}`)
        .replace("{2}", `${getMonthNames()[d.getMonth()]}`)
        .replace("{3}", `${new Date(dateString).getFullYear()}`);
};

export const getDayMonthFormatedWithYearAbbreviated = (dateString: string, separator = "-") => {
    const d = new Date(dateString);
    return `{1}${separator}{2}${separator}{3}`
        .replace("{1}", `${d.getDate()}`)
        .replace("{2}", `${abbreviatedMonthNames()[d.getMonth()]}`)
        .replace("{3}", `${new Date(dateString).getFullYear().toString().slice(-2)}`);
};

export const getDayMonthFormatedWithYearNumbers = (dateString: string, separator = "-", yearFormat?: "YYYY" | "YY") => {
    const yearSlice = yearFormat === "YY" || yearFormat == null ? -2 : 0;
    const d = new Date(dateString);
    return `{1}${separator}{2}${separator}{3}`
        .replace("{1}", `${checkIfIsUndertTen(d.getDate())}`)
        .replace("{2}", `${checkIfIsUndertTen(d.getMonth() + 1)}`)
        .replace("{3}", `${d.getFullYear().toString().slice(yearSlice)}`);
};

export const getFullDateWithTime = (dateString: string) => {
    const d = new Date(dateString);

    return `${d.getFullYear()}-${checkIfIsUndertTen(d.getMonth() + 1)}-${checkIfIsUndertTen(
        d.getDate()
    )}T${checkIfIsUndertTen(d.getHours())}:${checkIfIsUndertTen(d.getMinutes())}:${checkIfIsUndertTen(d.getSeconds())}`;
};
export const getFullDateWithTimeEndDate = (dateString: string, offset: number) => {
    const d = new Date(dateString);

    return `${d.getFullYear()}-${checkIfIsUndertTen(d.getMonth() + 1)}-${checkIfIsUndertTen(d.getDate())}T${
        23 - offset
    }:59:59`;
};

export const getFullDateWithTimeUTC = (date: Date) => {
    return `${date.getFullYear()}-${checkIfIsUndertTen(date.getMonth() + 1)}-${checkIfIsUndertTen(
        date.getDate()
    )}T${checkIfIsUndertTen(date.getHours())}:${checkIfIsUndertTen(date.getMinutes())}:${checkIfIsUndertTen(
        date.getSeconds()
    )}`;
};

export const getFullDateWithTimeReadable = (dateString: string, yearSliced?: boolean) => {
    const d = new Date(dateString);

    const year = yearSliced ? d.getFullYear().toString().slice(2) : d.getFullYear();

    return `${checkIfIsUndertTen(d.getDate())}/${checkIfIsUndertTen(d.getMonth() + 1)}/${year} 
     ${checkIfIsUndertTen(d.getHours())}:${checkIfIsUndertTen(d.getMinutes())}`;
};

export const getHoursFromDate = (dateString: string) => {
    const d = new Date(dateString);
    return `${checkIfIsUndertTen(d.getHours())}:${checkIfIsUndertTen(d.getMinutes())}`;
};

export const getHoursFromDateUTC = (date: Date) => {
    return `${checkIfIsUndertTen(date.getHours())}:${checkIfIsUndertTen(date.getMinutes())}`;
};

export const checkIfIsUndertTen = (day: number) => (day >= 10 ? day : `0${day}`);

export const checkIfIsUnderOneThousand = (day: number) => (day >= 999 ? day : day >= 10 ? `0${day}` : `00${day}`);

export const transformMinutesHoursToDate = (minuteHour: string) => {
    const minuteHourArray = minuteHour.split(":");

    const d = new Date().setMinutes(Number(minuteHourArray[1]));
    const d2 = new Date(d).setHours(Number(minuteHourArray[0]));

    return new Date(d2).toString();
};

export const getWeekDay = (dateString: string) => {
    const fecha = new Date(dateString);
    const firstMonthDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
    return Math.round((new Date(fecha).getDate() - new Date(firstMonthDay).getDate()) / 7 + 1);
};

export const getCurrentFullDate = () => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    return new Date().toLocaleString(i18n.languages[0], options);
};

// Transform a Date to the format: dd-MM-yyyy
export const getDateFormatted = (date: Date | undefined): string => {
    if (typeof date === "object" && date !== null && "getDate" in date) {
        const day = checkIfIsUndertTen(date.getDate());
        const month = checkIfIsUndertTen(date.getMonth() + 1);
        const year = date.getFullYear();
        return day + "-" + month + "-" + year;
    } else return "dd-MM-yyyy";
};
// Transform a Date to the format: yyyy-mm-dd
export const getDateFormattedFirstYear = (date: Date | undefined): string => {
    if (typeof date === "object" && date !== null && "getDate" in date) {
        const day = checkIfIsUndertTen(date.getDate());
        const month = checkIfIsUndertTen(date.getMonth() + 1);
        const year = date.getFullYear();
        return year + "-" + month + "-" + day;
    } else return "yyyy-MM-dd";
};

export const getDateFormattedSelects = (date: Date | undefined): string => {
    if (typeof date === "object" && date !== null && "getDate" in date) {
        const day = checkIfIsUndertTen(date.getDate());
        const month = checkIfIsUndertTen(date.getMonth() + 1);
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    } else return "yyyy-MM-dd";
};

export const getDateFormattedWithHoursSelects = (date: Date | undefined): string => {
    if (typeof date === "object" && date !== null && "getDate" in date) {
        const day = checkIfIsUndertTen(date.getDate());
        const month = checkIfIsUndertTen(date.getMonth() + 1);
        const year = date.getFullYear();
        const hour = checkIfIsUndertTen(date.getHours());
        const minutes = checkIfIsUndertTen(date.getMinutes());

        return `${year}-${month}-${day}T${hour}:${minutes}`;
    } else return "yyyy-MM-dd";
};

// Calculate days past
export const dateDiffInDays = (a: Date, b: Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const isTodayBetweenDates = (startDate?: Date, endDate?: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(0, 0, 0, 0);
    if (!startDate || !endDate) {
        return false;
    } else {
        return (
            (startDate <= today && today <= endDate) ||
            (startDate.getTime() === today.getTime() && today.getTime() === endDate.getTime())
        );
    }
};
// Time past since the start date of task
export const getTimePast = (start: Date) => {
    let timePastString = "";
    const deadlineDate: Date = new Date(start);
    const now = new Date();

    // Handle time when dates are from different month
    const days = dateDiffInDays(deadlineDate, now);
    if (days >= 1) {
        // Time past > 24h -> x Days
        timePastString += days + "d";
    } else {
        let timePast = now.getHours() - deadlineDate.getHours();
        if (timePast >= 1 && timePast < 24) {
            // Time past between 1 and 24h
            timePastString += timePast + "h";
        } else if (timePast < 1) {
            // Time past below 1h
            timePast = now.getMinutes() - deadlineDate.getMinutes();
            timePastString += timePast + "min";
        }
    }
    return timePastString;
};

/** Returns the difference between the param date and now */
export const getTimeToNow = (start: Date) => {
    let timeToEnd = "";
    const deadlineDate = new Date(start);
    const now = new Date();

    const days = dateDiffInDays(now, deadlineDate);
    if (days >= 1) {
        // Time past > 24h -> x Days
        timeToEnd += days + "d";
    } else {
        let timeToFinish = deadlineDate.getHours() - now.getHours();
        if (timeToFinish >= 1 && timeToFinish < 24) {
            // Time past between 1 and 24h
            timeToEnd += timeToFinish + "h";
        } else if (timeToFinish < 1) {
            // Time past below 1h
            timeToFinish = deadlineDate.getMinutes() - now.getMinutes();
            timeToEnd += timeToFinish + "min";
        }
    }
    return timeToEnd;
};

// Transform a Date to the format: dd-mm-yyyy
export const getDateFormattedSeparator = (date: Date | undefined, separator?: string): string => {
    separator = separator || "-";
    if (typeof date === "object" && date !== null && "getDate" in date) {
        const day = checkIfIsUndertTen(date.getDate());
        const month = checkIfIsUndertTen(date.getMonth() + 1);
        const year = date.getFullYear();
        return day + separator + month + separator + year;
    } else return "dd-mm-yyyy";
};

export const getHourSecWithString = (date: Date, lastString?: string) => {
    return `${checkIfIsUndertTen(date.getHours())}:${checkIfIsUndertTen(date.getMinutes())}${
        lastString ? lastString : ""
    }`;
};

//  ADD months
export const addMonths = (date: Date, months: number) => {
    const dateCopy = new Date(date);

    dateCopy.setMonth(dateCopy.getMonth() + months);

    return dateCopy;
};

export const getDayMonthAndTimeFormatted = (incomingDate: Date, withoutTime?: boolean) => {
    const monthName = getMonthNames()[incomingDate.getMonth()];
    const time = getHoursFromDate(incomingDate.toString()) + "h";
    const today = new Date();
    const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    let formattedDateTime = "";

    if (getDateFormattedSeparator(incomingDate) === getDateFormattedSeparator(today))
        formattedDateTime = `${todayName} ${withoutTime ? "" : time}`;
    else if (getDateFormattedSeparator(incomingDate) === getDateFormattedSeparator(yesterday))
        formattedDateTime = `${yesterdayName} ${withoutTime ? "" : time}`;
    else formattedDateTime = `${incomingDate.getDate()} ${monthName} ${withoutTime ? "" : time}`;

    return formattedDateTime;
};

export const getDayMonthName = (date: string) => {
    const dateFormatted = new Date(date);
    return `${dateFormatted.getDate()} de ${getMonthNames()[dateFormatted.getMonth()]}`;
};

export const convertUTCtoLocaleDate = (utcDate: Date, offset?: number) => {
    const timeZoneOffset = (offset || new Date().getTimezoneOffset()) * 60000;
    const date2 = new Date(utcDate);
    const localDate = new Date(date2.getTime() - timeZoneOffset);

    return localDate;
};

export const validateDate = (date?: string | null) =>
    date && RegexPatterns.validDateFormat.test(date) ? new Date(date) : new Date();

export const getTimeZoneOffset = () => new Date(new Date().toISOString()).getTimezoneOffset() / -60;
export const isToday = (fecha: Date) => {
    const fechaActual = new Date();

    const esMismoDia =
        fechaActual.getFullYear() === fecha.getFullYear() &&
        fechaActual.getMonth() === fecha.getMonth() &&
        fechaActual.getDate() === fecha.getDate();

    return esMismoDia;
};

export const areDatesSameDay = (date1: Date, date2: Date): boolean => {
    const sameYear = date1.getFullYear() === date2.getFullYear();
    const sameMonth = date1.getMonth() === date2.getMonth();
    const sameDay = date1.getDate() === date2.getDate();
    return sameYear && sameMonth && sameDay;
};

export const formatDatesDateRange = (dates: Date[], offset?: number) => {
    dates[0]?.setHours(offset || 0);
    dates[1]?.setHours(offset || 0);
    const startDate = dates[0].toJSON().split("T")[0];
    const endDate = dates[1] ? dates[1]?.toJSON().split("T")[0] : undefined;

    return `${startDate};${endDate}`;
};

export const formatDatesSimpleDate = (date: Date, offset?: number) => {
    date?.setHours(offset || 0);
    return date.toJSON().split("T")[0];
};

export const formatDateWithHour = (date: Date) => {
    return date.toJSON();
};

export const getDateFromRangeString = (dateString: string, isStart?: boolean): Date | undefined => {
    if (!dateString && isStart) {
        return new Date();
    }
    if (!dateString && !isStart) {
        return undefined;
    }

    const dates = dateString.split(";").filter((date) => date);
    if (dates.length !== 2 && !isStart) {
        return undefined;
    }
    if (dates[1] === "undefined" && !isStart) return undefined;

    const startDate = new Date(dates[0]);
    const endDate = dates[1] ? new Date(dates[1]) : undefined;
    const date = isStart ? startDate : endDate;
    return date;
};

export const compareTwoDates = (date1?: Date, date2?: Date) => {
    if (!date1 || !date2) return false;
    const initialDate = new Date(date1);
    const finalDate = new Date(date2);

    return (
        initialDate.getFullYear() === finalDate.getFullYear() &&
        initialDate.getMonth() === finalDate.getMonth() &&
        initialDate.getDate() === finalDate.getDate()
    );
};

export const getFormatedFullDate = (value?: Date) => {
    const date = value ?? new Date();
    const formatter = new Intl.DateTimeFormat("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
    const formattedDate = formatter.format(date);
    return formattedDate;
};

export const getFormatedFullDatemultiple = (value?: Date, value2?: Date) => {
    const date = value ?? new Date();
    const date2 = value2 ?? new Date();
    const formatter = new Intl.DateTimeFormat("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
    const formattedDate = formatter.format(date);
    const formattedDate2 = formatter.format(date2);
    return `${formattedDate} - ${formattedDate2}`;
};

export const getFormatedFullDateAndHours = (value?: Date) => {
    const date = value ?? new Date();
    const formatter = new Intl.DateTimeFormat("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

    const formattedDate = formatter.format(date);
    return formattedDate;
};

/**
 * Calcula la diferencia entre dos fechas en términos de horas y minutos.
 *
 * @param {Date} date1 - La primera fecha.
 * @param {Date} date2 - La segunda fecha.
 * @returns {{ hours: number, minutes: number }} Un objeto con la diferencia en horas y minutos.
 *
 * @example
 * // Ejemplo de uso:
 * const date1 = new Date('2024-09-17T13:00:00');
 * const date2 = new Date('2024-09-18T10:12:00');
 * const result = getDiferencesBetweenTwoDates(date1, date2);
 * console.log(result); // { hours: 21, minutes: 12 }
 */
export const getDiferencesBetweenTwoDates = (
    date1: Date,
    date2: Date
): { days: number; minutes: number; hours: number } => {
    const differenceInMillisecs = date2.getTime() - date1.getTime();

    if (differenceInMillisecs < 0) return { days: 0, hours: 0, minutes: 0 };

    const differenceInDays = Math.floor(differenceInMillisecs / (1000 * 60 * 60 * 24));
    const differenceInHours = Math.floor((differenceInMillisecs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const differenceInMinutes = Math.floor((differenceInMillisecs % (1000 * 60 * 60)) / (1000 * 60));

    return { days: differenceInDays, hours: differenceInHours, minutes: differenceInMinutes };
};

/**
 * Transforma de una fecha en formato string al formato aceptado por el back
 * 'YYYY-MM-DDTHH:mm:ss'
 * **/
export const dateToDateStringWithHours = (dateStr: string) => {
    const date = new Date(dateStr);

    const formattedDate = new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }).formatToParts(date);

    const year = formattedDate.find((part) => part.type === "year")?.value;
    const month = formattedDate.find((part) => part.type === "month")?.value;
    const day = formattedDate.find((part) => part.type === "day")?.value;
    const hour = formattedDate.find((part) => part.type === "hour")?.value;
    const minute = formattedDate.find((part) => part.type === "minute")?.value;
    const second = formattedDate.find((part) => part.type === "second")?.value;

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

export const isValidDate = (dateString: string) => {
    const date = new Date(dateString);

    return date instanceof Date && !isNaN(date.getTime());
};
