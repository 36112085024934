export const auditHistoryUrlParamsValidator = (pathname: string, searcParams: URLSearchParams) => {
    const auditSearchParams = new URLSearchParams();
    const tab = searcParams.get("tab");
    const companyId = searcParams.get("companyId");
    const auditId = searcParams.get("auditId");

    if (companyId !== null && Number(companyId) !== 0 && !isNaN(Number(companyId)))
        auditSearchParams.append("companyId", companyId);

    if (auditId !== null && Number(auditId) !== 0 && !isNaN(Number(auditId)))
        auditSearchParams.append("auditId", auditId);

    if (tab !== null && Number(tab) !== 0 && !isNaN(Number(tab))) auditSearchParams.append("tab", tab);

    if (auditSearchParams.size === 0) return null;

    return { searcParams: auditSearchParams, url: `${pathname}?${auditSearchParams.toString()}` };
};
