import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { TaskHisotryGridSfModel, TaskHistoryGridContext } from "../../state/context/taskHistoryGridContext";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { useToast } from "app/hooks/Toast/useToast";
import { fillTaskHistoryExtraParams } from "../utilities/fillTaskHistoryExtraParams";
import { TaskInstanceService } from "app/services";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { PaginationDefaults, PrivatePaths, TASK_TYPE } from "app/shared/Constants";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useSession } from "app/hooks";
import { usePlanAPPCC } from "app/hooks/usePlanAPPCC";
import RegistryHistoryService from "app/services/05-QUA/RegistryHistoryService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";
import { isValidDate } from "app/helpers";
import { taskHistoryUrlParamsValidator } from "app/helpers/urlParamValidator/taskHistoryUrlParamsValidator";
import { INITIAL_TH_SF_VALUES } from "../../constants/taskHistoryConstants";

type THGridFetchModel = {
    pi?: number;
    sortF?: string;
    sortD?: SortedTypeModel;
    taskHistorySF?: TaskHisotryGridSfModel;
    company?: string;
    q?: string;
};

type CurrentPage = "task" | "plan";

export const useTaskHistoryGrid = () => {
    const { t } = useTranslation();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const { handleToast } = useToast();
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    const session = useSession();
    const language = session?.user.language;

    const {
        secondaryFilterValues,
        pageIndex,
        selectCompany,
        query,
        onQueryChange,
        onChangePageIndex,
        onCompanyChange,
        onSFChange,
    } = useContext(TaskHistoryGridContext);

    const [data, setData] = useState<TaskInstanceListDto[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSecondaryFilter, setShowSecondaryFilter] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [taskInstanceId, setTaskInstanceId] = useState<number>();
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [isExportModalVisible, setIsExportModalVisible] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const { getPlans, planOptions } = usePlanAPPCC();

    const currentPage: CurrentPage = pathname === `/${PrivatePaths.REGISTRY_HISTORY}` ? "plan" : "task";

    const titleRecord: Record<CurrentPage, string> = {
        task: t(TranslationTitles.TASK_HISTORY_PAGE_TITLE),
        plan: t(TranslationTitles.REGISTRY_HISTORY_PAGE_TITLE),
    };
    const subtitleRecord: Record<CurrentPage, string> = {
        task: t(TranslationKeys.TASKS),
        plan: t(TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE),
    };

    const title = `${titleRecord[currentPage]} ${
        selectCompany.company.value === "-1" ? `(${selectCompany.company.label})` : ""
    }`;
    const subtitle = `${total} ${subtitleRecord[currentPage]}`;

    const handleExport = async (csvParams?: IDownLoadCsvReturn) => {
        setIsExporting(true);
        setIsExportModalVisible(false);
        const selectedCompanyFetch = selectCompany.company.value;
        const planId = currentPage === "task" ? null : searchParams.get("planId") || "-1";

        const extraParams = fillTaskHistoryExtraParams({
            ...secondaryFilterValues,
            companyId: selectedCompanyFetch,
            planId,
        });
        const paginationParams: PaginationParams = {
            ...csvParams,
            extraParams,
            query,
        };

        currentPage === "plan"
            ? await RegistryHistoryService.GetDataExport(paginationParams)
            : await TaskInstanceService.GetDataExport(paginationParams);
        setIsExporting(false);
    };

    const getTaskInstances = async ({ pi, company, taskHistorySF, q }: THGridFetchModel) => {
        setIsLoading(true);
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const filterData = taskHistorySF ?? secondaryFilterValues;
        const selectedCompanyFetch = company ?? selectCompany.company.value;
        const queryFetch = q !== undefined ? q : query;
        const planId = currentPage === "task" ? null : searchParams.get("planId") || "-1";

        const extraParams = fillTaskHistoryExtraParams({
            companyId: selectedCompanyFetch,
            planId,
            ...filterData,
        });

        const paginationParams: PaginationParams = {
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexFetch,
            extraParams,
            query: queryFetch,
        };

        const {
            data: taskInstanceData,
            error,
            status,
        } = currentPage === "plan"
            ? await RegistryHistoryService.GetData(paginationParams)
            : await TaskInstanceService.GetTaskHistoryData(paginationParams);

        if (!status()) {
            console.error(error);
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(taskInstanceData.list);
        setTotal(taskInstanceData.count);
        setIsLoading(false);
    };

    const handleCleanFetch = () => {
        setShowSearchInput(false);
        getTaskInstances({ q: "", pi: 0 });
        onQueryChange("");
        onChangePageIndex(1);
    };

    const onQuerySearch = () => {
        if (isLoading) return;
        if (!!query.length) {
            getTaskInstances({ pi: 0 });
            onChangePageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleSaveSecondaryFilter = (values: TaskHisotryGridSfModel) => {
        onSFChange(values);
        setShowSecondaryFilter(false);
        getTaskInstances({ pi: 0, taskHistorySF: values });
        onChangePageIndex(1);
    };

    const onSelectCompany = ({ label, value }: OptionsSearch) => {
        if (value !== "-1") changeWorkingCompanyByCompanyId(Number(value));

        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onSFChange({ departmentId: "-1", userId: "-1", subDepartmentId: "-1" });
        onChangePageIndex(1);
        getTaskInstances({
            pi: 0,
            company: value,
            taskHistorySF: { ...secondaryFilterValues, departmentId: "-1", userId: "-1", subDepartmentId: "-1" },
        });
    };

    const openTask = (id: number) => {
        setIsModalOpened(true);
        setTaskInstanceId(id);
    };

    const handleChangePageIndex = (pi: number) => {
        onChangePageIndex(pi);
        getTaskInstances({ pi: pi - 1 });
    };

    const onChangeCurrentTab = (tab: number) => {
        if (tab === currentTab) return;
        if (isLoading) return;
        setCurrentTab(tab);
    };

    const handlePlanChange = (value: string) => {
        searchParams.set("planId", value);
        setSearchParams(searchParams, { replace: true });
        handleCleanFetch();
    };

    useEffect(() => {
        let taskHistoryParams: TaskHisotryGridSfModel = secondaryFilterValues;
        let companyIdParams = selectCompany.company.value;
        // Do not Refetch when changing the select plan
        if (searchParams.size === 1 && searchParams.get("planId") != null) return;
        if (!!searchParams.size) {
            const searchParamsValidated = taskHistoryUrlParamsValidator(searchParams);

            const deadlineDate = searchParamsValidated.get("DeadlineDate");
            const deadlineDate1 = searchParamsValidated.get("DeadlineDate1");
            const sporadicTasks = searchParamsValidated.get("SporadicTasks") === "true";
            const inTime = searchParamsValidated.get("InTime") === "true";
            const outOfTime = searchParamsValidated.get("OutOfTime") === "true";
            const companyId = searchParamsValidated.get("CompanyId");
            const taskId = searchParamsValidated.get("TaskId") || "";

            taskHistoryParams = {
                ...secondaryFilterValues,
                startDate:
                    !!deadlineDate?.length && isValidDate(deadlineDate)
                        ? new Date(deadlineDate)
                        : INITIAL_TH_SF_VALUES.startDate,
                endDate:
                    !!deadlineDate1?.length && isValidDate(deadlineDate1)
                        ? new Date(deadlineDate1)
                        : INITIAL_TH_SF_VALUES.endDate,
                taskType: sporadicTasks ? TASK_TYPE.SPORADIC : "-1",
                inTime,
                outOfTime,
                taskId,
            };
            onSFChange(taskHistoryParams);
            if (!!companyId) {
                const selectedCompanyParams = session?.user.companies?.find((c) => c.companyId === Number(companyId));
                if (selectedCompanyParams) {
                    companyIdParams = String(selectedCompanyParams.companyId);
                    onCompanyChange({
                        company: {
                            label: selectedCompanyParams.companyName,
                            value: String(selectedCompanyParams.companyId),
                        },
                    });
                }
            }
        }
        setSearchParams(new URLSearchParams(), { replace: true });
        getTaskInstances({ taskHistorySF: taskHistoryParams, company: companyIdParams });
    }, [searchParams]);

    useEffect(() => {
        if (currentPage === "plan") getPlans();
    }, [language]);

    return {
        data,
        handleCleanFetch,
        handleSaveSecondaryFilter,
        isLoading,
        isModalOpened,
        onCompanyChange,
        onQueryChange,
        onQuerySearch,
        pageIndex,
        query,
        secondaryFilterValues,
        selectCompany,
        setIsModalOpened,
        showSearchInput,
        showSecondaryFilter,
        taskInstanceId,
        total,
        setShowSecondaryFilter,
        setShowSearchInput,
        onSelectCompany,
        openTask,
        title,
        subtitle,
        handleChangePageIndex,
        handleExport,
        currentTab,
        onChangeCurrentTab,
        isExportModalVisible,
        setIsExportModalVisible,
        isExporting,
        selectedPlan: searchParams.get("planId") ?? "-1",
        currentPage,
        handlePlanChange,
        planOptions,
    };
};
