import { OptionsSearch } from "app/models/FormComponentsModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { TaskCaregoryTypes } from "app/shared/types/TaskCategoryTypes";
import { useState } from "react";

type DynamicFieldsError = {
    parsedError: string;
    isErrored: boolean;
};

export const useDynamicFields = () => {
    const [dynamicFieldsOptions, setDynamicFieldsOptions] = useState<OptionsSearch[]>([]);
    const [isLoadingDynamicFields, setIsLoadingDynamicFields] = useState<boolean>(false);

    const getDynamicFields = async ({
        taskCategory,
    }: {
        taskCategory: TaskCaregoryTypes;
    }): Promise<DynamicFieldsError> => {
        setIsLoadingDynamicFields(true);
        const { data, status, getParsedError } = await TarSelectorService.GetDynamicFields({
            extraParams: `TaskCategory=${taskCategory}`,
        });
        if (!status()) {
            setDynamicFieldsOptions([]);
            return {
                isErrored: true,
                parsedError: getParsedError(),
            };
        }

        setDynamicFieldsOptions(data.map(({ label }) => ({ label, value: label })));
        return {
            isErrored: false,
            parsedError: "",
        };
    };

    return {
        dynamicFieldsOptions,
        isLoadingDynamicFields,
        getDynamicFields,
    };
};
