import { useEffect, useState } from "react";
import { ComponentStatus } from "app/models/FormComponentsModel";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { useSession } from "./useSession";
import { TaskStatus } from "app/shared/Constants";
import { mergeProfileCompaniesAndDepartments } from "app/helpers/companyHelper";

export const useTaskInstance = (taskInstanceId?: number, onClose?: () => void) => {
    const session = useSession();

    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [taskInstance, setTaskInstance] = useState<TaskInstanceModel>();

    const fetchTaskInstanceData = async (taskInstanceId: number) => {
        setStatus("loading");
        const sr = await TaskInstanceService.GetOne(taskInstanceId);
        if (sr.status()) {
            setTaskInstance(sr.data);
            setStatus("complete");
        } else {
            console.error("Could not retrieve task instances");
            setStatus("errored");
            onClose && onClose();
        }
    };

    const isTaskInstanceEditable = ({
        editedBy,
        asignedToUserId,
        asignedToDepartmentId,
        companyId,
        statusCode,
    }: TaskInstanceModel) => {
        const myId = session?.user.id;
        const companies = mergeProfileCompaniesAndDepartments(
            session?.user?.companies,
            session?.user?.temporaryCompanies
        );
        const myCompanies = companies?.map(({ companyId }) => companyId);
        const myDepartments = companies?.flatMap(({ departments }) =>
            departments?.map(({ departmentId }) => departmentId)
        );

        const isTaskComplete = statusCode === TaskStatus.COMPLETED;
        const isEditedByOtherUser = editedBy != null && editedBy !== myId;
        const isAsignedByOtherUser = asignedToUserId != null && asignedToUserId !== myId;
        const isNotAsignedToMyCompanies = !myCompanies?.includes(companyId);
        const isNotAsignedToMyDepartments =
            asignedToDepartmentId != null && !myDepartments?.includes(asignedToDepartmentId);

        if (isTaskComplete) return false;
        if (isEditedByOtherUser || isAsignedByOtherUser) return false;
        if (isNotAsignedToMyCompanies || isNotAsignedToMyDepartments) return false;

        return true;
    };

    useEffect(() => {
        taskInstanceId && fetchTaskInstanceData(taskInstanceId);
    }, [taskInstanceId]);

    return {
        taskInstance,
        setTaskInstance,
        status,
        isTaskInstanceEditable,
    };
};
