import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QrGridContext } from "../state/context/qrGridContext";
import { WorkingPositionModel } from "app/models/02-TAR/WorkingPosition/WorkingPositionModel";
import { TabPropsReduced } from "app/components_v2/Tabs";
import WorkingPositionService from "app/services/02-TAR/WorkingPositionService";
import { PaginationDefaults } from "app/shared/Constants";
import { INITIAL_QR_GRID_SORT_DIRECTION, INITIAL_QR_GRID_SORT_FIELD } from "../constants/qrGridConstants";
import { TranslationKeys } from "app/translation/translationKeys";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type IFetch = {
    pi?: number;
    companyId?: string;
    tab?: number;
    sortD?: SortedTypeModel;
    sortF?: string;
};

export const useQrGrid = () => {
    const session = useSession();
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const {
        currentTab,
        filterIcon,
        onChangeCurrentTab,
        onChangePageIndex,
        onCompanyChange,
        onQueryChange,
        onSortChange,
        pageIndex,
        query,
        selectCompany,
        sortDirection,
        sortField,
    } = useContext(QrGridContext);

    const [data, setData] = useState<WorkingPositionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.ACTIVE_TAB),
            onClick: () => handleTabChange(0),
            type: "dark",
        },
        // {
        //     text: t(TranslationKeys.INACTIVE_TAB),
        //     onClick: () => onChangeTab(1),
        //     type: "dark",
        // },
    ];

    const handleSubmitQuery = () => {
        if (!query.length) {
            onQueryChange("");
            setShowSearchInput(false);
        }
        getQr({ pi: 0 });
        onChangePageIndex(1);
    };

    const handlePageIndexChange = (pi: number) => {
        onChangePageIndex(pi);
        getQr({ pi: pi - 1 });
    };

    const handleChangeSort = (sortF: string, sortD?: SortedTypeModel) => {
        onSortChange(sortF, sortD || INITIAL_QR_GRID_SORT_DIRECTION);
        getQr({ sortD: sortD || INITIAL_QR_GRID_SORT_DIRECTION, sortF });
    };

    const handleTabChange = (tabIndex: number) => {
        if (tabIndex === currentTab) return;
        onChangeCurrentTab(tabIndex);
        onChangePageIndex(1);
        onSortChange(INITIAL_QR_GRID_SORT_FIELD, INITIAL_QR_GRID_SORT_DIRECTION);
        setIsLoading(true);
        getQr({ pi: 0, tab: tabIndex });
    };

    const handleCompanyChange = ({ label, value }: OptionModel) => {
        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onChangePageIndex(1);
        getQr({ pi: 0, companyId: value });
    };

    const getQr = async ({ companyId, pi, tab, sortD, sortF }: IFetch = {}) => {
        setIsLoading(true);
        const pageIndexExtraparam = pi !== undefined ? pi : pageIndex - 1;
        const isDeletedTab = tab !== undefined ? tab : currentTab;
        const companyIdFetch = companyId || selectCompany.company.value;

        const {
            data: assetData,
            error,
            status,
        } = await WorkingPositionService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexExtraparam,
            query: query,
            sortDirection: sortD || sortDirection,
            sortField: sortF || sortField,
            extraParams: `${companyIdFetch !== "-1" ? `companyId=${companyIdFetch}&` : ""}IsDeleted=${
                isDeletedTab === 1 ? "true" : "false"
            }`,
        });

        if (!status()) {
            setIsLoading(false);
            handleToast({ title: error, variant: "danger" });
            return;
        }

        setData(assetData.list);
        setTotal(assetData.count);
        setIsLoading(false);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const companyIdFetch = selectCompany.company.value || session?.workingCompany?.companyId;
        return WorkingPositionService.GetDataExport({
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_QR_GRID_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_QR_GRID_SORT_FIELD : sortField,
            extraParams: `${companyIdFetch !== "-1" ? `companyId=${companyIdFetch}&` : ""}IsDeleted=${
                currentTab === 1 ? "true" : "false"
            }`,
            ...params,
        });
    };

    useEffect(() => {
        getQr();
    }, []);

    return {
        currentTab,
        data,
        filterIcon,
        handleChangeSort,
        handleCompanyChange,
        handleExport,
        handlePageIndexChange,
        handleSubmitQuery,
        isLoading,
        onCompanyChange,
        onQueryChange,
        pageIndex,
        query,
        selectCompany,
        setShowSearchInput,
        showSearchInput,
        sortDirection,
        sortField,
        tabs,
        total,
    };
};
