import { DynamicFieldsType, DynamicTaskFieldOption } from "app/models/02-TAR/TaskWizard";
import { v4 } from "uuid";

export const isDropdownDynamicField = (dynamicFieldsType: DynamicFieldsType) =>
    dynamicFieldsType === "DROPDOWN" || dynamicFieldsType === "DROPDOWN_MULTIPLE";

export const isCorrectIncorrectDynamicField = (dynamicFieldsType: DynamicFieldsType) =>
    dynamicFieldsType === "CORRECT_INCORRECT";

export const getCorrectIncorrectOptions = (): DynamicTaskFieldOption[] => [
    { id: v4(), label: "CORRECT" },
    { id: v4(), label: "INCORRECT" },
];
