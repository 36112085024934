import { auditHistoryUrlParamsValidator } from "app/helpers/urlParamValidator/auditHistoryUrlParamsValidator";
import { taskHistoryUrlParamsValidator } from "app/helpers/urlParamValidator/taskHistoryUrlParamsValidator";
import { LOCAL_STORAGE_ACTION_RECORD, PrivatePaths } from "app/shared/Constants";
import { useLocation, useSearchParams } from "react-router-dom";

export const useStoreLocalStorageUrlParamaters = () => {
    const [searcParams] = useSearchParams();
    const { pathname } = useLocation();
    const storeUrlParams = () => {
        if (storeQrCodeLocalStorage()) return;
        if (storePathchNotes()) return;
        if (storeTaskHisotry()) return;
        if (storeAudit()) return;
    };

    const storeQrCodeLocalStorage = () => {
        const pathSplitted = pathname.split("/").filter((x) => x);
        const qr = pathSplitted.pop();
        const currentPath = pathSplitted.join("/");
        const dashboardQrPath = `${PrivatePaths.DASHBOARD}/qr`;
        if (currentPath.toLowerCase() === dashboardQrPath.toLowerCase() && qr) {
            localStorage.setItem("urlParamType", LOCAL_STORAGE_ACTION_RECORD.QR);
            localStorage.setItem("qrCode", qr);
            return true;
        }
        return false;
    };

    const storePathchNotes = () => {
        const patchNoteVersionId = searcParams.get("patchNoteVersionId");

        if (patchNoteVersionId == null || patchNoteVersionId === "" || typeof Number(patchNoteVersionId) !== "number")
            return false;

        localStorage.setItem("urlParamType", LOCAL_STORAGE_ACTION_RECORD.PATCH_NOTES);
        localStorage.setItem("patchNoteVersionId", patchNoteVersionId);
        return true;
    };

    const storeTaskHisotry = () => {
        const isTaskHistory = pathname.toLowerCase().includes(PrivatePaths.TASK_HISTORY);
        if (!isTaskHistory) return false;

        const taskHistorySearchParams = taskHistoryUrlParamsValidator(searcParams);

        const taskHistorySearchParamsSize = taskHistorySearchParams.size;

        if (taskHistorySearchParamsSize === 0) return false;

        localStorage.setItem("urlParamType", LOCAL_STORAGE_ACTION_RECORD.TASK_HISTORY);
        localStorage.setItem("searchParamsTaskHistory", taskHistorySearchParams.toString());

        return true;
    };

    const storeAudit = () => {
        const isAudit = pathname.toLowerCase().includes(PrivatePaths.AUDIT_INST_MANAG);
        if (!isAudit) return false;

        const auditHistoryUrlParams = auditHistoryUrlParamsValidator(pathname, searcParams);

        if (auditHistoryUrlParams === null) return false;
        if (auditHistoryUrlParams.searcParams.size === 0) return false;

        localStorage.setItem("urlParamType", LOCAL_STORAGE_ACTION_RECORD.AUDIT_HISTORY);
        localStorage.setItem("searchParamsAuditHistory", auditHistoryUrlParams.searcParams.toString());
        localStorage.setItem("auditHistoryUrl", auditHistoryUrlParams.url);

        return true;
    };

    return { storeUrlParams };
};
