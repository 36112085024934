import { FC, FormEvent, useEffect, useState, KeyboardEvent } from "react";
import {
    NotificationDistributionListErrors,
    NotificationDistributionListFormValues,
    SelectedNotificationDistributionList,
} from "../../models/NotificationDistributionListFormValues";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useTranslation } from "react-i18next";
import {
    NotificationDistributionListTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { Input, Label, OptionsOutsideSelect, Switch } from "app/components_v2/__inputs";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { DistributionListFilterType } from "../../models/NotificationDistributionListGridModels";
import { useToast } from "app/hooks/Toast/useToast";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { getInitials } from "app/helpers/getInitials";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Tag } from "app/components_v2/Tag/Tag";
import { validateEmail } from "app/helpers/__validates/validateCompanyForm";
import { INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS } from "../../constants/NotificationDistributionListConstants";

type EditCreateNotificationDistributionListUsersModalProps = {
    modalTitle: string;
    notificationDistributionList: SelectedNotificationDistributionList;
    onClose: () => void;
    onSubmit: (values: NotificationDistributionListFormValues) => Promise<void>;
};

const formId = "editNotificationDistributionListUsers";

export const EditCreateNotificationDistributionListUsersModal: FC<
    EditCreateNotificationDistributionListUsersModalProps
> = ({ onClose, onSubmit, modalTitle, notificationDistributionList }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const { fK_Company, fK_DistributionList, userList, emails, functionality, companyName } =
        notificationDistributionList;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [addAllUsersModalOpen, setAddAllUsersModalOpen] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<NotificationDistributionListFormValues>({
        fK_DistributionList,
        fK_Company,
        userList,
        emails,
        companyList: [],
        notifyUsers: !!userList.length,
        notifyEmails: !!emails.length,
    });
    const [email, setEmail] = useState<string>("");
    const [notificationDistributionListErrors, setNotificationDistributionListErrors] =
        useState<NotificationDistributionListErrors>(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);

    const { userList: selectedUsers, emails: selectedEmails, notifyUsers, notifyEmails } = formValues;
    const { errorUserList, errorEmails, errorNotSelected } = notificationDistributionListErrors;

    const title = `${t(functionality)} ${
        functionality !== NotificationDistributionListTranslations.DISTRIBUTION_LIST_PATCH_NOTES
            ? ` (${companyName})`
            : ""
    }`;

    const distributionListFilter: Record<number, DistributionListFilterType> = {
        1: "SporadicTasksPermission",
        2: "AuditReportPermission",
    };

    const handleInputChange = (values: Partial<NotificationDistributionListFormValues>) => {
        setNotificationDistributionListErrors(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<NotificationDistributionListErrors>) => {
        setNotificationDistributionListErrors((prev) => ({ ...prev, ...values }));
    };

    const handleDeleteUser = (id: string) => {
        const user = selectedUsers.find(({ value }) => value === id);
        if (!user) return;

        handleInputChange({ userList: selectedUsers.filter(({ value }) => value !== id) });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSaving(true);
        await onSubmit({
            ...formValues,
            userList: notifyUsers ? selectedUsers : [],
            emails: notifyEmails ? selectedEmails : [],
        });
        setIsSaving(false);
    };

    const validate = () => {
        let isValid = true;

        if (!notifyUsers && !notifyEmails) {
            handleErrorChange({
                errorNotSelected: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            isValid = false;
        }
        if (notifyUsers && !selectedUsers.length) {
            handleErrorChange({ errorUserList: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }
        if (notifyEmails && !selectedEmails.length) {
            handleErrorChange({ errorEmails: t(TranslationErrors.ADD_AT_LEAST_ONE) });
            isValid = false;
        }

        return isValid;
    };

    const getUsers = async () => {
        setIsLoading(true);
        const supervisorParams = new URLSearchParams();
        if (session?.user.id) supervisorParams.append("userId", session?.user.id.toString());
        supervisorParams.append("includeMyself", "true");
        if (!!fK_Company.length) supervisorParams.append("CompaniesId", fK_Company);

        const key = !!fK_DistributionList.length ? Number(fK_DistributionList) : 0;
        if (key == 1 || key == 2) supervisorParams.append(distributionListFilter[key], "true");

        const { data, status, getParsedError } = await SegSelectorService.GetDistributionListUsers({
            extraParams: supervisorParams.toString(),
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), type: "notification", variant: "danger" });
            onClose();
            setIsLoading(false);
            return;
        }

        setUserOptions(data.map((d) => ({ ...d, initials: getInitials(d.label) })));
        setIsLoading(false);
    };

    const onConfirmAddAllUsers = () => {
        handleInputChange({
            userList: [
                ...selectedUsers,
                ...userOptions.filter((uo) => !selectedUsers.some((su) => uo.value === su.value)),
            ],
        });
        setAddAllUsersModalOpen(false);
    };

    const handleAddEmail = (e?: KeyboardEvent<HTMLInputElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (emails.includes(email)) {
            handleToast({ title: t(TranslationErrors.EMAIL_ALREADY_ADDED), variant: "danger", type: "alert" });
            return;
        }
        if (!validateEmail(email)) {
            handleErrorChange({ errorEmails: t(TranslationErrors.EMAIL_IS_INVALID) });
            return;
        }
        handleInputChange({ emails: [...selectedEmails, email] });
        setEmail("");
    };

    const handleDeleteEmail = (index: number) =>
        handleInputChange({ emails: selectedEmails.filter((_, i) => i !== index) });

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <GenericModal
            header={{ title: modalTitle, onClose }}
            size="md-tall"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.SAVE),
                        form: formId,
                        type: "submit",
                        disabled: isLoading,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "tertiary",
                        onClick: onClose,
                    }}
                    loading={isSaving}
                />
            }
        >
            <>
                {addAllUsersModalOpen && (
                    <ConfirmModal
                        onClose={() => setAddAllUsersModalOpen(false)}
                        onConfirm={onConfirmAddAllUsers}
                        onConfirmText={t(TranslationCommon.ACCEPT)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        title={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_TITLE)}
                        description={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_DESCRIPTION)}
                    />
                )}
                <form id={formId} onSubmit={handleSubmit} className="editNotificationDistributionListUsersModal">
                    <p className="editNotificationDistributionListUsersModal__title">{title}</p>
                    <div className="createNotificationDistributionListUsersModal__inputs__content">
                        <Switch
                            label={t(
                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_USERS
                            )}
                            checked={notifyUsers}
                            onChange={(value) => handleInputChange({ notifyUsers: value })}
                        />
                        {notifyUsers && (
                            <div className="editNotificationDistributionListUsersModal__label">
                                <div className="editNotificationDistributionListUsersModal__addAllUsers">
                                    <Label label={t(TranslationKeys.USERS)} />
                                    <AddNewButton onClick={() => setAddAllUsersModalOpen(true)} icon={faPlus}>
                                        {t(TranslationCommon.ADD_ALL)}
                                    </AddNewButton>
                                </div>
                                <OptionsOutsideSelect
                                    onChange={(value) => handleInputChange({ userList: value })}
                                    options={userOptions}
                                    onDeleteItem={handleDeleteUser}
                                    values={selectedUsers}
                                    placeholder={t(TranslationKeys.SELECT_USERS)}
                                    isLoading={isLoading}
                                    isMulti
                                    errorMessage={errorUserList}
                                    autoInitials
                                />
                            </div>
                        )}
                    </div>
                    <div className="createNotificationDistributionListUsersModal__inputs__content">
                        <Switch
                            label={t(
                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL
                            )}
                            checked={notifyEmails}
                            onChange={(value) => handleInputChange({ notifyEmails: value })}
                        />
                        {notifyEmails && (
                            <>
                                {selectedEmails.map((email, i) => (
                                    <Tag key={i} message={email} onClose={() => handleDeleteEmail(i)} variant="white" />
                                ))}
                                {selectedEmails.length < 20 && (
                                    <>
                                        <Input
                                            label={t(
                                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_LABEL
                                            )}
                                            placeholder={t(
                                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_PLACEHOLDER
                                            )}
                                            onChange={setEmail}
                                            errorMessage={errorEmails}
                                            onPressEnter={handleAddEmail}
                                            value={email}
                                        />
                                        <AddNewButton onClick={() => handleAddEmail()} icon={faPlus}>
                                            {t(TranslationCommon.ADD)}
                                        </AddNewButton>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {errorNotSelected && <ErrorMessage errorMessage={errorNotSelected} />}
                </form>
            </>
        </GenericModal>
    );
};
