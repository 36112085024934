import { CompanyTaskToReview } from "app/dtos/01-SEG/Company/CompanyTaskToReview";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";

export const mergeProfileCompaniesAndDepartments = (
    companies?: UserCompanyModel[],
    temporaryCompanies?: UserCompanyModel[]
): UserCompanyModel[] => {
    const mergedMap = new Map<number, UserCompanyModel>();

    const mergeDepartments = (
        existingDepartments?: UsrDepartmentModel[],
        newDepartments?: UsrDepartmentModel[]
    ): UsrDepartmentModel[] => {
        const departmentMap = new Map<number, UsrDepartmentModel>();
        [...(existingDepartments || []), ...(newDepartments || [])].forEach((dept) => {
            if (!departmentMap.has(dept.departmentId)) {
                departmentMap.set(dept.departmentId, dept);
            }
        });
        return Array.from(departmentMap.values());
    };

    [...(companies || []), ...(temporaryCompanies || [])].forEach((company) => {
        if (!mergedMap.has(company.companyId)) {
            mergedMap.set(company.companyId, {
                ...company,
                departments: company.departments || [],
            });
        } else {
            const existingCompany = mergedMap.get(company.companyId)!;
            existingCompany.departments = mergeDepartments(existingCompany.departments, company.departments);
        }
    });

    return Array.from(mergedMap.values());
};

export const filterDuplicateCompanies = (companies: CompanyTaskToReview[]): CompanyTaskToReview[] => {
    return companies.filter(
        (company, index, self) => index === self.findIndex((c) => c.companyId === company.companyId)
    );
};
