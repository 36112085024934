import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";

type validateTaskDetailDynamicFieldsReturn = {
    invalidFieldIds: number[];
    hasEmptyOptionals: boolean;
};
export const validateTaskDetailDynamicFields = (
    fields: TaskInstanceFieldModel[]
): validateTaskDetailDynamicFieldsReturn => {
    const invalidFieldIds: number[] = [];
    let hasEmptyOptionals: boolean = false;
    fields.forEach((field) => {
        if (field.taskInstanceFieldValues.length === 0) {
            if (!field.isRequired) {
                hasEmptyOptionals = true;
                return;
            }
            invalidFieldIds.push(field.id);

            return;
        }

        if (field.dynamicFieldType === "DROPDOWN" || field.dynamicFieldType === "DROPDOWN_MULTIPLE") {
            if (!field.taskInstanceFieldValues.some((value) => value.value === "true")) {
                if (!field.isRequired) {
                    hasEmptyOptionals = true;
                    return;
                }
                invalidFieldIds.push(field.id);
            }
            return;
        }

        if (
            !field.taskInstanceFieldValues.every(({ value }) => value !== "") &&
            field.dynamicFieldType !== "DROPDOWN_MULTIPLE_ASSET"
        ) {
            if (!field.isRequired) {
                hasEmptyOptionals = true;
                return;
            }
            invalidFieldIds.push(field.id);
        }

        if (
            !field.taskInstanceFieldValues.some(({ value }) => value === "true") &&
            field.dynamicFieldType === "DROPDOWN_MULTIPLE_ASSET"
        ) {
            if (!field.isRequired) {
                hasEmptyOptionals = true;
                return;
            }
            invalidFieldIds.push(field.id);
        }
    });

    return { invalidFieldIds, hasEmptyOptionals };
};
