import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CloseCompanyTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { PostLoginContext } from "../state/context/PostLoginContext";
import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { TaskReviewService } from "app/services";
import { useToast } from "app/hooks/Toast/useToast";
import { useUserType } from "app/hooks/useUserType";
import { useSession } from "app/hooks";
import { getDateFormattedSelects, getDayMonthFormatedWithYearNumbers } from "app/helpers/Date.utilities";
import { OptionCompanySkelleton } from "app/components_v2/__skelletons/OptionCompanySkelleton/OptionCompanySkelleton";
import { faQuestion, faSearch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { CompanyTaskToReview } from "app/dtos/01-SEG/Company/CompanyTaskToReview";
import { BadgeType } from "app/components_v2/Badge/badgeTypes";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { Input } from "../../../__inputs/Input/Input";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Actions } from "app/state/actions";
import { LOCAL_STORAGE_ACTION_KEYS, PrivatePaths } from "app/shared/Constants";
import { useGetUrlFromLocalStorage } from "app/hooks/useGetUrlFromLocalStorage";
import { filterDuplicateCompanies, mergeProfileCompaniesAndDepartments } from "app/helpers/companyHelper";

export const StepOne: FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const dispatch = useDispatch();
    const session = useSession();
    const { workingCompany } = useContext(PostLoginContext);
    const { handleToast } = useToast();
    const { isSupervisorOrAnalist } = useUserType();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const { getUrlFromLocalStorage } = useGetUrlFromLocalStorage();
    const companies = mergeProfileCompaniesAndDepartments(session?.user?.companies, session?.user?.temporaryCompanies);

    const sessionCompany = session?.workingCompany?.companyId;

    const [companyPendingTasks, setCompanyPendingTasks] = useState<CompanyTaskToReview[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [searchCompanyValue, setSearchCompanyValue] = useState<string>("");

    const handleCompanySearch = (newValue: string) => {
        setSearchCompanyValue(newValue);
    };

    const handleCompanySearchSubmit = (e: FormEvent) => {
        e.preventDefault();
        getCompanyPendingTasksToReview();
    };

    const isWorkingCompanyActive = (id: number) => {
        if (!workingCompany) return false;
        if (workingCompany.companyId === id) return true;
        if (!sessionCompany) return false;
        return sessionCompany === id;
    };

    const getCompanyPendingTasksToReview = async () => {
        setIsLoading(true);
        let extraParams = `deadlineDate=${getDateFormattedSelects(new Date())}`;
        if (!!searchCompanyValue.length) extraParams += `&query=${searchCompanyValue}`;

        const pendingTasksSr = await TaskReviewService.GetCompanyPendingTasksToReview(extraParams);

        if (!pendingTasksSr.status()) {
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            setCompanyPendingTasks([]);
            return;
        }
        setIsLoading(false);

        if (pendingTasksSr.data?.length === 1) {
            handleSelectCompany(pendingTasksSr.data[0].companyId);
            return;
        }
        setCompanyPendingTasks(filterDuplicateCompanies(pendingTasksSr.data));
    };

    const handleSelectCompany = (companyid: number) => {
        changeWorkingCompanyByCompanyId(companyid);

        dispatch(Actions.switchWizardMode(false));
        const initialScreenCode = session?.user?.initialScreenCode;
        const initialScreenCodePath = PrivatePaths[initialScreenCode as keyof typeof PrivatePaths];
        const goToUrl = getUrlFromLocalStorage();
        LOCAL_STORAGE_ACTION_KEYS.forEach((key) => localStorage.removeItem(key));

        nav(goToUrl || `/${initialScreenCodePath || PrivatePaths.DASHBOARD}`);
    };

    const getSelectedCompany = (company: CompanyTaskToReview) => {
        const selectedTask = companyPendingTasks.find((pendingTasks) => pendingTasks.companyId === company.companyId);
        const closeDate = getDayMonthFormatedWithYearNumbers(selectedTask?.closedDate?.toString() || "", "/");
        const openDate = getDayMonthFormatedWithYearNumbers(selectedTask?.openDate?.toString() || "", "/");
        const badge: BadgeType | undefined = selectedTask?.isClosed
            ? {
                  icon: faXmark,
                  title: t(CloseCompanyTranslations.COMPANY_CLOSED_DAYS).replace("{1}", `${closeDate} - ${openDate}`),
                  variant: "grey",
                  theme: "light",
                  outline: false,
              }
            : undefined;
        const count = session?.user.userType !== "WORKER" ? selectedTask?.pendingTasksToReview : 0;

        return {
            selectedTask,
            badge,
            count,
        };
    };

    useEffect(() => {
        getCompanyPendingTasksToReview();
    }, [session?.workingCompany]);

    return (
        <WizardForm validate={handleCompanySearchSubmit}>
            <div className="selectDepartmentWizard__children">
                {!sessionCompany && (
                    <p className="selectDepartmentWizard__children__subtitle">
                        {` ${t(TranslationKeys.WELCOME_COMPANY)} ${session?.user.firstName}`}
                    </p>
                )}
                <h1 className="selectDepartmentWizard__children__title">
                    {isSupervisorOrAnalist
                        ? translateCustomerTypeKeys(TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE_SUPERVISOR, {
                              firstReplace: "SINGULAR",
                          })
                        : translateCustomerTypeKeys(TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE, {
                              firstReplace: "SINGULAR",
                          })}
                </h1>
                <div className="selectDepartmentWizard__children__options">
                    <Input
                        value={searchCompanyValue}
                        onChange={handleCompanySearch}
                        placeholder={t(TranslationCommon.SEARCH)}
                        iconRight={faSearch}
                        onClickIcon={getCompanyPendingTasksToReview}
                    />
                    {isLoading ? (
                        <OptionCompanySkelleton quantity={5} />
                    ) : (
                        <>
                            {!!companyPendingTasks.length ? (
                                companyPendingTasks.map((company, i) => {
                                    const { badge, selectedTask, count } = getSelectedCompany(company);
                                    return (
                                        <OptionCompany
                                            name={company.companyName}
                                            onClick={() => {
                                                const selectedCompany = companies?.find(
                                                    (x) => x.companyId == company.companyId
                                                );
                                                selectedCompany && handleSelectCompany(selectedCompany.companyId);
                                            }}
                                            isActive={isWorkingCompanyActive(company.companyId)}
                                            key={i}
                                            logoImage={company.logoUrl || ""}
                                            count={count || 0}
                                            isDisabled={selectedTask?.isClosed}
                                            badge={badge}
                                            justifyContent="center"
                                        />
                                    );
                                })
                            ) : (
                                <div className="selectDepartmentWizard__children__options__companyListEmpty">
                                    <TableError icon={faQuestion} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </WizardForm>
    );
};
