import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "../../shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { DashboardPaginatedResults } from "app/models/ServiceResponse/PaginatedResult";
import { TaskInstanceModel } from "../../models/02-TAR/TaskInstance/TaskInstanceModel";
import { CompleteTaskInstanceRequestModel } from "app/models/02-TAR/TaskInstance/CompleteTaskInstanceRequestModel";
import { ImageToSendTaskDetailDto } from "app/dtos/02-TAR/TaskInstance/ImageToSendTaskDetailDto";
import { TaskInstancePhotoModel } from "app/models/02-TAR/TaskInstance/TaskInstancePhotoModel";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export default class TaskInstanceService {
    public static async GetOne(taskInstanceId: number | string) {
        return FetchService.get<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/taskInstance/${taskInstanceId}`,
        });
    }

    public static async GetData(params?: PaginationParams) {
        return FetchService.get<DashboardPaginatedResults<TaskInstanceListDto>>({
            url: `${ApiPaths.TAR_API}/taskInstance`,
            paginationParams: params,
        });
    }

    public static async GetTaskHistoryData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<TaskInstanceListDto>>({
            url: `${ApiPaths.TAR_API}/taskInstance/TaskHistory`,
            paginationParams: params,
        });
    }

    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.TAR_API}/taskInstance/export`,
            paginationParams,
            csvExport: true,
        });
    }

    // POST Executes a trigger in the database to generate task instances from the existing tasks in a range of days
    public static async GenerateTaskInstances(extraParams: string) {
        return FetchService.post<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/taskInstance/generateTaskInstance?${extraParams}`,
        });
    }

    // POST Executes a trigger in the database to generate task instances from the existing tasks in a range of days
    public static async GenerateAPPCCTaskInstances(extraParams: string) {
        return FetchService.post<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/taskInstance/generateAPPCCTaskInstance?${extraParams}`,
        });
    }

    // PUT Makes a task instance as completed with the given data requiered
    public static async CompleteTask(values: CompleteTaskInstanceRequestModel, taskInstanceId: number | string) {
        return FetchService.put<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/taskInstance/${taskInstanceId}/completeTask`,
            body: values,
        });
    }

    // PUT Reset TaskInstance to its Initial State
    public static async ReopenTask(id: number) {
        return FetchService.put<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/taskInstance/${id}/reopen`,
        });
    }

    public static async UploadPhotos(taskInstanceId: number | string, values: ImageToSendTaskDetailDto[]) {
        return FetchService.put<TaskInstancePhotoModel[]>({
            url: `${ApiPaths.TAR_API}/TaskInstancePhoto/${taskInstanceId}/Upload`,
            body: values,
        });
    }

    public static async EditPhoto(
        taskInstanceId: number | string,
        taskPhotoId: number,
        value: ImageToSendTaskDetailDto
    ) {
        return FetchService.put<TaskInstancePhotoModel>({
            url: `${ApiPaths.TAR_API}/TaskInstancePhoto/${taskInstanceId}/Edit/${taskPhotoId}`,
            body: value,
        });
    }

    public static async DeletePhoto(taskInstanceId: number | string, fotoId: number) {
        return FetchService.delete<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/TaskInstancePhoto/${taskInstanceId}/Delete/${fotoId}`,
        });
    }
}
