import { FC } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSession, useTitle, useWindowSize } from "app/hooks";
import { useGetPageTitle } from "app/hooks/useGetPageTitle";
import { TranslationTitles } from "app/translation/translationKeys";
import { ReviewUserMapper } from "app/mappers/ReviewUserMapper";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { ReviewTaskList } from "./ReviewTaskList/ReviewTaskList";
import { ReviewCarrousel } from "./carrousel/ReviewCarrousel";
import { Tabs } from "app/components_v2/Tabs";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { screenSize } from "app/shared/Constants";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { useReviewTask } from "./hooks/useReviewTask";
import { Icon } from "app/components_v2/Icon/Icon";
import { faSliders } from "@fortawesome/pro-regular-svg-icons";
import { ReviewTaskFilter } from "app/components_v2/__filters/ReviewTaskFilter/ReviewTaskFilter";
import { INITIAL_REVIEW_TASK_FILTER_DATA } from "./constants/reviewTaskConstants";
import { isEqual } from "lodash";

export const ReviewTask: FC = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const pageTitle = useGetPageTitle();
    const session = useSession();
    const nav = useNavigate();

    const {
        currentTab,
        reviewStatus,
        user,
        tabs,
        handleDateChange,
        onStarsValued,
        isLoading,
        pageIndex,
        taskInstanceList,
        total,
        onChangePageIndex,
        dates,
        isSecondaryFilterOpen,
        setIsSecondaryFilterOpen,
        filterData,
        onFilterChange,
        taskDetailOnStarsChange,
        actualPage,
        refreshList,
    } = useReviewTask();

    const { endDate, startDate } = dates;

    useTitle(
        t(TranslationTitles.REVIEW_TASK_PAGE_TITLE).replace(
            "{0}",
            session?.workingCompany?.companyName || t(TranslationTitles.FOR_LINK)
        )
    );

    return (
        <>
            <PageContainer
                fullHeight
                header={
                    <TabHeader
                        tabs={
                            <>
                                <Tabs tabs={tabs} currentTab={currentTab} hideChildren isLoading={isLoading} />
                                <div className="reviewHeader__filterIcon">
                                    <Icon icon={faSliders} onClick={() => setIsSecondaryFilterOpen(true)} />
                                    {!isEqual(filterData, INITIAL_REVIEW_TASK_FILTER_DATA) && (
                                        <div className="markableIcon__notification tableTab__header__actions__markableIcon"></div>
                                    )}
                                </div>
                            </>
                        }
                        paddingSize="sm"
                    >
                        <div className="reviewHeader">
                            <div className="reviewHeader__header">
                                {!!pageTitle.length && width >= screenSize.TABLET && (
                                    <p className="pageTitle">{pageTitle}</p>
                                )}
                                <DateRangePicker
                                    inverted
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    showMoreOptions
                                />
                            </div>
                            <ReviewCarrousel
                                onItemClick={(id) => nav(`${id}`)}
                                listItems={ReviewUserMapper(user, session?.user.timeZoneOffset || 0)}
                                isLoading={reviewStatus === "loading"}
                            />
                        </div>
                    </TabHeader>
                }
            >
                <ReviewTaskList
                    data={taskInstanceList}
                    isLoading={isLoading}
                    onStarsValued={onStarsValued}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    taskDetailOnStarsChange={taskDetailOnStarsChange}
                    total={total}
                    actualPage={actualPage}
                    refreshList={refreshList}
                />
            </PageContainer>
            <ReviewTaskFilter
                filterData={filterData}
                initialFilterData={INITIAL_REVIEW_TASK_FILTER_DATA}
                isOpen={isSecondaryFilterOpen}
                onCancel={() => setIsSecondaryFilterOpen(false)}
                onClickOutside={() => setIsSecondaryFilterOpen(false)}
                onSave={onFilterChange}
                companyId={session?.workingCompany?.companyId || 0}
                startDate={startDate}
                endDate={endDate}
            />
        </>
    );
};
