import { FC, useEffect, useState } from "react";
import { SporadicTaskModalOptions } from "./types";
import { sporadicTaskOptions } from "./constants/SporadicTaskDetailModalConstants";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { DashboardService } from "app/services";
import { useTranslation } from "react-i18next";
import { useTaskInstance, useTitle } from "app/hooks";
import { TranslationTitles } from "app/translation/translationKeys";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import Spinner from "app/components_v2/Spinner/Spinner";
import { TaskDetail } from "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskDetail";

type SporadicTaskDetailModalProps = {
    id: number;
    options?: SporadicTaskModalOptions;
    onClose?: () => void;
    onComplete?: () => void;
    onStarsChange?: () => void;
};

export const SporadicTaskDetailModal: FC<SporadicTaskDetailModalProps> = ({
    id,
    options = sporadicTaskOptions,
    onClose,
    onComplete,
    onStarsChange,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const nav = useNavigate();

    const { isTaskInstanceEditable } = useTaskInstance();

    const [sporadicTask, setSporadicTask] = useState<TaskInstanceModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useTitle(t(TranslationTitles.TASK_DETAIL_TITLE));

    const getData = async () => {
        setIsLoading(true);
        const { data, status } = await DashboardService.InsertSporadicTask(id);

        if (!status()) {
            setSporadicTask(null);
            setIsLoading(false);
            onClose && onClose();
            return;
        }

        setSporadicTask(data);
        setIsLoading(false);
    };

    const handleClose = () => onClose && onClose();

    useEffect(() => {
        getData();
    }, []);

    return (
        <GenericModal
            header={{
                title: t(TranslationTitles.TASK_DETAIL_TITLE),
                onClose: handleClose,
                actionButton: hasPermissionToEdit(SecScreen.TASK)
                    ? {
                          icon: faPen,
                          onClick() {
                              nav(`/${PrivatePaths.TASK_PAGE}/edit/${sporadicTask?.taskId}`);
                          },
                      }
                    : undefined,
            }}
            size="xl"
            hideChildrenPadding
            allowCloseOnEscape={false}
        >
            {isLoading ? (
                <div className="sporadicTaskDetailModal__spinner">
                    <Spinner />
                </div>
            ) : sporadicTask ? (
                <TaskDetail
                    taskInstance={sporadicTask}
                    setTaskInstance={setSporadicTask}
                    onCancel={handleClose}
                    isFeedback={location.pathname.includes(PrivatePaths.FEEDBACK_TASKS)}
                    onComplete={onComplete}
                    editable={options.isEditable && sporadicTask && isTaskInstanceEditable(sporadicTask)}
                    isReviewPhotos={!!options.feedback?.isVisible}
                    onStarsChange={onStarsChange}
                    editStarRating={!!options.feedback?.editable}
                    showValoratedBy={!!options.feedback?.showValoratedBy}
                    allowCompleteTask={!!options.allowCompleteTask}
                />
            ) : null}
        </GenericModal>
    );
};
