import { LOCAL_STORAGE_ACTION_RECORD, PrivatePaths } from "app/shared/Constants";

export const useGetUrlFromLocalStorage = () => {
    const getUrlFromLocalStorage = () => {
        const urlParamType = localStorage.getItem("urlParamType");
        const qrCode = localStorage.getItem("qrCode");
        const searchParamsTaskHistory = localStorage.getItem("searchParamsTaskHistory");
        const auditHistoryUrl = localStorage.getItem("auditHistoryUrl");

        if (qrCode != null && urlParamType === LOCAL_STORAGE_ACTION_RECORD.QR)
            return `/${PrivatePaths.DASHBOARD}/qr/${qrCode}`;

        if (searchParamsTaskHistory != null && urlParamType === LOCAL_STORAGE_ACTION_RECORD.TASK_HISTORY) {
            return `/${PrivatePaths.TASK_HISTORY}?${searchParamsTaskHistory}`;
        }

        if (auditHistoryUrl != null && urlParamType === LOCAL_STORAGE_ACTION_RECORD.AUDIT_HISTORY) {
            return auditHistoryUrl;
        }

        return null;
    };

    return { getUrlFromLocalStorage };
};
