import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTaskInstance, useTitle } from "app/hooks";
import {
    DynamicFieldTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { TaskDetail } from "./TaskDetail";
import { PrivatePaths, SecScreen, TaskStatus } from "app/shared/Constants";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import Spinner from "app/components_v2/Spinner/Spinner";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { isCorrectIncorrectDynamicField } from "app/helpers/dynamicFields/dynamicFieldHelper";
import IssueService from "app/services/05-QUA/IssueService";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import { useToast } from "app/hooks/Toast/useToast";

type TaskDetailModalProps = {
    taskInstanceId: string;
    onComplete?: () => void;
    onClose?: () => void;
    editTaskDetail?: boolean;
    isReviewPhotos?: boolean;
    onStarsChange?: () => void;
    hideFeedBack?: boolean;
    editStarRating?: boolean;
    showValoratedBy?: boolean;
    allowOpenIssue?: boolean;
    variant?: TabHeaderVariants;
    allowCompleteTask?: boolean;
};

export const TaskDetailModal: FC<TaskDetailModalProps> = ({
    taskInstanceId,
    onComplete,
    onClose,
    editTaskDetail = true,
    isReviewPhotos,
    onStarsChange,
    hideFeedBack,
    editStarRating,
    showValoratedBy,
    allowOpenIssue = true,
    variant = "primary",
    allowCompleteTask,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const nav = useNavigate();
    const { handleToast } = useToast();
    const { taskInstance, setTaskInstance, status, isTaskInstanceEditable } = useTaskInstance(
        Number(taskInstanceId),
        onClose
    );

    useTitle(t(TranslationTitles.TASK_DETAIL_TITLE));

    const [isIssueDynamicFieldCreated, setIsIssueDynamicFieldCreated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClose = () => {
        if (
            taskInstance?.statusCode === TaskStatus.PENDING &&
            taskInstance?.taskInstanceFields2?.some(
                ({ dynamicFieldType, taskInstanceFieldValues }) =>
                    isCorrectIncorrectDynamicField(dynamicFieldType) &&
                    taskInstanceFieldValues.some(
                        ({ label, value }) => label === DynamicFieldTranslations.INCORRECT && value === "true"
                    )
            )
        ) {
            setIsIssueDynamicFieldCreated(true);
            return;
        }
        onClose && onClose();
    };

    const handleDeleteDynamicFieldIssues = async () => {
        setIsLoading(true);
        const dynamicFieldsWithIssue = taskInstance?.taskInstanceFields2?.filter(
            ({ dynamicFieldType, taskInstanceFieldValues }) =>
                isCorrectIncorrectDynamicField(dynamicFieldType) &&
                taskInstanceFieldValues.some(
                    (item) => item.label === DynamicFieldTranslations.INCORRECT && item.value === "true"
                )
        );
        if (!dynamicFieldsWithIssue?.length) {
            setIsLoading(false);
            onClose && onClose();
            return;
        }

        try {
            const promiseArray: Promise<ServiceResponse<unknown>>[] = [];
            dynamicFieldsWithIssue.forEach(({ id }) =>
                promiseArray.push(IssueService.DeleteIssueByTaskInstanceFieldIdAsync(id))
            );
            await Promise.all(promiseArray);
        } catch (error) {
            handleToast({ title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE), variant: "danger", type: "alert" });
        } finally {
            setIsLoading(false);
            onClose && onClose();
        }
    };

    return (
        <>
            {isIssueDynamicFieldCreated && (
                <ConfirmModal
                    title={t(TranslationModals.TASK_DETAIL_DYNAMIC_FIELDS_WITH_ISSUE_TITLE)}
                    description={t(TranslationModals.TASK_DETAIL_DYNAMIC_FIELDS_WITH_ISSUE_DESCRIPTION)}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={() => handleDeleteDynamicFieldIssues()}
                    onClose={() => setIsIssueDynamicFieldCreated(false)}
                    isLoading={isLoading}
                />
            )}
            <GenericModal
                header={{
                    variant,
                    title: t(TranslationTitles.TASK_DETAIL_TITLE),
                    onClose: handleClose,
                    actionButton: hasPermissionToEdit(SecScreen.TASK)
                        ? {
                              icon: faPen,
                              onClick: () => {
                                  const pathname = !!taskInstance?.planId
                                      ? PrivatePaths.REGISTRY_MANAGEMENT
                                      : PrivatePaths.TASK_PAGE;
                                  nav(`/${pathname}/edit/${taskInstance?.taskId}`);
                              },
                          }
                        : undefined,
                }}
                size="xl"
                hideChildrenPadding
                allowCloseOnEscape={false}
            >
                {status === "loading" && (
                    <div className="sporadicTaskDetailModal__spinner">
                        <Spinner />
                    </div>
                )}
                {status === "complete" && (
                    <TaskDetail
                        taskInstance={taskInstance}
                        setTaskInstance={setTaskInstance}
                        onCancel={handleClose}
                        isFeedback={location.pathname.includes(PrivatePaths.FEEDBACK_TASKS)}
                        onComplete={onComplete}
                        editable={editTaskDetail && taskInstance && isTaskInstanceEditable(taskInstance)}
                        isReviewPhotos={
                            isReviewPhotos ||
                            (!hideFeedBack &&
                                taskInstance &&
                                validateStatus(taskInstance) !== "PENDING" &&
                                validateStatus(taskInstance) !== "NEAR_END")
                        }
                        onStarsChange={onStarsChange}
                        hideFeedBack={hideFeedBack}
                        editStarRating={editStarRating}
                        showValoratedBy={showValoratedBy}
                        allowOpenIssue={allowOpenIssue}
                        allowCompleteTask={!!allowCompleteTask}
                    />
                )}
            </GenericModal>
        </>
    );
};

export default TaskDetailModal;
