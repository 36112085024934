import { ActionType } from "app/models/FormComponentsModel";
import { FormEvent, useState } from "react";
import { IssueClassificationModalProps } from "../IssueClassificationModal";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import IssueClassificationService from "app/services/05-QUA/IssueClassificationService";
import { IssueClassificationModel } from "app/models/05-QUA/IssueClassificationModels/IssueClassificationModel";
import { TranslationKeys } from "app/translation/translationKeys";

type IUseIssueClassificationModal = Omit<IssueClassificationModalProps, "onCancel" | "variant">;

export const useIssueClassificationModal = ({
    onAddNew,
    onEdit,
    selectedIssueClassification,
}: IUseIssueClassificationModal) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const isSelectedIssueClassificationNotNull = selectedIssueClassification !== null;
    const action: ActionType = isSelectedIssueClassificationNotNull ? "edit" : "create";

    const [name, setName] = useState<string>(() =>
        action === "edit" && isSelectedIssueClassificationNotNull ? selectedIssueClassification.name : ""
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoading(true);
        if (action === "create") await handleAdd();
        if (action === "edit") await handleEdit();
        setIsLoading(false);
    };

    const handleAdd = async () => {
        const { data, getParsedError, status } = await IssueClassificationService.Save(name);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }
        onAddNew(data);
    };

    const handleEdit = async () => {
        if (!selectedIssueClassification) return;

        const editedIssueClassification: IssueClassificationModel = {
            ...selectedIssueClassification,
            name,
        };
        const { getParsedError, status } = await IssueClassificationService.Edit(editedIssueClassification);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }
        onEdit({ id: selectedIssueClassification.id, name });
    };

    const validate = () => {
        if (name.length >= 50 || !name.length) {
            setErrorMessage(t(TranslationKeys.INPUT_MAX_CHARACTERS).replace("{0}", "50"));
            return false;
        }
        return true;
    };

    return {
        name,
        onNameChange: setName,
        isLoading,
        errorMessage,
        onSubmit: handleSubmit,
        action,
    };
};
