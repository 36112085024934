import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { AlertNotificationService } from "app/services";
import { TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { AlertExtraparamsProps, alertExtraparams } from "../helpers/alertExtraparams";
import { AlertFilterModel } from "app/components_v2/__filters/AlertFilter/types";
import { AlertTabsModel } from "../types";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";
import { translateCurrentTabToModel } from "../helpers/translateCurrentTabToModel";
import AlertQRService from "app/services/02-TAR/AlertQRService";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";

export const useAlert = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const initialFilterData: AlertFilterModel = {
        userId: "-1",
        departmentId: "-1",
        companyId: "-1",
        receivedStartDate: undefined,
        receivedEndDate: undefined,
        seenStartDate: undefined,
        seenEndDate: undefined,
    };

    const [filterData, setFilterData] = useState<AlertFilterModel>(initialFilterData);
    const [list, setList] = useState<AlertNotificationDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedData, setSelectedData] = useState<AlertNotificationDto>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isNotficationModalOpened, setIsNotificationModalOpened] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [checkedAlertIds, setCheckedAlertIds] = useState<number[]>([]);
    const [isDeleteAlertsModalOpen, setIsDeleteAlertsModalOpen] = useState<boolean>(false);
    const [isDeleteAlertModalLoading, setIsDeleteAlertModalLoading] = useState<boolean>(false);

    const getNotifications = async (
        currentTab: AlertTabsModel,
        pageIndex?: number,
        filterDataProps?: AlertFilterModel
    ) => {
        setIsLoading(true);
        const filterDataExtraParam = filterDataProps || filterData;
        const extraParams: AlertExtraparamsProps = {
            ...filterDataExtraParam,
            currentTab,
            userId: filterDataExtraParam.userId === "-1" ? String(session?.user.id) : filterDataExtraParam.userId,
            departmentId: filterDataExtraParam.departmentId === "-1" ? "" : filterDataExtraParam.departmentId,
            companyId: filterDataExtraParam.companyId === "-1" ? "" : filterDataExtraParam.companyId,
            sessionUserId: session?.user.id || 0,
        };
        const service = currentTab === "qr" ? AlertQRService.GetData : AlertNotificationService.GetData;
        const { data, status } = await service({
            pageIndex: pageIndex ? pageIndex - 1 : 0,
            extraParams: alertExtraparams(extraParams),
        });

        if (!status()) {
            handleToast({
                title: t(TranslationKeys.FAILED_ALERT_SENT),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }

        setList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const onChangePageIndex = (pi: number, tab?: number) => {
        setPageIndex(pi);
        const current = tab !== undefined ? tab : currentTab;
        getNotifications(translateCurrentTabToModel(current), pi, filterData);
    };

    const onClickChangeTab = (current: number) => {
        if (current === currentTab) return;
        if (isLoading) return;
        setCheckedAlertIds([]);
        setPageIndex(0);
        setCurrentTab(current);
        const newFilterData = {
            ...filterData,
            seenStartDate: initialFilterData.seenStartDate,
            seenEndDate: initialFilterData.seenEndDate,
        };
        setFilterData(newFilterData);
        getNotifications(translateCurrentTabToModel(current), 0, newFilterData);
    };

    const onAlertClick = (id: number) => {
        setSelectedData(list.find((item) => item.id === id));
        setShowModal(true);
    };

    const onChangeFilterData = (values: AlertFilterModel) => {
        const sfCompany = values.companyId ?? "-1";

        setFilterData(values);
        setIsFilterOpen(false);
        setPageIndex(0);
        getNotifications(translateCurrentTabToModel(currentTab), 0, values);

        if (sfCompany != "-1") changeWorkingCompanyByCompanyId(Number(sfCompany));
    };

    const onCloseNotificationModal = () => {
        setIsNotificationModalOpened(false);
        getNotifications(translateCurrentTabToModel(currentTab), pageIndex, filterData);
    };

    const onCheckboxChange = (alertId: number, isChecked: boolean) => {
        if (isChecked) {
            setCheckedAlertIds((prev) => [...prev, alertId]);
            return;
        }

        setCheckedAlertIds((prev) => prev.filter((aId) => aId !== alertId));
    };

    const onDeleteAlerts = async () => {
        setIsDeleteAlertModalLoading(true);
        const extraParams = new URLSearchParams();
        extraParams.append("AlertIds", checkedAlertIds.join(";"));
        const { status } = await AlertQRService.DeleteMultiple({ extraParams: extraParams.toString() });

        setIsDeleteAlertModalLoading(false);

        if (!status()) {
            handleToast({
                title: t(TranslationErrors.DELETE_ALERTS_ERROR_TITLE),
                subtitle: t(TranslationErrors.DELETE_ALERTS_ERROR_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return;
        }

        setCheckedAlertIds([]);
        setIsDeleteAlertsModalOpen(false);
        setPageIndex(0);
        getNotifications(translateCurrentTabToModel(currentTab), 0, filterData);
    };

    useEffect(() => {
        getNotifications(translateCurrentTabToModel(currentTab), pageIndex, filterData);
    }, []);

    return {
        currentTab,
        filterData,
        initialFilterData,
        isFilterOpen,
        isLoading,
        isNotficationModalOpened,
        list,
        onAlertClick,
        onChangeFilterData,
        onChangePageIndex,
        onClickChangeTab,
        onCloseNotificationModal,
        pageIndex,
        selectedData,
        setFilterData,
        setIsFilterOpen,
        setIsLoading,
        setIsNotificationModalOpened,
        setShowModal,
        showModal,
        total,
        onCheckboxChange,
        checkedAlertIds,
        onChangeDeleteModalVisibility: setIsDeleteAlertsModalOpen,
        isDeleteAlertsModalOpen,
        isDeleteAlertModalLoading,
        onDeleteAlerts,
    };
};
