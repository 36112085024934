import { AlertNotificationService } from "app/services";
import { ErrorFormValuesNotificationModal, NotificationModalAssignedTo } from "../types";
import { ImageNamesModel } from "app/models/FormComponentsModel";
import { INITIAL_ERROR_FORM_VALUES_NOTIFICATION_MODAL } from "../constants/notificationModalConstants";
import { MultipleAlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/MultipleAlertNotificationDto";
import { NotificationFileInsertModel } from "app/models/02-TAR/AlertNotification/AlertNotificationModel";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { useCompanies, useDepartments, useSession, useUsers } from "app/hooks";
import { useEffect, useState } from "react";
import { useQrs } from "app/hooks/useQrs";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import AlertQRService from "app/services/02-TAR/AlertQRService";
import { getDateFormattedSelects } from "app/helpers";
import { MAX_SIZE_IMAGES_30MB } from "app/shared/Constants";

type IuseNotificationModal = {
    userId?: number;
    onClose: () => void;
    handleSubmitMessage?: () => void;
};

export const useNotificationModal = ({ userId, onClose, handleSubmitMessage }: IuseNotificationModal) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const session = useSession();
    const { companies: companyOptions, getCompanies } = useCompanies();
    const { getUsers, users: userOptions } = useUsers();
    const { departments: departmentOptions, getDepartment } = useDepartments();
    const { getQrs, qrs: qrOptions } = useQrs();

    const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
    const [selectedQr, setSelectedQr] = useState<number[]>([]);
    const [currentTab, setCurrentTab] = useState<number>(0);

    const [showProgramNotifsModal, setShowProgramNotifsModal] = useState<boolean>(false);
    const [textAreaValue, setTextAreaValue] = useState<string>("");
    const [textAreaError, setTextAreaError] = useState<boolean>(false);
    const [files, setFiles] = useState<ImageNamesModel[]>([]);
    const [audioBase64, setAudioBase64] = useState<string | null>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendDate, setSendDate] = useState<Date | null>(null);
    const [maxSizeError, setMaxSizeError] = useState<string>("");
    const [errorFormValues, setErrorFormValues] = useState<ErrorFormValuesNotificationModal>(
        INITIAL_ERROR_FORM_VALUES_NOTIFICATION_MODAL
    );

    const handleChangeCurrentTab = async (tab: number) => {
        if (tab === currentTab) return;
        setErrorFormValues(INITIAL_ERROR_FORM_VALUES_NOTIFICATION_MODAL);
        setCurrentTab(tab);
        setSendDate(null);
        handleSelect({ companyList: [], departmentList: [], qrList: [], usersList: [] }, false);

        await getCompanies(tab === 2);
    };

    const handleSelect = (
        { companyList, departmentList, qrList, usersList }: NotificationModalAssignedTo,
        companyChanged: boolean
    ) => {
        setSelectedCompanies(companyList);
        setSelectedDepartments(departmentList);
        setSelectedQr(qrList);
        setSelectedUsers(usersList);
        setErrorFormValues(INITIAL_ERROR_FORM_VALUES_NOTIFICATION_MODAL);
        if (companyChanged) {
            if (currentTab === 0 || currentTab === 1) {
                getUsers(companyList[0]);
                getDepartment(companyList[0]);
                return;
            }
            if (currentTab === 2) {
                getQrs(companyList[0]);
                return;
            }
        }
    };

    const handleErrorChange = (values: Partial<ErrorFormValuesNotificationModal>) =>
        setErrorFormValues((prev) => ({ ...prev, ...values }));

    const validations = () => {
        let error = false;
        const isSendNotificationDirectToSpecificUser = !!userId;
        setErrorFormValues(INITIAL_ERROR_FORM_VALUES_NOTIFICATION_MODAL);

        if (!isSendNotificationDirectToSpecificUser) error = validateTabs();

        if (!textAreaValue.length && !audioBase64?.length) {
            setTextAreaError(true);
            error = true;
        } else setTextAreaError(false);

        return error;
    };

    const validateTabs = () => {
        let error = false;
        const validateHasUsers = !!selectedUsers?.length || userId;
        const validateHasDepartments = !!selectedDepartments?.length;
        const isTabZero = currentTab === 0;
        const isQrTab = currentTab === 2;
        const isUserAndDeptInvalid = !validateHasUsers && !validateHasDepartments;

        if (!selectedCompanies.length) {
            handleErrorChange({
                errorCompany:
                    currentTab === 0
                        ? t(TranslationCommon.INPUT_NOT_EMPTY)
                        : t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            return true;
        }

        if (isTabZero && isUserAndDeptInvalid) {
            handleErrorChange({
                errorUser: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
                errorDepartment: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            error = true;
        }

        if (isQrTab && !selectedQr.length) {
            handleErrorChange({
                errorQr: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            error = true;
        }

        if (isQrTab && !sendDate) {
            handleErrorChange({
                errorDates: t(TranslationCommon.INPUT_NOT_EMPTY),
            });
            error = true;
        }

        return error;
    };

    const parseDate = (receivedDate?: Date) => {
        if (sendDate) return sendDate;
        if (!receivedDate) return undefined;
        return receivedDate < new Date()
            ? new Date(new Date().toUTCString())
            : new Date(new Date(receivedDate).toUTCString());
    };

    const handleSubmit = async (date?: Date) => {
        const filesToSend: NotificationFileInsertModel[] = [];
        if (files.length) {
            files.map(({ base64 }: ImageNamesModel) => {
                filesToSend.push({ fileBase64: base64 || "" });
            });
        }
        if (!session) return;
        const { user } = session;
        const receivedDate = parseDate(date);
        if (validations()) return;

        const multipleAlertNotification: MultipleAlertNotificationDto = {
            alertNotification: {
                customerInstanceId: user.customerInstanceId || 0,
                receivedDate: date ? receivedDate : !!receivedDate ? getDateFormattedSelects(receivedDate) : undefined,
                isProgrammed: date ? true : false,
                title: t(TranslationKeys.SCHEDULED_ALERT),
                body: textAreaValue,
                senderUserId: user.id,
                audioBase64: audioBase64?.length ? audioBase64 : "",
                notificationFiles: filesToSend,
                fK_WorkingPosition: null,
                workingPosition: null,
            },
            companyIdList: currentTab === 1 ? selectedCompanies : [],
            departmentIdList: currentTab === 1 ? [] : selectedDepartments,
            userIdList: currentTab === 1 ? [] : userId ? [userId] : selectedUsers,
            qrList: currentTab === 2 ? selectedQr : [],
        };

        const service =
            currentTab === 2
                ? AlertQRService.InsertMultiple(multipleAlertNotification)
                : AlertNotificationService.Save(multipleAlertNotification);

        setLoading(true);
        const { status } = await service;
        handleSubmitMessage && handleSubmitMessage();
        onClose();
        handleToast({
            title: status() ? t(TranslationModals.TOAST_ALERT_SENDED) : t(TranslationModals.TOAST_GENERIC_ERROR),
            type: "alert",
            variant: status() ? "success" : "danger",
        });
    };

    const getTotalFilesSize = (arr: ImageNamesModel[]) => arr.reduce((acc, obj) => acc + (obj.file?.size || 0), 0);

    const openProgramedNotificationModal = () => {
        if (!validations()) setShowProgramNotifsModal(true);
    };

    const onSelectDepartment = (values: Omit<NotificationModalAssignedTo, "qrList">, companyChanged: boolean) =>
        handleSelect({ ...values, qrList: selectedQr }, companyChanged);

    const onSelectCompany = (values: Pick<NotificationModalAssignedTo, "companyList">) =>
        handleSelect(
            { ...values, departmentList: selectedDepartments, usersList: selectedUsers, qrList: selectedQr },
            false
        );

    const onSelectQr = (
        values: Pick<NotificationModalAssignedTo, "qrList" | "companyList">,
        companyChanged: boolean
    ) => {
        handleSelect(
            {
                ...values,
                departmentList: selectedDepartments,
                usersList: selectedUsers,
            },
            companyChanged
        );
    };

    const onChangeTextarea = (value: string) => {
        setTextAreaValue(value);
        setTextAreaError(false);
    };

    useEffect(() => {
        if (getTotalFilesSize(files) > MAX_SIZE_IMAGES_30MB) {
            setMaxSizeError(t(TranslationKeys.MAX_30_MB));
            return;
        }
        setMaxSizeError("");
    }, [files]);

    useEffect(() => {
        getCompanies();
    }, []);

    return {
        showProgramNotifsModal,
        handleSubmit,
        setShowProgramNotifsModal,
        maxSizeError,
        currentTab,
        loading,
        openProgramedNotificationModal,
        notificationAssigned: {
            companyList: selectedCompanies,
            companyOptions: companyOptions,
            departmentList: selectedDepartments,
            departmentOptions: departmentOptions,
            errorFormValues: errorFormValues,
            onChangeDate: setSendDate,
            onSelect: handleSelect,
            qrList: selectedQr,
            qrOptions: qrOptions,
            sendDate: sendDate,
            userOptions: userOptions,
            usersList: selectedUsers,
        },
        onChangeCurrentTab: handleChangeCurrentTab,
        uploadAudio: {
            showSendAudio: true,
            audioBase64: audioBase64,
            setAudioBase64: setAudioBase64,
            isRecording: isRecording,
            setIsRecording: setIsRecording,
        },
        uploadFile: {
            showSendFiles: true,
            sendFiles: setFiles,
            maxFiles: 10,
        },
        textAreaValue,
        onChangeTextarea,
        textAreaError,
        onSelectDepartment,
        onSelectCompany,
        onSelectQr,
    };
};
