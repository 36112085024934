import { isUndefined, max } from "lodash";
import { ErrorResponse } from "../../models/ServiceResponse/ErrorResponse";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import { PaginationDefaults } from "app/shared/Constants";
import { getLanguage } from "app/helpers/BrowserStorage/LocalStorageHandler";

export type IExtraParams = {
    key: string;
    value: any;
};

export const getQueryString = ({
    pageIndex,
    pageSize,
    totalRows,
    sortField,
    sortDirection,
    createdDateFrom,
    createdDateTo,
    query,
    extraParams,
}: PaginationParams) => {
    const values: string[] = [];

    if (pageIndex !== undefined)
        values.push(`pageIndex=${pageIndex ? pageIndex : "0"}&pageSize=${pageSize || PaginationDefaults.PAGE_SIZE}`);

    if (totalRows !== undefined) values.push(`totalRows=${totalRows}`);

    if (sortField) values.push(`sortField=${sortField}&sortDirection=${sortDirection || ""}`);

    if (createdDateFrom) values.push(`createdDateFrom=${createdDateFrom}`);

    if (createdDateTo) values.push(`createdDateTo=${createdDateTo}`);

    if (query) values.push(`query=${query}`);

    if (extraParams) values.push(extraParams);

    return "?" + values.join("&");
};

type FetchParams = {
    url: string;
    token?: string;
    body?: object;
    paginationParams?: PaginationParams;
    csvExport?: boolean;
};

class FetchService {
    private static authToken: string = "";
    private static customerInstanceId?: number = undefined;

    public static async get<T>(params: FetchParams): Promise<ServiceResponse<T>> {
        const { paginationParams, csvExport } = params;
        let url = params.url;
        if (paginationParams) url += getQueryString(paginationParams);

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: FetchService.getHeaders(params.token),
            });

            return FetchService.processResponse<T>(response, csvExport);
        } catch (error: any) {
            const sr = new ServiceResponse<T>();
            sr.addError(error.message);
            return sr;
        }
    }

    public static async post<T>(params: FetchParams): Promise<ServiceResponse<T>> {
        const { body, paginationParams } = params;
        let url = params.url;
        if (paginationParams) url += getQueryString(paginationParams);

        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(body),
                headers: FetchService.getHeaders(params.token),
            });

            return FetchService.processResponse<T>(response);
        } catch (error: any) {
            const sr = new ServiceResponse<T>();
            sr.addError(error.message);
            return sr;
        }
    }

    public static async put<T>(params: FetchParams): Promise<ServiceResponse<T>> {
        const { url, body } = params;

        try {
            const response = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: FetchService.getHeaders(params.token),
            });

            return FetchService.processResponse<T>(response);
        } catch (error: any) {
            const sr = new ServiceResponse<T>();
            sr.addError(error.message);
            return sr;
        }
    }

    public static async delete<T>(params: FetchParams): Promise<ServiceResponse<T>> {
        const { body, paginationParams } = params;
        let url = params.url;
        if (paginationParams) url += getQueryString(paginationParams);

        try {
            const response = await fetch(url, {
                method: "DELETE",
                body: JSON.stringify(body),
                headers: FetchService.getHeaders(params.token),
            });

            return FetchService.processResponse<T>(response);
        } catch (error: any) {
            const sr = new ServiceResponse<T>();
            sr.addError(error.message);
            return sr;
        }
    }

    private static async processResponse<T>(response: Response, csvExport?: boolean): Promise<ServiceResponse<T>> {
        const sr = new ServiceResponse<T>();
        sr.httpCodeStatus = response.status;
        sr.servicePath = response.url.substring(response.url.lastIndexOf("/"));
        if (response.status === 400) {
            sr.errorResponse = (await response.json()) as ErrorResponse;
            return sr;
        }

        if (response.status === 401) {
            sr.error = "Unauthorized";
            return sr;
        }

        if (response.status === 404) {
            sr.error = "Resource Not Found";
            return sr;
        }

        if (response.ok) {
            if (response.status === 200) {
                if (csvExport) {
                    const fileURL = URL.createObjectURL(await response.blob());
                    window.open(fileURL, "_self");
                    return new ServiceResponse();
                }

                sr.data = (await response.json()) as T;
                return sr;
            }
        }

        if (response.status > 400 || response.status === 208) {
            const error = new Error(await response.text());
            sr.addError(error.message || response.statusText);
        }

        return sr;
    }

    public static setToken(token: string) {
        this.authToken = token;
    }

    public static setInstance(value?: number) {
        this.customerInstanceId = value;
    }

    private static getHeaders(token: string | undefined) {
        const headers = new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token || this.authToken}`,
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": getLanguage(),
        });

        headers.set("CustomerInstanceId", (this.customerInstanceId || 0).toString());

        return headers;
    }
}

export default FetchService;

export const concatServiceErrors = (...srs: ServiceResponse<any>[]) => {
    return srs
        .map((sr) => sr.getParsedError())
        .filter((x) => !isUndefined(x))
        .join(" - ");
};

export const getMaxHttpCode = (...srs: ServiceResponse<any>[]) => {
    return max(srs.map((sr) => sr.httpCodeStatus));
};
