import { AnalistDataBlocks, AnalistFetchDataProps, AnalistSecondaryFilterModel } from "./types";
import { AnalistDetailBarChart } from "./AnalistDetailBarChart/AnalistDetailBarChart";
import { AnalistDetailBody } from "./AnalistDetailBody/AnalistDetailBody";
import { AnalistDetailDonut } from "./AnalistDetailDonut/AnalistDetailDonut";
import { AnalistDetailDonutList } from "./AnalistDetailDonutList/AnalistDetailDonutList";
import { AnalistDetailFilter } from "app/components_v2/__filters/AnalistDetailFilter/AnalistDetailFilter";
import { AnalistDetailStars } from "./AnalistDetailStars/AnalistDetailStars";
import { AnalistDetailTaskList } from "./AnalistDetailTaskList/AnalistDetailTaskList";
import { AnalistEntityDetailModel } from "app/models/03-REP/Analytics/AnalistEntityDetailModel";
import { AnalistEntityDetailPercentageStarsModel } from "app/models/03-REP/Analytics/AnalistEntityDetailPercentageStarsModel";
import { AnalistEntityDetailTaskListCounters } from "app/dtos/03-REP/Analytics/AnalistEntityDetailTaskListCounters";
import { AnalistEntityDetailTaskListModel } from "app/models/03-REP/Analytics/AnalistEntityDetailTaskListModel";
import {
    ANALYST_ENTITY_TYPE,
    AnalistTaskInstanceListTabsType,
    PrivatePaths,
    SecScreen,
    TaskInstanceStatus,
    screenSize,
} from "app/shared/Constants";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { buildParams, buildParamsprops } from "../helpers/helpers";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { DatesReviewTask } from "app/hooks/Dates/types";
import { DoughnutItem } from "app/components_v2/DoughnutChart/types";
import { faArrowRight, faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { FC, useCallback, useEffect, useState } from "react";
import { getDateFormattedSelects } from "app/helpers";
import { INITIAL_ANALIST_EMPTY_DATA_BLOCKS, INITIAL_ANALIST_SECONDARY_FILTER } from "../constants/AnalistConstants";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TranslationErrors, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useBreadcrumbs } from "../state/BreadcrumbsProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useRedirectRanking } from "../Hooks/useRedirectRanking";
import { useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";
import AnalyticsService from "app/services/03-REP/AnalyticsService";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import TaskDetailModal from "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskDetailModal";
import AnalistLayout from "app/components_v2/Layout/AnalistLayout/AnalistLayout";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";

type AnalistDetailProps = {
    entityType: AnalystEntityType;
    onDateChange: (value: Partial<DatesReviewTask>) => void;
    startDate: Date;
    endDate: Date;
};

const pageSize = 4;

export const AnalistDetail: FC<AnalistDetailProps> = ({ startDate, endDate, onDateChange, entityType }) => {
    const { t } = useTranslation();
    const redirectToRankingPage = useRedirectRanking();
    const nav = useNavigate();
    const { width } = useWindowSize();
    const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
    const { id } = useParams();
    const { handleToast } = useToast();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    useTitle(
        entityType === "COMPANY"
            ? translateCustomerTypeKeys(TranslationTitles.COMPANY_DETAIL_PAGE_TITLE, { firstReplace: "SINGULAR" })
            : entityType === "DEPARTMENT"
            ? t(TranslationTitles.DEPARTMENT_DETAIL_PAGE_TITLE)
            : entityType === "USER"
            ? t(TranslationTitles.USER_DETAIL_PAGE_TITLE)
            : t(TranslationTitles.DETAIL_PAGE_TITLE)
    );

    /* HEADER */
    const [isHeaderLoading, setIsHeaderLoading] = useState<boolean>(true);

    // SECONDARY FILTER
    const taskTypeOptions: OptionsSearch[] = [
        {
            value: "all",
            label: t(TranslationKeys.ALL_TASKS),
        },
        {
            value: "no_critic",
            label: t(TranslationKeys.IS_NOT_CRITICAL),
        },
        { value: "critic", label: t(TranslationKeys.IS_CRITICAL) },
    ];
    const [showSecondaryFilter, setShowSecondaryFilter] = useState<boolean>(false);
    const [workingPositionOptions, setWorkingPositionOptions] = useState<OptionsSearch[]>([]);

    const [filterData, setFilterData] = useState<AnalistSecondaryFilterModel>(INITIAL_ANALIST_SECONDARY_FILTER);

    const handleSaveSecondaryFilter = (values: Partial<AnalistSecondaryFilterModel>) => {
        setFilterData((prev) => ({ ...prev, ...values }));
        fetchTaskListData({
            donutStatus: selectedDonutTaskStatus,
            pageIndex: 0,
            selectedTaskType: values.taskType,
            selectedWorkingPosition: values.workingPosition,
        });
        setShowSecondaryFilter(false);
        setTaskListDataPageIndex(0);
    };

    // SECONDARY FILTER END
    /* HEADER END */

    /* ANALIST DATA */
    const [analistDetailData, setAnalistDetailData] = useState<AnalistEntityDetailModel>();
    const [analistDataBlocks, setAnalistDataBlocks] = useState<AnalistDataBlocks>(INITIAL_ANALIST_EMPTY_DATA_BLOCKS);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    /* ANALIST DATA */
    /* STARS DATA */
    const [starsPercentageData, setStarsPercentageData] = useState<AnalistEntityDetailPercentageStarsModel>();
    /* STARS DATA END */
    /* TASK LIST DATA */
    const [taskListData, setTaskListData] = useState<AnalistEntityDetailTaskListModel>();
    const [isTaskListDataLoading, setIsTaskListDataLoading] = useState(false);
    const [taskListDataPageIndex, setTaskListDataPageIndex] = useState(0);
    const [currentTaskListDataTab, setCurrentTaskListDataTab] = useState(0);
    const [taskListDataCounters, setTaskListDataCounters] = useState<AnalistEntityDetailTaskListCounters>({
        feedbackTasksCount: 0,
        commentTasksCount: 0,
        withNoReviewsCount: 0,
    });
    const [showTaskDetail, setShowTaskDetail] = useState<number | undefined>();

    const handleTaskListPagination = (pageIndex: number) => {
        setTaskListDataPageIndex(pageIndex);

        fetchTaskListData({
            donutStatus: selectedDonutTaskStatus,
            pageIndex: pageIndex - 1,
            tabIndex: currentTaskListDataTab,
        });
    };

    const handleTaskListTabChange = (tabIndex: number) => {
        if (tabIndex === currentTaskListDataTab) return;
        if (isTaskListDataLoading) return;

        setTaskListDataPageIndex(0);
        setCurrentTaskListDataTab(tabIndex);
        fetchTaskListData({
            donutStatus: selectedDonutTaskStatus,
            pageIndex: 0,
            tabIndex,
        });
    };
    /* TASK LIST DATA */

    /* DONUT */
    const [doughnutData, setDoughnutData] = useState<DoughnutItem[]>([]);
    const [selectedDonutTaskStatus, setSelectedDonutTaskStatus] = useState<TaskInstanceStatus | undefined>(undefined);
    const formatDougnutData = (data: AnalistEntityDetailModel) => {
        if (entityType === ANALYST_ENTITY_TYPE.USER) {
            const shared_timed_out: DoughnutItem = {
                id: 0,
                color: "darkRed",
                count: data.analistEntityDetailDonutModel.totalSharedTimedOutCount || 0,
                label: t(TranslationKeys.ANALIST_SHARED_TIMED_OUT),
                percentage: data.analistEntityDetailDonutModel.totalSharedTimedOutPercentage || 0,
                isFocused: data.actualStatus === TaskInstanceStatus.SHARED_TIMED_OUT,
            };
            const timed_out: DoughnutItem = {
                id: 1,
                color: "red",
                count: data.analistEntityDetailDonutModel.totalTimedOutCount,
                label: t(TranslationKeys.ANALIST_TIMED_OUT),
                percentage: data.analistEntityDetailDonutModel.totalTimedOutPercentage,
                isFocused: data.actualStatus === TaskInstanceStatus.TIMED_OUT,
            };
            const out_of_time: DoughnutItem = {
                id: 2,
                color: "yellow",
                count: data.analistEntityDetailDonutModel.totalOutOfTimeCount,
                label: t(TranslationKeys.ANALIST_OUT_OF_TIME),
                percentage: data.analistEntityDetailDonutModel.totalOutOfTimePercentage,
                isFocused: data.actualStatus === TaskInstanceStatus.OUT_OF_TIME,
            };
            const in_time: DoughnutItem = {
                id: 3,
                color: "green",
                count: data.analistEntityDetailDonutModel.totalInTimeCount,
                label: t(TranslationKeys.ANALIST_IN_TIME),
                percentage: data.analistEntityDetailDonutModel.totalInTimePercentage,
                isFocused: data.actualStatus === TaskInstanceStatus.IN_TIME,
            };
            setDoughnutData([shared_timed_out, timed_out, out_of_time, in_time]);
            return;
        }

        const timed_out: DoughnutItem = {
            id: 0,
            color: "red",
            count: data.analistEntityDetailDonutModel.totalTimedOutCount,
            label: t(TranslationKeys.ANALIST_TIMED_OUT),
            percentage: data.analistEntityDetailDonutModel.totalTimedOutPercentage,
            isFocused: data.actualStatus === TaskInstanceStatus.TIMED_OUT,
        };
        const out_of_time: DoughnutItem = {
            id: 1,
            color: "yellow",
            count: data.analistEntityDetailDonutModel.totalOutOfTimeCount,
            label: t(TranslationKeys.ANALIST_OUT_OF_TIME),
            percentage: data.analistEntityDetailDonutModel.totalOutOfTimePercentage,
            isFocused: data.actualStatus === TaskInstanceStatus.OUT_OF_TIME,
        };
        const in_time: DoughnutItem = {
            id: 2,
            color: "green",
            count: data.analistEntityDetailDonutModel.totalInTimeCount,
            label: t(TranslationKeys.ANALIST_IN_TIME),
            percentage: data.analistEntityDetailDonutModel.totalInTimePercentage,
            isFocused: data.actualStatus === TaskInstanceStatus.IN_TIME,
        };

        setDoughnutData([timed_out, out_of_time, in_time]);
    };
    /* DONUT END */

    const getAnalistDataPromise = (
        params: string,
        pageIndex: number,
        id: string
    ): Promise<ServiceResponse<AnalistEntityDetailModel>> => {
        return entityType === ANALYST_ENTITY_TYPE.COMPANY
            ? AnalyticsService.GetCompany(id, {
                  pageIndex: pageIndex === 0 ? 0 : pageIndex - 1,
                  pageSize,
                  extraParams: params,
              })
            : entityType === ANALYST_ENTITY_TYPE.DEPARTMENT
            ? AnalyticsService.GetDepartment(id, {
                  pageIndex: pageIndex === 0 ? 0 : pageIndex - 1,
                  pageSize,
                  extraParams: params,
              })
            : AnalyticsService.GetUser(id, {
                  pageIndex: pageIndex === 0 ? 0 : pageIndex - 1,
                  pageSize,
                  extraParams: params,
              });
    };

    const setAnalistBasicData = (data: AnalistEntityDetailModel): void => {
        formatDougnutData(data);
        setAnalistDetailData(data);
        setStarsPercentageData(data.analistEntityDetailPercentageStarsModel);
    };

    const fetchData = useCallback(
        async ({
            donutStatus,
            pageIndex = 0,
            tabIndex,
            endDateProp,
            startDateProp,
            selectedTaskType,
            selectedWorkingPosition,
        }: AnalistFetchDataProps) => {
            setIsDataLoading(true);
            setIsTaskListDataLoading(true);
            setTaskListDataPageIndex(0);

            if (!id) return;
            const taskListTab = tabIndex !== undefined ? tabIndex : currentTaskListDataTab;
            setCurrentTaskListDataTab(taskListTab);
            const filterDataFetch: AnalistSecondaryFilterModel = {
                taskType: selectedTaskType || filterData.taskType,
                workingPosition: selectedWorkingPosition || filterData.workingPosition,
            };
            const startDateFetch = startDateProp !== undefined ? startDateProp : startDate;
            const endDateFetch = endDateProp !== undefined ? endDateProp : endDate;

            const analistDataParams: buildParamsprops = {
                taskStatus: donutStatus,
                startDateInput: startDateFetch,
                endDateInput: endDateFetch,
                selectedWorkingPosition: filterDataFetch.workingPosition,
                isCompanyList: entityType === ANALYST_ENTITY_TYPE.COMPANY,
                isDepartmentList: entityType === ANALYST_ENTITY_TYPE.DEPARTMENT,
                noReviewTaskListTab: taskListTab === 0 && !!donutStatus,
                reviewTaskListTab: taskListTab === 1 && !!donutStatus,
                commentTaskListTab: taskListTab === 2 && !!donutStatus,
            };
            const builtAnalistDataParams = buildParams(analistDataParams).toString();

            const analistDataPromise = getAnalistDataPromise(builtAnalistDataParams, pageIndex, id);

            // Check if Selected Donut Status
            if (donutStatus) setSelectedDonutTaskStatus(donutStatus);

            // Fetch ALL Data
            const {
                data: analistData,
                status: analistDataStatus,
                errorResponse: analistDataErrorResponse,
            } = await analistDataPromise;

            // Handle ERROR
            const isRedirected = redirectToRankingPage({
                errorResponse: analistDataErrorResponse,
                status: analistDataStatus(),
            });

            if (isRedirected) {
                handleFetchDataError(isRedirected, !analistDataStatus() ? "analistData" : "counters");
                return;
            }
            setSelectedDonutTaskStatus(analistData.actualStatus);
            setAnalistBasicData(analistData);
            setIsDataLoading(false);
            setIsHeaderLoading(false);

            handleEmptyData(analistData);

            // Fetch TaskList & TaskListCounters
            await fetchTaskListData({
                donutStatus: analistData.actualStatus,
                startDateProp: startDateFetch,
                endDateProp: endDateFetch,
                selectedTaskType: filterDataFetch.taskType,
                selectedWorkingPosition: filterDataFetch.workingPosition,
            });
            setIsTaskListDataLoading(false);
        },
        [id, AnalyticsService, startDate, endDate, filterData.taskType, filterData.workingPosition]
    );

    const handleEmptyData = (analistData: AnalistEntityDetailModel | undefined) => {
        if (analistData == null) {
            setAnalistDataBlocks({
                actualStatus: true,
                entityHeaderInfoModel: true,
                analistEntityDetailDonutModel: true,
                analistEntityDetailPercentageStarsModel: true,
                analistEntityDetailLineChartDataModel: true,
                analistEntityDetailRankingModel: true,
                taskList: true,
            });
            return;
        }

        const data: AnalistDataBlocks = { ...INITIAL_ANALIST_EMPTY_DATA_BLOCKS };
        const {
            actualStatus,
            entityHeaderInfoModel,
            analistEntityDetailDonutModel,
            analistEntityDetailPercentageStarsModel,
            analistEntityDetailRankingModel,
        } = analistData;
        const { totalSharedTimedOutCount, totalTimedOutCount, totalOutOfTimeCount, totalInTimeCount } =
            analistEntityDetailDonutModel;

        const {
            fiveStarsPercentage,
            fourStarsPercentage,
            threeStarsPercentage,
            twoStarsPercentage,
            oneStarsPercentage,
        } = analistEntityDetailPercentageStarsModel;

        const totalCounter =
            (totalSharedTimedOutCount || 0) +
            (totalTimedOutCount || 0) +
            (totalOutOfTimeCount || 0) +
            (totalInTimeCount || 0);
        if (actualStatus.length === 0) data.actualStatus = true;
        if (entityHeaderInfoModel.name === "") data.entityHeaderInfoModel = true;
        if (totalCounter === 0) {
            data.analistEntityDetailDonutModel = true;
            data.analistEntityDetailLineChartDataModel = true;
        }
        if (
            fiveStarsPercentage == null &&
            fourStarsPercentage == null &&
            threeStarsPercentage == null &&
            twoStarsPercentage == null &&
            oneStarsPercentage == null
        )
            data.analistEntityDetailPercentageStarsModel = true;

        if (analistEntityDetailRankingModel == null) data.analistEntityDetailRankingModel = true;
        setAnalistDataBlocks(data);
    };

    // Handle fetch data error
    const handleFetchDataError = (hasError: boolean, toastType: "analistData" | "counters") => {
        if (hasError) {
            toastType === "analistData" &&
                handleToast({
                    title: TranslationErrors.ANALIST_DETAIL_ERROR.replace("{0}", entityType),
                    variant: "danger",
                    type: "alert",
                });

            toastType === "counters" &&
                handleToast({
                    title: TranslationErrors.ANALIST_TASK_COUNTERS_ERROR,
                    variant: "danger",
                    type: "alert",
                });
        }
    };

    // FETCH TASKINSTANCE LIST
    const fetchTaskListData = async ({
        donutStatus,
        pageIndex = 0,
        tabIndex,
        selectedTaskType,
        selectedWorkingPosition,
        startDateProp,
        endDateProp,
    }: AnalistFetchDataProps) => {
        setIsTaskListDataLoading(true);
        const filterDataFetch: AnalistSecondaryFilterModel = {
            taskType: selectedTaskType || filterData.taskType,
            workingPosition: selectedWorkingPosition || filterData.workingPosition,
        };

        const startDateFetch = startDateProp !== undefined ? startDateProp : startDate;
        const endDateFetch = endDateProp !== undefined ? endDateProp : endDate;
        const currentTabFetch = tabIndex !== undefined ? tabIndex : currentTaskListDataTab;

        if (!id) return;
        const params = buildParams({
            taskStatus: donutStatus,
            startDateInput: startDateFetch,
            endDateInput: endDateFetch,
            selectedTaskType: filterDataFetch.taskType,
            selectedWorkingPosition: filterDataFetch.workingPosition,
            noReviewTaskListTab: currentTabFetch === 0,
            reviewTaskListTab: currentTabFetch === 1,
            commentTaskListTab: currentTabFetch === 2,
            entityType: entityType,
        }).toString();

        const taskListDataSr = await AnalyticsService.GetTaskList(id, {
            extraParams: params,
            pageIndex: pageIndex,
            pageSize: pageSize,
        });

        if (!taskListDataSr.status()) {
            handleToast({
                title: t(TranslationErrors.ANALIST_DETAIL_LIST_ERROR),
                variant: "danger",
                type: "alert",
            });
            setTaskListData(undefined);
            setIsTaskListDataLoading(false);
            return;
        }

        setTaskListData(taskListDataSr.data);
        setIsTaskListDataLoading(false);

        const taskListDataCountersSr = await AnalyticsService.GetTaskListCounters(id, {
            extraParams: params,
        });

        setTaskListDataCounters(
            taskListDataCountersSr.status()
                ? taskListDataCountersSr.data
                : {
                      feedbackTasksCount: 0,
                      commentTasksCount: 0,
                      withNoReviewsCount: 0,
                  }
        );
    };

    const getWorkingPosition = async (startDateProp: Date, endDateProp: Date) => {
        const extraParams = new URLSearchParams();

        if (entityType === "COMPANY") {
            extraParams.append("CompanyId", String(id));
        }
        if (entityType === "DEPARTMENT") {
            extraParams.append("DepartmentId", String(id));
        }
        if (entityType === "USER") {
            extraParams.append("UserId", String(id));
        }
        extraParams.append("StartDate", getDateFormattedSelects(startDateProp));
        extraParams.append("EndDate", getDateFormattedSelects(endDateProp));
        const { data, status } = await TarSelectorService.GetWorkingPositionOptionsAssignedToTask({
            extraParams: extraParams.toString(),
        });

        if (!status()) {
            setWorkingPositionOptions([]);
            return;
        }

        setWorkingPositionOptions([{ label: t(TranslationKeys.ALL_QRS), value: "all" }, ...data]);
    };

    const handleDataChange = (dates: Date[]) => {
        onDateChange({ startDate: dates[0], endDate: dates[1] });
        if (dates[1] == null) return;
        getWorkingPosition(dates[0], dates[1]);
        fetchData({ tabIndex: currentTaskListDataTab, startDateProp: dates[0], endDateProp: dates[1] });
    };

    useEffect(() => {
        fetchData({ donutStatus: undefined, tabIndex: currentTaskListDataTab });
    }, [id]);

    useEffect(() => {
        analistDetailData?.entityHeaderInfoModel?.name &&
            updateBreadcrumbs({ name: analistDetailData?.entityHeaderInfoModel?.name });
    }, [analistDetailData]);

    useEffect(() => {
        getWorkingPosition(startDate, endDate);
    }, []);

    return (
        <>
            <AnalistLayout
                isLoading={isHeaderLoading}
                breadCrums={breadcrumbs}
                avatar={{
                    avatarName: analistDetailData?.entityHeaderInfoModel?.name || "",
                    colorId: Number(id),
                    img: analistDetailData?.entityHeaderInfoModel?.photoURL ?? undefined,
                }}
                rating={{
                    starRatingAVG: analistDetailData?.entityHeaderInfoModel?.avg || 0,
                    total: analistDetailData?.entityHeaderInfoModel?.totalTasks || 0,
                    trend: Number((analistDetailData?.entityHeaderInfoModel?.trend || 0).toFixed(0)),
                }}
                filters={
                    <div className="analistDetail__filters">
                        <div className="analistDetail__filters__date">
                            <DateRangePicker
                                onChange={handleDataChange}
                                startDate={startDate}
                                endDate={endDate}
                                showMoreOptions
                                range
                                inverted
                                showToday={false}
                                disableFutureDates
                                min={
                                    analistDetailData?.entityHeaderInfoModel.createdDate &&
                                    new Date(analistDetailData?.entityHeaderInfoModel.createdDate)
                                }
                            />
                        </div>
                    </div>
                }
                widthVariant={"small"}
            >
                <div className="analistDetail">
                    {entityType !== ANALYST_ENTITY_TYPE.USER && (
                        <div className="analistDetail__buttons">
                            {entityType === ANALYST_ENTITY_TYPE.COMPANY && (
                                <Button
                                    iconRight={faArrowRight}
                                    text={t(TranslationKeys.SEE_RANKING_DEPARTMENTS)}
                                    type="secondary"
                                    onClick={() => nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-teams/${id}`)}
                                    fullWidth={width < screenSize.MOBILE}
                                />
                            )}
                            {(entityType === ANALYST_ENTITY_TYPE.COMPANY ||
                                entityType === ANALYST_ENTITY_TYPE.DEPARTMENT) && (
                                <Button
                                    iconRight={faArrowRight}
                                    text={t(TranslationKeys.SEE_RANKING_USERS)}
                                    type="secondary"
                                    onClick={() =>
                                        entityType === ANALYST_ENTITY_TYPE.COMPANY
                                            ? nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-users?companyId=${id}`)
                                            : nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-users/${id}`)
                                    }
                                    fullWidth={width < screenSize.MOBILE}
                                />
                            )}
                        </div>
                    )}

                    {(analistDataBlocks.actualStatus ||
                        analistDataBlocks.entityHeaderInfoModel ||
                        analistDataBlocks.analistEntityDetailDonutModel) &&
                    !isDataLoading ? (
                        <TableError
                            icon={faListCheck}
                            title={t(TranslationKeys.NO_VALORATION)}
                            description={t(TranslationKeys.NO_VALORATION_SUBTITLE)}
                        />
                    ) : (
                        <>
                            <AnalistDetailBody
                                entityType={entityType}
                                selectedTaskStatus={selectedDonutTaskStatus}
                                donut={
                                    <AnalistDetailDonut
                                        entityType={entityType}
                                        isLoading={isDataLoading}
                                        doughnutData={doughnutData}
                                        fetchData={fetchData}
                                    />
                                }
                                donutList={
                                    entityType !== ANALYST_ENTITY_TYPE.USER && (
                                        <AnalistDetailDonutList
                                            entityType={entityType}
                                            isLoading={isDataLoading}
                                            rankingList={analistDetailData?.analistEntityDetailRankingModel ?? []}
                                            taskStatus={selectedDonutTaskStatus}
                                        />
                                    )
                                }
                                stars={
                                    <AnalistDetailStars
                                        isLoading={isDataLoading}
                                        starsPercentageData={starsPercentageData}
                                        hasNoData={
                                            analistDataBlocks.analistEntityDetailPercentageStarsModel && !isDataLoading
                                        }
                                    />
                                }
                                barChart={
                                    <AnalistDetailBarChart
                                        isLoading={isDataLoading}
                                        chartData={analistDetailData?.analistEntityDetaiBarChartDataModel || null}
                                        hasNoData={
                                            analistDataBlocks.analistEntityDetailLineChartDataModel && !isDataLoading
                                        }
                                    />
                                }
                                taskList={
                                    <AnalistDetailTaskList
                                        isLoading={isTaskListDataLoading}
                                        taskListData={taskListData}
                                        pageIndex={taskListDataPageIndex}
                                        pageSize={pageSize}
                                        handleTaskListPagination={handleTaskListPagination}
                                        handleTaskListTabChange={handleTaskListTabChange}
                                        setShowTaskDetail={setShowTaskDetail}
                                        taskCounters={taskListDataCounters}
                                        currentTab={currentTaskListDataTab}
                                        entityType={entityType}
                                    />
                                }
                                onSecondaryFilterClick={() => setShowSecondaryFilter(true)}
                                selectedTab={Object.values(AnalistTaskInstanceListTabsType)[currentTaskListDataTab]}
                            />
                            {showTaskDetail && (
                                <TaskDetailModal
                                    taskInstanceId={String(showTaskDetail)}
                                    onClose={() => setShowTaskDetail(undefined)}
                                    isReviewPhotos
                                    editTaskDetail
                                    allowCompleteTask={hasPermission(SecScreen.COMPLETE_TASK_AN)}
                                    editStarRating
                                    showValoratedBy
                                />
                            )}
                        </>
                    )}
                </div>
            </AnalistLayout>

            <AnalistDetailFilter
                filterData={filterData}
                initialFilterdata={INITIAL_ANALIST_SECONDARY_FILTER}
                isOpen={showSecondaryFilter}
                onSave={handleSaveSecondaryFilter}
                taskTypeOptions={taskTypeOptions}
                workingPositionOptions={workingPositionOptions}
                onCancel={() => setShowSecondaryFilter(false)}
            />
        </>
    );
};
