import { MultipleAlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/MultipleAlertNotificationDto";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";

export default class AlertQRService {
    // GET Alert Notification List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AlertNotificationDto>>({
            url: `${ApiPaths.TAR_API}/AlertQr`,
            paginationParams: params,
        });
    }

    // POST New AlertQR Notification
    public static async InsertMultiple(values: MultipleAlertNotificationDto) {
        return FetchService.post<number>({
            url: `${ApiPaths.TAR_API}/AlertQR`,
            body: values,
        });
    }

    // DELETE Multiple Alerts
    public static async DeleteMultiple(paginationParams?: PaginationParams) {
        return FetchService.delete({
            url: `${ApiPaths.TAR_API}/AlertQR`,
            paginationParams,
        });
    }
}
