import { PrivatePaths } from "app/shared/Constants";
import { PatchNotesModalContext } from "app/state/context/PatchNotesModalContext/PatchNotesModalContext";
import { useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const useSearchParamsActions = () => {
    const [searcParams] = useSearchParams();
    const location = useLocation();
    const nav = useNavigate();

    const { openModal: openPatchNotesModal } = useContext(PatchNotesModalContext);

    const redirectWithSearchParams = () => {
        if (handlePatchNotes()) return;

        window ? nav(`${location.pathname}${location.search}`) : nav("/" + PrivatePaths.DASHBOARD.split("/")[0]);
    };

    const handlePatchNotes = () => {
        const patchNoteVersionIdParam =
            searcParams.get("patchNoteVersionId") || localStorage.getItem("patchNoteVersionId");
        if (patchNoteVersionIdParam == null) return false;
        const patchNoteVersionIdParamNumeric = Number(patchNoteVersionIdParam);
        openPatchNotesModal(patchNoteVersionIdParamNumeric, { readonly: true });
        nav(location.pathname);

        return true;
    };

    return { redirectWithSearchParams };
};
