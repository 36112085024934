export enum TranslationTitles {
    // 4LINK
    FOR_LINK = "4LINK®",

    // PERMISSIONS
    PERMISSION_TITLE_ASSET_PAGE_PERMISSION = "PERMISSION_TITLE_ASSET_PAGE_PERMISSION",
    PERMISSION_TITLE_ASSET_RANGE_PAGE_PERMISSION = "PERMISSION_TITLE_ASSET_RANGE_PAGE_PERMISSION",
    PERMISSION_TITLE_TASK_PHOTO = "PERMISSION_TITLE_TASK_PHOTO",
    PERMISSION_TITLE_FEEDBACK_TASKS = "PERMISSION_TITLE_FEEDBACK_TASKS",
    PERMISSION_TITLE_ADMIN = "PERMISSION_TITLE_ADMIN",
    PERMISSION_TITLE_ANALYST = "PERMISSION_TITLE_ANALYST",
    PERMISSION_TITLE_SUPERVISOR = "PERMISSION_TITLE_SUPERVISOR",
    PERMISSION_TITLE_EMPLOYEE = "PERMISSION_TITLE_EMPLOYEE",
    PERMISSION_TITLE_ALERTS_NOTIFICATIONS = "PERMISSION_TITLE_ALERTS_NOTIFICATIONS",
    PERMISSION_TITLE_TAR_MANAGEMENT = "PERMISSION_TITLE_TAR_MANAGEMENT",
    PERMISSION_TITLE_ISSUE_MANAGEMENT = "PERMISSION_TITLE_ISSUE_MANAGEMENT",
    PERMISSION_TITLE_ACCES_CONFIG = "PERMISSION_TITLE_ACCES_CONFIG",
    PERMISSION_TITLE_CENTERS_MANAGEMENT = "PERMISSION_TITLE_CENTERS_MANAGEMENT",
    PERMISSION_TITLE_ROLE_MANAGEMENT = "PERMISSION_TITLE_ROLE_MANAGEMENT",
    PERMISSION_TITLE_TEAM_MANAGEMENT = "PERMISSION_TITLE_TEAM_MANAGEMENT",
    PERMISSION_TITLE_TEMPORARY_ROLES = "PERMISSION_TITLE_TEMPORARY_ROLES",
    PERMISSION_TITLE_USER_MANAGEMENT = "PERMISSION_TITLE_USER_MANAGEMENT",
    PERMISSION_TITLE_BASE_TASK_MANAGEMENT = "PERMISSION_TITLE_BASE_TASK_MANAGEMENT",
    PERMISSION_TITLE_COMPANY_RANKING = "PERMISSION_TITLE_COMPANY_RANKING",
    PERMISSION_TITLE_TASK_HISTORY = "PERMISSION_TITLE_TASK_HISTORY",
    PERMISSION_TITLE_TASK_REVIEW = "PERMISSION_TITLE_TASK_REVIEW",
    PERMISSION_TITLE_MY_VALORATION = "PERMISSION_TITLE_MY_VALORATION",
    PERMISSION_TITLE_RESET_TASK = "PERMISSION_TITLE_RESET_TASK",
    PERMISSION_TITLE_ALERTS = "PERMISSION_TITLE_ALERTS",
    PERMISSION_TITLE_SYSTEM_NOTIFICATION = "PERMISSION_TITLE_SYSTEM_NOTIFICATION",
    PERMISSION_TITLE_ASSETS = "PERMISSION_TITLE_ASSETS",
    PERMISSION_TITLE_QR = "PERMISSION_TITLE_QR",
    PERMISSION_TITLE_REASIGN_TASK = "PERMISSION_TITLE_REASIGN_TASK",
    PERMISSION_TITLE_TASK = "PERMISSION_TITLE_TASK",
    PERMISSION_TITLE_ATTACH_FILES = "PERMISSION_TITLE_ATTACH_FILES",
    PERMISSION_TITLE_CLOSE_ISSUE = "PERMISSION_TITLE_CLOSE_ISSUE",
    PERMISSION_TITLE_OPEN_MANUAL_ISSUE = "PERMISSION_TITLE_OPEN_MANUAL_ISSUE",
    PERMISSION_TITLE_OPEN_TASK_ISSUE = "PERMISSION_TITLE_OPEN_TASK_ISSUE",
    PERMISSION_TITLE_REOPEN_ISSUE = "PERMISSION_TITLE_REOPEN_ISSUE",
    PERMISSION_TITLE_ISSUE = "PERMISSION_TITLE_ISSUE",
    PERMISSION_TITLE_ALLOW_GALERY_PHOTO = "PERMISSION_TITLE_ALLOW_GALERY_PHOTO",
    PERMISSION_TITLE_JOB_DESC_MANAGER = "PERMISSION_TITLE_JOB_DESC_MANAGER",
    PERMISSION_TITLE_APPCC_MODULE = "PERMISSION_TITLE_APPCC_MODULE",
    PERMISSION_TITLE_PLAN_APPCC = "PERMISSION_TITLE_PLAN_APPCC",
    PERMISSION_TITLE_REGISTRY_MANAGEMENT = "PERMISSION_TITLE_REGISTRY_MANAGEMENT",
    PERMISSION_TITLE_CORR_MEASURE_MANAGER = "PERMISSION_TITLE_CORR_MEASURE_MANAGER",
    PERMISSION_TITLE_AUDIT_MANAGEMENT = "PERMISSION_TITLE_AUDIT_MANAGEMENT",
    PERMISSION_TITLE_AUDIT_GROUP_MANAGEMENT = "PERMISSION_TITLE_AUDIT_GROUP_MANAGEMENT",
    PERMISSION_TITLE_AG_CHECKLIST_MANAGEMENT = "PERMISSION_TITLE_AG_CHECKLIST_MANAGEMENT",
    PERMISSION_TITLE_AGCL_ITEM_MANAGEMENT = "PERMISSION_TITLE_AGCL_ITEM_MANAGEMENT",
    PERMISSION_TITLE_AUDIT_INSTANCE_MANAG = "PERMISSION_TITLE_AUDIT_INSTANCE_MANAG",
    PERMISSION_TITLE_AUDIT_INSTANCE_NC = "PERMISSION_TITLE_AUDIT_INSTANCE_NC",
    PERMISSION_TITLE_AUDIT_INSTANCE_DEL_NC = "PERMISSION_TITLE_AUDIT_INSTANCE_DEL_NC",
    PERMISSION_TITLE_AUDIT_INST_REPORT = "PERMISSION_TITLE_AUDIT_INST_REPORT",
    PERMISSION_TITLE_SPORADIC_TASK = "PERMISSION_TITLE_SPORADIC_TASK",
    PERMISSION_TITLE_AUTOMATIC_RECORD = "PERMISSION_TITLE_AUTOMATIC_RECORD",
    PERMISSION_TITLE_EMAIL_DISTRIBUTION_LIST = "PERMISSION_TITLE_EMAIL_DISTRIBUTION_LIST",
    PERMISSION_TITLE_CLOSE_COMPANY_DEPT = "PERMISSION_TITLE_CLOSE_COMPANY_DEPT",
    PERMISSION_TITLE_REGISTRY_HISTORY = "PERMISSION_TITLE_REGISTRY_HISTORY",
    PERMISSION_TITLE_BASE_TASK_APPCC = "PERMISSION_TITLE_BASE_TASK_APPCC",
    PERMISSION_TITLE_ASSET_NOT_WORKING = "PERMISSION_TITLE_ASSET_NOT_WORKING",
    PERMISSION_TITLE_ASSET_NOT_OPERATIVE = "PERMISSION_TITLE_ASSET_NOT_OPERATIVE",
    PERMISSION_TITLE_COMPLETE_TASK_AN = "PERMISSION_TITLE_COMPLETE_TASK_AN",
    PERMISSION_TITLE_COMPLETE_TASK_REV = "PERMISSION_TITLE_COMPLETE_TASK_REV",
    PERMISSION_TITLE_ASSET_RANGE = "PERMISSION_TITLE_ASSET_RANGE",

    // PAGE TITLES
    LOGIN_PAGE_TITLE = "LOGIN_PAGE_TITLE",
    RECOVER_PAGE_TITLE = "RECOVER_PAGE_TITLE",
    RESET_PASSWORD_PAGE_TITLE = "RESET_PASSWORD_PAGE_TITLE",
    ACTIVATE_ACCOUNT_PAGE_TITLE = "ACTIVATE_ACCOUNT_PAGE_TITLE",
    SELECT_PROFILE_PAGE_TITLE = "SELECT_PROFILE_PAGE_TITLE",
    SELECT_DEPARTMENT_SUBDEPARTMENT_PAGE_TITLE = "SELECT_DEPARTMENT_SUBDEPARTMENT_PAGE_TITLE",
    GROUP_COMPANY_PAGE_TITLE = "GROUP_COMPANY_PAGE_TITLE",
    COMPANY_EDIT_PAGE = "COMPANY_EDIT_PAGE",
    DEPARTMENT_AND_SUBDEPARTMENT_PAGE_TITLE = "DEPARTMENT_AND_SUBDEPARTMENT_PAGE_TITLE",
    LICENSES_PAGE_TITLE = "LICENSES_PAGE_TITLE",
    MANAGE_LICENSE_PAGE_TITLE = "MANAGE_LICENSE_PAGE_TITLE",
    MY_PROFILE_PAGE_TITLE = "MY_PROFILE_PAGE_TITLE",
    ROLES_PAGE_TITLE = "ROLES_PAGE_TITLE",
    EDIT_ROLE_PAGE_TITLE = "EDIT_ROLE_PAGE_TITLE",
    TEMPORARY_ROLES_PAGE_TITLE = "TEMPORARY_ROLES_PAGE_TITLE",
    USERS_PAGE_TITLE = "USERS_PAGE_TITLE",
    NEW_USER_PAGE_TITLE = "NEW_USER_PAGE_TITLE",
    EDIT_USER_PAGE_TITLE = "EDIT_USER_PAGE_TITLE",
    USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE = "USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE",
    DASHBOARD_PAGE_TITLE = "DASHBOARD_PAGE_TITLE",
    TASK_DETAIL_TITLE = "TASK_DETAIL_TITLE",
    SCAN_QR_PAGE_TITLE = "SCAN_QR_PAGE_TITLE",
    REVIEW_TASK_PAGE_TITLE = "REVIEW_TASK_PAGE_TITLE",
    REVIEW_WORKER_PAGE_TITLE = "REVIEW_WORKER_PAGE_TITLE",
    FEEDBACK_DETAIL_TITLE = "FEEDBACK_DETAIL_TITLE",
    FEEDBACK_PAGE_TITLE = "FEEDBACK_PAGE_TITLE",
    TASK_PAGE_TITLE = "TASK_PAGE_TITLE",
    NEW_TASK_PAGE_TITLE = "NEW_TASK_PAGE_TITLE",
    DUPLICATE_TASK_PAGE_TITLE = "DUPLICATE_TASK_PAGE_TITLE",
    EDIT_TASK_PAGE_TITLE = "EDIT_TASK_PAGE_TITLE",
    TASK_HISTORY_PAGE_TITLE = "TASK_HISTORY_PAGE_TITLE",
    REGISTRY_HISTORY_PAGE_TITLE = "REGISTRY_HISTORY_PAGE_TITLE",
    ALERTS_PAGE_TITLE = "ALERTS_PAGE_TITLE",
    INCOMING_ALERTS_TITLE = "INCOMING_ALERTS_TITLE",
    PRIVACY_POLICY_PAGE_TITLE = "PRIVACY_POLICY_PAGE_TITLE",
    WORKING_POSITION_PAGE_TITLE = "WORKING_POSITION_PAGE_TITLE",
    NEW_WORKING_POSITION_PAGE_TITLE = "NEW_WORKING_POSITION_PAGE_TITLE",
    EDIT_WORKING_POSITION_PAGE_TITLE = "EDIT_WORKING_POSITION_PAGE_TITLE",
    PRINT_QR_PAGE_TITLE = "PRINT_QR_PAGE_TITLE",
    BASE_TASK_PAGE_TITLE = "BASE_TASK_PAGE_TITLE",
    NEW_BASE_TASK_PAGE_TITLE = "NEW_BASE_TASK_PAGE_TITLE",
    EDIT_BASE_TASK_PAGE_TITLE = "EDIT_BASE_TASK_PAGE_TITLE",
    GLOBAL_COMPANY_RANKING_PAGE_TITLE = "GLOBAL_COMPANY_RANKING_PAGE_TITLE",
    GLOBAL_DEPARTMENT_RANKING_PAGE_TITLE = "GLOBAL_DEPARTMENT_RANKING_PAGE_TITLE",
    GLOBAL_USER_RANKING_PAGE_TITLE = "GLOBAL_USER_RANKING_PAGE_TITLE",
    USER_BY_DEPARTMENT_RANKING_PAGE_TITLE = "USER_BY_DEPARTMENT_RANKING_PAGE_TITLE",
    ANALYTICS_PAGE_TITLE = "ANALYTICS_PAGE_TITLE",
    DEPARTMENT_RANKING_PAGE_TITLE = "DEPARTMENT_RANKING_PAGE_TITLE",
    COMPANY_DETAIL_PAGE_TITLE = "COMPANY_DETAIL_PAGE_TITLE",
    DEPARTMENT_DETAIL_PAGE_TITLE = "DEPARTMENT_DETAIL_PAGE_TITLE",
    USER_DETAIL_PAGE_TITLE = "USER_DETAIL_PAGE_TITLE",
    DETAIL_PAGE_TITLE = "DETAIL_PAGE_TITLE",
    ASSET_PAGE_TITLE = "ASSET_PAGE_TITLE",
    ASSET_RANGE_PAGE_TITLE = "ASSET_RANGE_PAGE_TITLE",
    NEW_ASSET_PAGE_TITLE = "NEW_ASSET_PAGE_TITLE",
    EDIT_ASSET_PAGE_TITLE = "EDIT_ASSET_PAGE_TITLE",
    AUDIT_PAGE_TITLE = "AUDIT_PAGE_TITLE",
    NEW_AUDIT_PAGE_TITLE = "NEW_AUDIT_PAGE_TITLE",
    COMPLETED_AUDIT_PAGE_TITLE = "COMPLETED_AUDIT_PAGE_TITLE",
    REVIEWED_AUDIT_PAGE_TITLE = "REVIEWED_AUDIT_PAGE_TITLE",
    RESOLVE_AUDIT_PAGE_TITLE = "RESOLVE_AUDIT_PAGE_TITLE",
    REVIEW_AUDIT_PAGE_TITLE = "REVIEW_AUDIT_PAGE_TITLE",
    AUDIT_TEMPLATE_PAGE_TITLE = "AUDIT_TEMPLATE_PAGE_TITLE",
    CORRECTIVE_MEASURES_PAGE_TITLE = "CORRECTIVE_MEASURES_PAGE_TITLE",
    ISSUE_PAGE_TITLE = "ISSUE_PAGE_TITLE",
    NEW_ISSUE_PAGE_TITLE = "NEW_ISSUE_PAGE_TITLE",
    EDIT_ISSUE_PAGE_TITLE = "EDIT_ISSUE_PAGE_TITLE",
    PLAN_APPCC_PAGE_TITLE = "PLAN_APPCC_PAGE_TITLE",
    TASK_APPCC_PAGE_TITLE = "TASK_APPCC_PAGE_TITLE",
    NEW_TASK_APPCC_PAGE_TITLE = "NEW_TASK_APPCC_PAGE_TITLE",
    DUPLICATE_TASK_APPCC_PAGE_TITLE = "DUPLICATE_TASK_APPCC_PAGE_TITLE",
    EDIT_TASK_APPCC_PAGE_TITLE = "EDIT_TASK_APPCC_PAGE_TITLE",
    FAQS_PAGE_TITLE = "FAQS_PAGE_TITLE",
    EDIT_ISSUE_TITLE = "EDIT_ISSUE_TITLE",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM = "BASE_AUDIT_GROUP_CHECKLIST_ITEM",
    AUTOMATIC_RECORD_PAGE_TITLE = "AUTOMATIC_RECORD_PAGE_TITLE",
    EMAIL_DISTRIBUTION_LIST_TITLE = "EMAIL_DISTRIBUTION_LIST_TITLE",

    // TITLES
    USERS_TITLE = "USERS_TITLE",
    GROUP_COMPANY_TITLE = "GROUP_COMPANY_TITLE",
    ROLES_MAINTENANCE_TITLE = "ROLES_MAINTENANCE_TITLE",
    ROLES_TEMPORARY_TITLE = "ROLES_TEMPORARY_TITLE",
    DEPARTMENT_TITLE = "DEPARTMENT_TITLE",
    GENERAL_INFO_TITLE = "GENERAL_INFO_TITLE",
    COMPANIES_AND_DEPARTMENTS_TITLE = "COMPANIES_AND_DEPARTMENTS_TITLE",
    COMPANIES_MAINTENANCE_TITLE = "COMPANIES_MAINTENANCE_TITLE",
    LICENSES_TITLE = "LICENSES_TITLE",
    TEMPORARY_ROLES_TITLE = "TEMPORARY_ROLES_TITLE",
    SCAN_QR_TASK_TITLE = "SCAN_QR_TASK_TITLE",
    SCAN_QR_SENSOR_TITLE = "SCAN_QR_SENSOR_TITLE",
    MY_TASKS_HEADER_TITLE = "MY_TASKS_HEADER_TITLE",
    CONFIGURATION_TITLE = "CONFIGURATION_TITLE",
    REVIEW_TASKS_TITLE = "REVIEW_TASKS_TITLE",
    REVIEW_FEEDBACK_TITLE = "REVIEW_FEEDBACK_TITLE",
    WORKING_POSITION_TITLE = "WORKING_POSITION_TITLE",
    RECEIVED_ALERTS_TITLE = "RECEIVED_ALERTS_TITLE",
    BASE_TASK_TITLE = "BASE_TASK_TITLE",
    BASE_TASK_REGISTRY_TITLE = "BASE_TASK_REGISTRY_TITLE",
    RANKING_COMPANY_TITLE = "RANKING_COMPANY_TITLE",
    RANKING_DEPARTMENT_TITLE = "RANKING_DEPARTMENT_TITLE",
    RANKING_USERS_BY_DEPARTMENT_TITLE = "RANKING_USERS_BY_DEPARTMENT_TITLE",
    AUDIT_TEMPLATE_TITLE = "AUDIT_TEMPLATE_TITLE",
    AUDIT_GRID_TITLE = "AUDIT_GRID_TITLE",
    PATCH_NOTES_TITLE = "PATCH_NOTES_TITLE",

    // HEADER TITLES
    HEADER_REVIEW_TASKS_TITLE = "HEADER_REVIEW_TASKS_TITLE",
    HEADER_REVIEW_WORKERS_TITLE = "HEADER_REVIEW_WORKERS_TITLE",
    HEADER_PLAN_APPCC_TITLE = "HEADER_PLAN_APPCC_TITLE",
    HEADER_ON_BOARDING_TITLE = "HEADER_ON_BOARDING_TITLE",
}

export enum TranslationCommon {
    // Permission Patent
    ALL_PATENT = "ALL_PATENT",
    ADD_PATENT = "ADD_PATENT",
    VIEW_PATENT = "VIEW_PATENT",
    MODIFY_PATENT = "MODIFY_PATENT",
    DELETE_PATENT = "DELETE_PATENT",

    YES = "YES",
    NO = "NO",
    START = "START",
    NEXT = "NEXT",
    PREVIOUS = "PREVIOUS",
    EXIT = "EXIT",
    ADD = "ADD",
    NEW = "NEW",
    NEW_M = "NEW_M",
    SEARCH = "SEARCH",
    CHANGE_COMPANY = "CHANGE_COMPANY",
    FILTER = "FILTER",
    SUBMIT = "SUBMIT",
    SAVE = "SAVE",
    YES_CHANGE = "YES_CHANGE",
    NO_GOBACK = "NO_GOBACK",
    ACCEPT = "ACCEPT",
    CANCEL = "CANCEL",
    CONFIRM = "CONFIRM",
    LANGUAGE = "LANGUAGE",
    LOGOUT = "LOGOUT",
    COMPLETE = "COMPLETE",
    NO_RESULTS = "NO_RESULTS",
    ACCESS_DENIED = "ACCESS_DENIED",
    GO_BACK = "GO_BACK",
    RELOAD = "RELOAD",
    ARE_YOU_SURE = "ARE_YOU_SURE",
    EDIT = "EDIT",
    DELETE = "DELETE",
    FILTERS = "FILTERS",
    DETAILS = "DETAILS",
    INPUT_NOT_EMPTY = "INPUT_NOT_EMPTY",
    INVALID_NIF = "INVALID_NIF",
    FAILED_DATA_LOADED = "FAILED_DATA_LOADED",
    ACTION_CANNOT_BE_COMPLETED = "ACTION_CANNOT_BE_COMPLETED",
    ENTITY_HAS_CHILDREN = "ENTITY_HAS_CHILDREN",
    SELECT_OPTION = "SELECT_OPTION",
    NO_MORE_OPTIONS = "NO_MORE_OPTIONS",
    NOT_FOUND = "NOT_FOUND",
    SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
    CREATE = "CREATE",
    VIEW = "VIEW",
    MODIFY = "MODIFY",
    TOTAL_ACCESS = "TOTAL_ACCESS",
    NO_DATA_FOR_YOUR_SEARCH = "NO_DATA_FOR_YOUR_SEARCH",
    STATUS = "STATUS",
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    ORDER_BY_TABLE = "ORDER_BY_TABLE",
    ASCENDENT = "ASCENDENT",
    DESCENDENT = "DESCENDENT",
    SKIP = "SKIP",
    ACTIVES = "ACTIVES",
    IMAGE_UPLOAD_FAILED = "IMAGE_UPLOAD_FAILED",
    IMAGE_UPLOAD_SUCCEED = "IMAGE_UPLOAD_SUCCEED",
    DATA = "DATA",
    TIME_TO = "TIME_TO",
    TIME_FROM = "TIME_FROM",
    PRINT = "PRINT",
    UPLOADING_FILE = "UPLOADING_FILE",
    TRANSCRIPTING = "TRANSCRIPTING",
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    CLOSE = "CLOSE",
    START_DATE_LABEL = "START_DATE_LABEL",
    SELECT_ALL = "SELECT_ALL",
    ADD_ALL = "ADD_ALL",
    OMIT = "OMIT",
}

export enum TranslationKeys {
    SCHEDULE_SEND = "SCHEDULE_SEND",
    USERS = "USERS",
    ADD_USERS = "ADD_USERS",
    SELECT_USERS = "SELECT_USERS",
    PRIVACY_POLICY = "PRIVACY_POLICY",
    PRIVACY_POLICY_TEXT = "PRIVACY_POLICY_TEXT",
    PRIVACY_POLICY_ACCEPT = "PRIVACY_POLICY_ACCEPT",
    WORKING_POSITION = "WORKING_POSITION",
    RANKING = "RANKING",

    RECORDING = "RECORDING",
    UPLOAD_TYPE = "UPLOAD_TYPE",
    CLICK_TO_UPLOAD = "CLICK_TO_UPLOAD",
    ADDEDS_USERS_TO_TEAM = "ADDEDS_USERS_TO_TEAM",
    DELETEDS_USERS_TO_TEAM = "DELETEDS_USERS_TO_TEAM",
    SCHEDULE_SEND_NEXT_DAY_MORNING = "SCHEDULE_SEND_NEXT_DAY_MORNING",
    SCHEDULE_SEND_NEXT_DAY_AFTERNOON = "SCHEDULE_SEND_NEXT_DAY_AFTERNOON",
    SCHEDULE_SEND_NEXT_MONDAY = "SCHEDULE_SEND_NEXT_MONDAY",

    TOTAL_RECORDS = "TOTAL_RECORDS",
    NO_DEPARTMENTS = "NO_DEPARTMENTS",
    NO_DEPARTMENTS_ASSIGNED = "NO_DEPARTMENTS_ASSIGNED",
    NO_SUBDEPARTMENTS = "NO_SUBDEPARTMENTS",

    TO_DASHBOARD = "TO_DASHBOARD",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    FORGOT_PASSWORD_TITLE = "FORGOT_PASSWORD_TITLE",
    RESET_PASSWORD_TITLE = "RESET_PASSWORD_TITLE",
    NEW_PASSWORD_TITLE = "NEW_PASSWORD_TITLE",

    DELETE_MESSAGE = "DELETE_MESSAGE",
    CLONE_MESSAGE = "CLONE_MESSAGE",
    MESSAGE = "MESSAGE",
    DELETE_DEPARTMENT = "DELETE_DEPARTMENT",
    DELETE_DEPARTMENT_TEXT = "DELETE_DEPARTMENT_TEXT",
    EDIT_DEPARTMENT = "EDIT_DEPARTMENT",
    ADD_USER = "ADD_USER",
    ADD_USER_TEXT = "ADD_USER_TEXT",
    ADD_SUBDEPARTMENT = "ADD_SUBDEPARTMENT",
    EDIT_SUBDEPARTMENT = "EDIT_SUBDEPARTMENT",
    DELETE_SUBDEPARTMENT = "DELETE_SUBDEPARTMENT",
    DELETE_SUBDEPARTMENT_TEXT = "DELETE_SUBDEPARTMENT_TEXT",
    SELECT_SUBDEPARTMENTS = "SELECT_SUBDEPARTMENTS",
    SUBDEPARTMENT_NAME = "SUBDEPARTMENT_NAME",
    NEW_SUBDEPARTMENT = "NEW_SUBDEPARTMENT",
    SELECT_USER = "SELECT_USER",
    DRAG_FILES = "DRAG_FILES",
    UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE",
    INPUT_VALID_EMAIL = "INPUT_VALID_EMAIL",
    USERCODE_MIN_LENGTH = "USERCODE_MIN_LENGTH",

    INPUT_NOT_EMPTY_POLICY = "INPUT_NOT_EMPTY_POLICY",
    INPUT_MAX_CHARACTERS = "INPUT_MAX_CHARACTERS",
    INPUT_MINIMUN = "INPUT_MINIMUN",
    INPUT_RANGE = "INPUT_RANGE",
    INPUT_LENGTH = "INPUT_LENGTH",
    INPUT_FIXED_LENGTH = "INPUT_FIXED_LENGTH",

    SELECT_INSTANCE = "SELECT_INSTANCE",
    PLEASE_SELECT_INSTANCE = "PLEASE_SELECT_INSTANCE",
    USER_MADE_ACTION = "USER_MADE_ACTION",

    MANAGE_USERS = "MANAGE_USERS",
    MODIFY_NAME = "MODIFY_NAME",
    ROLE_NAME = "ROLE_NAME",
    EDIT_ROLE = "EDIT_ROLE",
    DASHBOARD_PLACEHOLDER_TEXT = "DASHBOARD_PLACEHOLDER_TEXT",
    NO_DEPARTMENTS_FOR_YOUR_SEARCH = " NO_DEPARTMENTS_FOR_YOUR_SEARCH",
    NO_USERS_FOR_YOUR_SEARCH = " NO_USERS_FOR_YOUR_SEARCH",
    NO_TASKS_FOR_YOUR_SEARCH = " NO_TASKS_FOR_YOUR_SEARCH",
    NO_TASKS_FOR_YOUR_SEARCH_DEPARTMENTS = " NO_TASKS_FOR_YOUR_SEARCH_DEPARTMENTS",
    NO_TASKS_FOR_YOUR_SEARCH_USERS = " NO_TASKS_FOR_YOUR_SEARCH_USERS",
    NO_TASKS_FOR_YOUR_SEARCH_USERS_TEXT = "NO_TASKS_FOR_YOUR_SEARCH_USERS_TEXT",
    EDIT_PROFILE_PICTURE = "EDIT_PROFILE_PICTURE",
    EDIT_LOGO = "EDIT_LOGO",
    IMPERSONATE = "IMPERSONATE",
    IMPERSONATING_TO = "IMPERSONATING_TO",
    PERSONALIZATION = "PERSONALIZATION",
    PERSONALIZED = "PERSONALIZED",
    TASK_IS_DISABLED = "TASK_IS_DISABLED",
    MAX_30_MB = "MAX_30_MB",
    EDITING_SUBDEPARTMENT_MODAL_TEXT = "EDITING_SUBDEPARTMENT_MODAL_TEXT",
    NO_USERS_TEXT = "NO_USERS_TEXT",
    ALL_ROLES_ADDED = "ALL_ROLES_ADDED",
    DUPLICATE_USERNAME = "DUPLICATE_USERNAME",
    CSV_DOWNLOAD = "CSV_DOWNLOAD",
    CSV_DOWNLOAD_ROWS = "CSV_DOWNLOAD_ROWS",
    CSV_EXPORT = "CSV_EXPORT",
    ROWS = "ROWS",
    ROW_PAGES = "ROW_PAGES",
    ROW_FROM_THE_PAGE = "ROW_FROM_THE_PAGE",
    EXPORT_ALL = "EXPORT_ALL",
    MAX_RECORDS = "MAX_RECORDS",
    FROM_CURRENT_PAGE = "FROM_CURRENT_PAGE",
    DOWNLOAD = "DOWNLOAD",
    ROW_AMOUNT = "ROW_AMOUNT",
    MAX_CSV_ROWS = "MAX_CSV_ROWS",
    INSTANCE_NAME = "INSTANCE_NAME",
    CONTACT = "CONTACT",
    MANAGE = "MANAGE",
    MAIN_CONTACT = "MAIN_CONTACT",
    PRIMARY_ADMINISTRATOR = "PRIMARY_ADMINISTRATOR",
    CONFIRMATION = "CONFIRMATION",
    NO_GROUPS_TEXT = "NO_GROUPS_TEXT",
    NO_GROUPS_TEXT_F = "NO_GROUPS_TEXT_F",
    EMPTY_DEPARTMENT = "EMPTY_DEPARTMENT",
    ENTER_USERNAME = "ENTER_USERNAME",
    INSERT_ROLE_NAME = "INSERT_ROLE_NAME",
    PLACEHOLDER_ROLE_NAME = "PLACEHOLDER_ROLE_NAME",
    TEMPORARY = "TEMPORARY",
    REFRESHING_MODULES = "REFRESHING_MODULES",
    REFRESH = "REFRESH",
    REFRESH_COUNTDOWN = "REFRESH_COUNTDOWN",
    INVALID_PHONE = "INVALID_PHONE",
    CHAT = "CHAT",
    PRICING = "PRICING",
    STOCK = "STOCK",
    SUPPLIERS = "SUPPLIERS",
    SELECT_DEPARTMENT = "SELECT_DEPARTMENT",
    SELECT_SUBDEPARTMENT = "SELECT_SUBDEPARTMENT",
    OPTIONS_DEPARTMENT_SUBDEPARTMENT = "OPTIONS_DEPARTMENT_SUBDEPARTMENT",
    USER_GENERAL_INFO_HELPER = "USER_GENERAL_INFO_HELPER",
    USER_ROLES_HELPER = "USER_ROLES_HELPER",
    PERSONALIZTION_HELPER = "PERSONALIZTION_HELPER",
    ALL_DEPTS_ADDED = "ALL_DEPTS_ADDED",
    ASSIGN_DEPARTMENT = "ASSIGN_DEPARTMENT",
    ASSIGN_SUBDEPARTMENT = "ASSIGN_SUBDEPARTMENT",
    DEPARTMENTS = "DEPARTMENTS",
    SUBDEPARTMENTS = "SUBDEPARTMENTS",
    EDIT_DEPARTMENTS = "EDIT_DEPARTMENTS",
    EDIT_SUBDEPARTMENTS = "EDIT_SUBDEPARTMENTS",
    CALENDAR_PLACEHOLDER = "CALENDAR_PLACEHOLDER",
    TASK_PAGE_PLACEHOLDER = "TASK_PAGE_PLACEHOLDER",
    REASIGN_PLACEHOLDER = "REASIGN_PLACEHOLDER",
    REVIEW_PLACEHOLDER = "REVIEW_PLACEHOLDER",
    FEEDBACK_PLACEHOLDER = "FEEDBACK_PLACEHOLDER",
    HISTORY_PLACEHOLDER = "HISTORY_PLACEHOLDER",
    APPCC_PAGE_PLACEHOLDER = "APPCC_PAGE_PLACEHOLDER",
    INCIDENCE_HISTORY_PLACEHOLDER = "INCIDENCE_HISTORY_PLACEHOLDER",
    TASK_FULFILLMENT_PLACEHOLDER = "TASK_FULFILLMENT_PLACEHOLDER",
    TASK_FULFILLMENT_DET_PLACEHOLDER = "TASK_FULFILLMENT_DET_PLACEHOLDER",
    TASK_DETAIL_PLACEHOLDER = "TASK_DETAIL_PLACEHOLDER",
    TASK_FEEDBACK_PLACEHOLDER = "TASK_FEEDBACK_PLACEHOLDER",
    TASK_ALERT_PLACEHOLDER = "TASK_ALERT_PLACEHOLDER",
    ORDER_BY_STARS = "ORDER_BY_STARS",
    STARS = "STARS",
    STAR = "STAR",
    TASKS_VALORATION = "TASKS_VALORATION",

    REVIEW_QUALITY_1_STAR_NO_REV = "REVIEW_QUALITY_1_STAR_NO_REV",
    REVIEW_QUALITY_3_STAR_NO_REV = "REVIEW_QUALITY_3_STAR_NO_REV",
    REVIEW_QUALITY_HIGH_REVIEW = "REVIEW_QUALITY_HIGH_REVIEW",
    REVIEW_QUALITY_LOW_REVIEW = "REVIEW_QUALITY_LOW_REVIEW",
    FAST_REVIEW = "FAST_REVIEW",
    SLOW_REVIEW = "SLOW_REVIEW",
    NO_REVIEW = "NO_REVIEW",
    FAST_COMMENT = "FAST_COMMENT",
    SLOW_COMMENT = "SLOW_COMMENT",
    NO_COMMENT = "NO_COMMENT",

    // USERS
    USER = "USER",
    NEW_USER = "NEW_USER",
    CANNOT_DELETE_SUPERVISOR_STATE = "CANNOT_DELETE_SUPERVISOR_STATE",
    CANNOT_ADD_SUPERVISOR_STATE = "CANNOT_ADD_SUPERVISOR_STATE",
    ACTIVE_TAB = "ACTIVE_TAB",
    INACTIVE_TAB = "INACTIVE_TAB",
    USER_GRID_INACTIVE_WITH_DATE_TAB = "USER_GRID_INACTIVE_WITH_DATE_TAB",
    NO_ALERTS_RECEIVED = "NO_ALERTS_RECEIVED",
    NO_ALERTS_SENT = "NO_ALERTS_SENT",
    NO_ALERTS_PROGRAMMED = "NO_ALERTS_PROGRAMMED",
    USER_DEACTIVATED = "USER_DEACTIVATED",
    USER_ACTIVATED = "USER_ACTIVATED",

    // DASHBOARD TASKS
    HI_USER = "HI_USER",
    HI = "HI",
    SHOW_ALL = "SHOW_ALL",
    DASHBOARD_SUBTITLE = "DASHBOARD_SUBTITLE",
    DASHBOARD_STAR_RATING_SUPERVIR_TEXT = "DASHBOARD_STAR_RATING_SUPERVIR_TEXT",
    MY_TASKS = "MY_TASKS",
    SHARED_TASKS = "SHARED_TASKS",
    MY_DEPARTMENTS = "MY_DEPARTMENTS",
    SEE_ALL = "SEE_ALL",
    TASKS_DONE_COUNTERS = "TASKS_DONE_COUNTERS",
    EDIT_TASK = "EDIT_TASK",
    DUPLICATE_TASK = "DUPLICATE_TASK",
    DELETE_TASK = "DELETE_TASK",
    EDIT_REGISTER = "EDIT_REGISTER",
    DUPLICATE_REGISTER = "DUPLICATE_REGISTER",
    DELETE_REGISTER = "DELETE_REGISTER",
    NEW_REGISTER = "NEW_REGISTER",
    LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
    SEVEN_DAYS = "SEVEN_DAYS",
    LAST_THIRTY_DAYS = "LAST_THIRTY_DAYS",
    TIME_PAST = "TIME_PAST",
    TASKS_OF = "TASKS_OF",
    DEPARTMENT_TASKS = "DEPARTMENT_TASKS",
    TASKS_OF_DEPARTMENT = "TASKS_OF_DEPARTMENT",
    TASKS_OF_SUBDEPARTMENT = "TASKS_OF_SUBDEPARTMENT",
    SCHEDULED_ALERT = "SCHEDULED_ALERT",
    SUCCESS_ALERT_SENT = "SUCCESS_ALERT_SENT",
    FAILED_ALERT_SENT = "FAILED_ALERT_SENT",
    TASK_DETAIL_REOPEN_TASK_TITLE = "TASK_DETAIL_REOPEN_TASK_TITLE",
    TASK_DETAIL_REOPEN_TASK_DESC = "TASK_DETAIL_REOPEN_TASK_DESC",
    TASK_DETAIL_REOPEN_TASK_BTN_OPEN = "TASK_DETAIL_REOPEN_TASK_BTN_OPEN",
    TASK_DETAIL_REOPEN_TASK_BTN_CLOSE = "TASK_DETAIL_REOPEN_TASK_BTN_CLOSE",
    TASK_DETAIL_REOPEN_TASK = "TASK_DETAIL_REOPEN_TASK",
    TASK_DETAIL_TEMPERATURE_TITLE = "TASK_DETAIL_TEMPERATURE_TITLE",
    TASK_DETAIL_DATA_TO_REPORT_TITLE = "TASK_DETAIL_DATA_TO_REPORT_TITLE",

    TASK_FIELDS_INCOMPLETE = "TASK_FIELDS_INCOMPLETE",
    TASK_IS_BEING_COMPLETED = "TASK_IS_BEING_COMPLETED",
    TASK_COMPLETED_SUCCESSFULLY = "TASK_COMPLETED_SUCCESSFULLY",
    TASK_DETAIL_PHOTO_EXAMPLE = "TASK_DETAIL_PHOTO_EXAMPLE",
    TASK_DETAIL_PHOTO_GALERY = "TASK_DETAIL_PHOTO_GALERY",
    TASK_DETAIL_PHOTO_INSTANT = "TASK_DETAIL_PHOTO_INSTANT",
    TASK_DETAIL_CHECKLIST = "TASK_DETAIL_CHECKLIST",
    TASK_ACTIVE_BETWEEN = "TASK_ACTIVE_BETWEEN",
    TASK_DUE = "TASK_DUE",
    TASK_TIMED_OUT = "TASK_TIMED_OUT",
    TASK_DONE = "TASK_DONE",
    DRAG_FILE_UPLOAD_PHOTO = "DRAG_FILE_UPLOAD_PHOTO",
    UPLOAD_PHOTO = "UPLOAD_PHOTO",
    IF_NOT_USERCODE_USE_DNI = "IF_NOT_USERCODE_USE_DNI",
    NO_VALORATION = "NO_VALORATION",
    NO_VALORATION_SUBTITLE = "NO_VALORATION_SUBTITLE",
    NO_VALORATION_SUBTITLE_TASK_VALORATION = "NO_VALORATION_SUBTITLE_TASK_VALORATION",
    NO_RESULTS_PERCENTAGE_STARS_WORKER_DETAIL = "NO_RESULTS_PERCENTAGE_STARS_WORKER_DETAIL",

    NO_COMMENTS = "NO_COMMENTS",
    NO_COMMENTS_SUBTITLE = "NO_COMMENTS_SUBTITLE",

    // DASHBOARD TASKS PLACEHOLDERS LIST
    DASHBOARD_ALERT_PLACEHOLDER = "DASHBOARD_ALERT_PLACEHOLDER",
    DASHBOARD_FEEDBACK_PLACEHOLDER = "DASHBOARD_FEEDBACK_PLACEHOLDER",

    // TASK PILL
    START_DATE = "START_DATE",
    END_DATE = "END_DATE",

    // TASK INSTANCE SECONDARY STATUS
    PENDING = "PENDING",
    NEAR_END = "NEAR_END",
    TIMED_OUT = "TIMED_OUT",
    IN_TIME = "IN_TIME",
    OUT_OF_TIME = "OUT_OF_TIME",
    NOT_FINISHED = "NOT_FINISHED",
    SHARED_TIMED_OUT = "SHARED_TIMED_OUT",
    SHARED_TIMED_OUT_TASKS = "SHARED_TIMED_OUT_TASKS",

    // TASK INSTANCE SECONDARY FILTER
    REQUEST_DATE = "REQUEST_DATE",
    DELIVERY_DATE = "DELIVERY_DATE",
    WELL_DONE_TASK = "WELL_DONE_TASK",
    WORSE_DONE_TASK = "WORSE_DONE_TASK",
    PIC_ASSOCIATED_TASK = "PIC_ASSOCIATED_TASK",
    REPORTED_DATA_TASK = "REPORTED_DATA_TASK",
    APPLY_FILTER = "APPLY_FILTER",
    TASK_FILTER_USER = "TASK_FILTER_USER",
    TASK_FILTER_DEPARTMENT = "TASK_FILTER_DEPARTMENT",
    TASK_FILTER_SUBDEPARTMENT = "TASK_FILTER_SUBDEPARTMENT",
    TASK_FILTER_WORKING_POSITION = "TASK_FILTER_WORKING_POSITION",
    TASK_FILTER_DATA = "TASK_FILTER_DATA",
    TASK_FILTER_PLAN = "TASK_FILTER_PLAN",
    TASK_FILTER_STATUS = "TASK_FILTER_STATUS",
    TASK_FILTER_EVALUATION = "TASK_FILTER_EVALUATION",
    RESET_FILTERS = "RESET_FILTERS",
    TERMINATE = "TERMINATE",
    TASK_USER_DEP_LIST = "TASK_USER_DEP_LIST",
    COMPLETED_CHECKBOX = "COMPLETED_CHECKBOX",
    CRITICAL_CHECKBOX = "CRITICAL_CHECKBOX",
    CRITICAL_CHECKBOXES = "CRITICAL_CHECKBOXES",
    QR_CHECKBOX = "QR_CHECKBOX",
    TASK_FILTER_LAST_SEVEN_DAYS = "TASK_FILTER_LAST_SEVEN_DAYS",
    MY_TASKS_FILTER = "MY_TASKS_FILTER",
    SHARED_TASKS_FILTER = "SHARED_TASKS_FILTER",

    // TASK MOBILE OPTIONS
    OPEN_TASK_DETAIL = "OPEN_TASK_DETAIL",

    // TASK REVIEW
    PENDING_TASKS_1 = "PENDING_TASKS_1",
    PENDING_TASKS_2 = "PENDING_TASKS_2",
    IS_CRITICAL = "IS_CRITICAL",
    IS_NOT_CRITICAL = "IS_NOT_CRITICAL",
    TOTAL_TASKS = "TOTAL_TASKS",
    FEEDBACK = "FEEDBACK",
    REVIEW_WITH_PHOTO = "REVIEW_WITH_PHOTO",
    REVIEW_WITHOUT_PHOTO = "REVIEW_WITHOUT_PHOTO",
    REVIEW_TIMED_OUT = "REVIEW_TIMED_OUT",
    REVIEW_COMPLETED = "REVIEW_COMPLETED",

    // TASK COMMENTS
    TASK_COMMENTS_INFO_1 = "TASK_COMMENTS_INFO_1",
    TASK_COMMENTS_INFO_2 = "TASK_COMMENTS_INFO_2",
    TASK_COMMENTS_TITLE = "TASK_COMMENTS_TITLE",
    TASK_COMMENTS_PLACEHOLDER = "TASK_COMMENTS_PLACEHOLDER",
    INSERT_MESSAGE_FAILED = "INSERT_MESSAGE_FAILED",
    TASKS_WITH_COMENTS = "TASKS_WITH_COMENTS",
    TASKS_WITH_FEEDBACK = "TASKS_WITH_FEEDBACK",

    // WIZARD ISMAEL
    INVALID_TIME_FORMAT = "INVALID_TIME_FORMAT",
    INVALID_HOURS_SAME_HOURS = "INVALID_HOURS_SAME_HOURS",
    MINIMUM_VALUE = "MINIMUM_VALUE",
    SELECT_ONE_DAY = "SELECT_ONE_DAY",
    TASK_DESCRIPTION = "TASK_DESCRIPTION",
    INPUT_OPTIONAL = "INPUT_OPTIONAL",
    BELONGNS_APPCC_PLAN = "BELONGNS_APPCC_PLAN",
    COMPANY_BELONGS = "COMPANY_BELONGS",
    COMPANY_BELONGS_F = "COMPANY_BELONGS_F",
    ASIGN_EMPLOYEE_DEPARTMENT = "ASIGN_EMPLOYEE_DEPARTMENT",
    EMPLOYEE = "EMPLOYEE",
    DEPARTMENT = "DEPARTMENT",
    SELECT_A_PLAN = "SELECT_A_PLAN",

    SUBDEPARTMENT = "SUBDEPARTMENT",
    DATA_TO_REPORT = "DATA_TO_REPORT",
    DATAS_TO_REPORT = "DATAS_TO_REPORT",
    ADD_DATA_TO_REPORT = "ADD_DATA_TO_REPORT",
    ADD_ITEM_TO_CHECKLIST = "ADD_ITEM_TO_CHECKLIST",
    ADD_OPTION = "ADD_OPTION",
    PHOTOGRAPHIC_REGISTER = "PHOTOGRAPHIC_REGISTER",
    UPLOAD_FILE = "UPLOAD_FILE",
    WIZARD_TEMPORARY_ROLE_SELECT_DATES = "WIZARD_TEMPORARY_ROLE_SELECT_DATES",
    WIZARD_TEMPORARY_ROLE_USER = "WIZARD_TEMPORARY_ROLE_USER",
    WIZARD_TEMPORARY_ROLE_PLACEHOLDER_USER = "WIZARD_TEMPORARY_ROLE_PLACEHOLDER_USER",
    WIZARD_TEMPORARY_ROLE_ACTIVE_PERMISIONS = "WIZARD_TEMPORARY_ROLE_ACTIVE_PERMISIONS",
    WIZARD_TEMPORARY_ROLE_FOR_USER = "WIZARD_TEMPORARY_ROLE_FOR_USER",
    WIZARD_ROLES_FAILED_SAVE = "WIZARD_ROLES_FAILED_SAVE",
    TASK_END_LABEL = "TASK_END_LABEL",

    CRITICAL_TASK = "CRITICAL_TASK",
    EXAMPLE_PHOTO = "EXAMPLE_PHOTO",
    PLACEHOLDER_TASK_TITLE = "PLACEHOLDER_TASK_TITLE",
    PLACEHOLDER_TASK_DESCRIPTION = "PLACEHOLDER_TASK_DESCRIPTION",
    PLACEHOLDER_REPORT_DATA = "PLACEHOLDER_REPORT_DATA",
    PLACEHOLDER_CHECKLIST = "PLACEHOLDER_CHECKLIST",
    PLACEHOLDER_WIZARD_TASK_START = "PLACEHOLDER_WIZARD_TASK_START",
    TASK_START = "TASK_START",
    TASK_START_HOURS = "TASK_START_HOURS",
    TASK_START_HOUR = "TASK_START_HOUR",
    TASK_START_HOUR_MOBILE = "TASK_START_HOUR_MOBILE",
    TASK_ENDS_HOUR_MOBILE = "TASK_ENDS_HOUR_MOBILE",
    TASK_OUT_OF_TIME_HOUR_MOBILE = "TASK_OUT_OF_TIME_HOUR_MOBILE",
    TASK_COMPLETED_HOUR_MOBILE = "TASK_COMPLETED_HOUR_MOBILE",
    TASK_TIMED_OUT_HOUR_MOBILE = "TASK_TIMED_OUT_HOUR_MOBILE",
    TASKINSTANCE_COMPLETED_OUT_OF_TIME_TEXT = "TASKINSTANCE_COMPLETED_OUT_OF_TIME_TEXT",
    TASKINSTANCE_LIMIT_DATE = "TASKINSTANCE_LIMIT_DATE",
    TASKINSTANCE_START_HOUR = "TASKINSTANCE_START_HOUR",
    MUST_SELECT_USER_OR_QR_TITLE = "MUST_SELECT_USER_OR_QR_TITLE",
    MUST_SELECT_USER_OR_QR_TITLE_F = "MUST_SELECT_USER_OR_QR_TITLE_F",
    MUST_SELECT_USER_OR_QR_BODY = "MUST_SELECT_USER_OR_QR_BODY",

    // Placeholders Dynamic Fields
    DYNAMIC_FIELD_PLACEHOLDER_NUMBER = "DYNAMIC_FIELD_PLACEHOLDER_NUMBER",
    DYNAMIC_FIELD_PLACEHOLDER_TEXT = "DYNAMIC_FIELD_PLACEHOLDER_TEXT",
    DYNAMIC_FIELD_PLACEHOLDER_SELECT = "DYNAMIC_FIELD_PLACEHOLDER_SELECT",
    DYNAMIC_FIELD_PLACEHOLDER_OPTION = "DYNAMIC_FIELD_PLACEHOLDER_OPTION",
    DYNAMIC_FIELD_PLACEHOLDER_DATE = "DYNAMIC_FIELD_PLACEHOLDER_DATE",
    DYNAMIC_FIELD_PLACEHOLDER_HOUR = "DYNAMIC_FIELD_PLACEHOLDER_HOUR",
    DYNAMIC_FIELD_PLACEHOLDER_DATE_AND_HOUR = "DYNAMIC_FIELD_PLACEHOLDER_DATE_AND_HOUR",
    DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE = "DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE",
    DYNAMIC_FIELD_PLACEHOLDER_CORRECT_INCORRECT = "DYNAMIC_FIELD_PLACEHOLDER_CORRECT_INCORRECT",
    DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD = "DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD",
    DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_MULTIPLE_FIELD = "DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_MULTIPLE_FIELD",
    DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_MULTIPLE_ASSET_FIELD = "DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_MULTIPLE_ASSET_FIELD",

    TASK_COMPLETED = "TASK_COMPLETED",
    TASK_NOT_COMPLETED = "TASK_NOT_COMPLETED",

    TASK_COMPLETED_OUT_OF_TIME = "TASK_COMPLETED_OUT_OF_TIME",
    TASK_PENDING = "TASK_PENDING",
    TASK_DISABLED = "TASK_DISABLED",
    TASK_NEAR_TO_END = "TASK_NEAR_TO_END",

    RECURRENT_TASK_CHECKBOX = "RECURRENT_TASK_CHECKBOX",
    EVERY_DAY = "EVERY_DAY",
    EVERY_WEEK_DAY = "EVERY_WEEK_DAY",
    EVERY_MONTH_DAY = "EVERY_MONTH_DAY",
    EVERY_YEAR_DAY_MONTH = "EVERY_YEAR_DAY_MONTH",
    EVERY_LABORAL_DAYS = "EVERY_LABORAL_DAYS",
    EVERY_MONTh_DAY = "EVERY_MONTh_DAY",
    // Singular
    DAY = "DAY",
    EVERY_WEEK = "EVERY_WEEK",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
    // Plural
    EVERY_WEEKS = "EVERY_WEEKS",
    WEEKS = "WEEKS",
    MONTHS = "MONTHS",
    YEARS = "YEARS",
    AFTER = "AFTER",

    SPECIFIC_DAY = "SPECIFIC_DAY",
    FIRST_DAY_WEEK = "FIRST_DAY_WEEK",
    SECOND_DAY_WEEK = "SECOND_DAY_WEEK",
    THIRD_DAY_WEEK = "THIRD_DAY_WEEK",
    FOURTH_DAY_WEEK = "FOURTH_DAY_WEEK",
    FIFTH_DAY_WEEK = "FIFTH_DAY_WEEK",
    NEVER = "NEVER",
    REPETITIONS = "REPETITIONS",
    REPETITION = "REPETITION",
    REPEAT_EVERY = "REPEAT_EVERY",
    REPEAT = "REPEAT",
    END = "END",
    DAYS = "DAYS",
    EVERY = "EVERY",

    WIZARD_HEADER_TASK_INFORMATION = "WIZARD_HEADER_TASK_INFORMATION",
    WIZARD_HEADER_TASK_RESPONSIBLE = "WIZARD_HEADER_TASK_RESPONSIBLE",
    WIZARD_HEADER_TASK_RECORD_INFO = "WIZARD_HEADER_TASK_RECORD_INFO",
    WIZARD_HEADER_TASK_DATE = "WIZARD_HEADER_TASK_DATE",
    WIZARD_HEADER_TASK_FINISH = "WIZARD_HEADER_TASK_FINISH",
    WIZARD_HEADER_TASK_REFERENCE = "WIZARD_HEADER_TASK_REFERENCE",
    WIZARD_SELECT_CUSTOM = "WIZARD_SELECT_CUSTOM",
    CREATING_TASKS = "CREATING_TASKS",
    FAILED_GENERATING_TASKS = "FAILED_GENERATING_TASKS",
    TASKS_CREATED = "TASKS_CREATED",

    DATA_TO_REPORT_NO = "DATA_TO_REPORT_NO",
    DATA_TO_REPORT_EXTRA_DATA = "DATA_TO_REPORT_EXTRA_DATA",
    DATA_TO_REPORT_MANUAL = "DATA_TO_REPORT_MANUAL",
    DATA_TO_REPORT_ASSET = "DATA_TO_REPORT_ASSET",
    DATA_TO_REPORT_RANGE_REGISTER = "DATA_TO_REPORT_RANGE_REGISTER",
    DATA_TO_REPORT_DYNAMIC_FIELDS = "DATA_TO_REPORT_DYNAMIC_FIELDS",
    CHECKLIST = "CHECKLIST",
    DATA_TO_REPORT_DYNAMIC_NUMBER = "DATA_TO_REPORT_DYNAMIC_NUMBER",
    DATA_TO_REPORT_DYNAMIC_TEXT = "DATA_TO_REPORT_DYNAMIC_TEXT",
    DATA_TO_REPORT_DYNAMIC_DROPDOWN = "DATA_TO_REPORT_DYNAMIC_DROPDOWN",
    DATA_TO_REPORT_DYNAMIC_DATE = "DATA_TO_REPORT_DYNAMIC_DATE",
    DATA_TO_REPORT_DYNAMIC_TIME = "DATA_TO_REPORT_DYNAMIC_TIME",
    DATA_TO_REPORT_DYNAMIC_DATETIME = "DATA_TO_REPORT_DYNAMIC_DATETIME",
    DATA_TO_REPORT_DYNAMIC_DATERANGE = "DATA_TO_REPORT_DYNAMIC_DATERANGE",
    DATA_TO_REPORT_DYNAMIC_CORRECT_INCORRECT = "DATA_TO_REPORT_DYNAMIC_CORRECT_INCORRECT",
    DATA_TO_REPORT_DYNAMIC_REQUIRED = "DATA_TO_REPORT_DYNAMIC_REQUIRED",
    DATA_TO_REPORT_DYNAMIC_OPTION_TITLE = "DATA_TO_REPORT_DYNAMIC_OPTION_TITLE",
    DATA_TO_REPORT_DYNAMIC_DROPDOWN_MULTIPLE = "DATA_TO_REPORT_DYNAMIC_DROPDOWN_MULTIPLE",
    DATA_TO_REPORT_DYNAMIC_DROPDOWN_MULTIPLE_ASSET = "DATA_TO_REPORT_DYNAMIC_DROPDOWN_MULTIPLE_ASSET",

    WEEK_MONDAY = "WEEK_MONDAY",
    WEEK_TUESDAY = "WEEK_TUESDAY",
    WEEK_WEDNESDAY = "WEEK_WEDNESDAY",
    WEEK_THURSDAY = "WEEK_THURSDAY",
    WEEK_FRIDAY = "WEEK_FRIDAY",
    WEEK_SATURDAY = "WEEK_SATURDAY",
    WEEK_SUNDAY = "WEEK_SUNDAY",

    MONTH_NAME_JANUARY = "MONTH_NAME_JANUARY",
    MONTH_NAME_FEBRAURY = "MONTH_NAME_FEBRAURY",
    MONTH_NAME_MARCH = "MONTH_NAME_MARCH",
    MONTH_NAME_APRIL = "MONTH_NAME_APRIL",
    MONTH_NAME_MAY = "MONTH_NAME_MAY",
    MONTH_NAME_JUNE = "MONTH_NAME_JUNE",
    MONTH_NAME_JULY = "MONTH_NAME_JULY",
    MONTH_NAME_AUGUST = "MONTH_NAME_AUGUST",
    MONTH_NAME_SEPTEMBER = "MONTH_NAME_SEPTEMBER",
    MONTH_NAME_OCTOBER = "MONTH_NAME_OCTOBER",
    MONTH_NAME_NOVEMBER = "MONTH_NAME_NOVEMBER",
    MONTH_NAME_DECEMBER = "MONTH_NAME_DECEMBER",

    MONTH_NAME_JANUARY_SHORT = "MONTH_NAME_JANUARY_SHORT",
    MONTH_NAME_FEBRAURY_SHORT = "MONTH_NAME_FEBRAURY_SHORT",
    MONTH_NAME_MARCH_SHORT = "MONTH_NAME_MARCH_SHORT",
    MONTH_NAME_APRIL_SHORT = "MONTH_NAME_APRIL_SHORT",
    MONTH_NAME_MAY_SHORT = "MONTH_NAME_MAY_SHORT",
    MONTH_NAME_JUNE_SHORT = "MONTH_NAME_JUNE_SHORT",
    MONTH_NAME_JULY_SHORT = "MONTH_NAME_JULY_SHORT",
    MONTH_NAME_AUGUST_SHORT = "MONTH_NAME_AUGUST_SHORT",
    MONTH_NAME_SEPTEMBER_SHORT = "MONTH_NAME_SEPTEMBER_SHORT",
    MONTH_NAME_OCTOBER_SHORT = "MONTH_NAME_OCTOBER_SHORT",
    MONTH_NAME_NOVEMBER_SHORT = "MONTH_NAME_NOVEMBER_SHORT",
    MONTH_NAME_DECEMBER_SHORT = "MONTH_NAME_DECEMBER_SHORT",

    // TASK WIZARD
    COMPANY_RESPONSIBLE_TITLE = "COMPANY_RESPONSIBLE_TITLE",
    DATA_REPORT_TITLE = "DATA_REPORT_TITLE",
    CHECKLIST_TITLE = "CHECKLIST_TITLE",
    DYNAMIC_FIELDS_TITLE = "DYNAMIC_FIELDS_TITLE",
    DATE_START_REPETITIONS_TITLE = "DATE_START_REPETITIONS_TITLE",
    VERIFY_INFORMATION_TITLE = "VERIFY_INFORMATION_TITLE",
    PLAN_APPCC_LABEL = "PLAN_APPCC_LABEL",
    COMPANY_TASK = "COMPANY_TASK",
    TASK_ASIGNED_TO = "TASK_ASIGNED_TO",
    TASK_ASIGNED_TO_USER_IN_DEPARTMENT = "TASK_ASIGNED_TO_USER_IN_DEPARTMENT",
    TASK_ASIGNED_TO_DEPARTMENT = "TASK_ASIGNED_TO_DEPARTMENT",
    TASK_ASIGNED_TO_SUBDEPARTMENT = "TASK_ASIGNED_TO_SUBDEPARTMENT",
    TASK_ASIGNED_TO_WORKING_POSITION = "TASK_ASIGNED_TO_WORKING_POSITION",
    TASK_REASIGNED_TO = "TASK_REASIGNED_TO",
    CANCEL_REASIGNATION = "CANCEL_REASIGNATION",
    IMAGE_FOR_REPORT = "IMAGE_FOR_REPORT",
    IS_A_CRITICAL_TASK = "IS_A_CRITICAL_TASK",
    HOURS_OUT_OF_RANGE_TITLE = "HOURS_OUT_OF_RANGE_TITLE",
    HOURS_OUT_OF_RANGE_DESCRIPTION = "HOURS_OUT_OF_RANGE_DESCRIPTION",

    // TASK WIZARD DAYS FIRST LETTER
    DAY_WEEK_MONDAY_FIRST_LETTER = "DAY_WEEK_MONDAY_FIRST_LETTER",
    DAY_WEEK_TUESDAY_FIRST_LETTER = "DAY_WEEK_TUESDAY_FIRST_LETTER",
    DAY_WEEK_WEDNESDAY_FIRST_LETTER = "DAY_WEEK_WEDNESDAY_FIRST_LETTER",
    DAY_WEEK_THURSDAY_FIRST_LETTER = "DAY_WEEK_THURSDAY_FIRST_LETTER",
    DAY_WEEK_FRIDAY_FIRST_LETTER = "DAY_WEEK_FRIDAY_FIRST_LETTER",
    DAY_WEEK_SATURDAY_FIRST_LETTER = "DAY_WEEK_SATURDAY_FIRST_LETTER",
    DAY_WEEK_SUNDAY_FIRST_LETTER = "DAY_WEEK_SUNDAY_FIRST_LETTER",

    FULL_DATE = "FULL_DATE",
    FINISH_IN = "FINISH_IN",

    TASK_REPEAT_EVERY_X_WEEKS = "TASK_REPEAT_EVERY_X_WEEKS",
    TASK_REPEAT_EVERY_X_WEEK = "TASK_REPEAT_EVERY_X_WEEK",
    TASK_TEMPORALITY = "TASK_TEMPORALITY",
    IN_THE_HOUSR = "IN_THE_HOURS",
    TASK_REPEAT = "TASK_REPEAT",
    TASK_REPEAT_EVERY = "TASK_REPEAT_EVERY",

    // TASK PAGE
    DONT_HAVE_PLAN_ASSIGNED = "DONT_HAVE_PLAN_ASSIGNED",
    DONT_HAVE_USER_ASSIGNED = "DONT_HAVE_USER_ASSIGNED",
    RESPONSABLE = "RESPONSABLE",
    DETAIL = "DETAIL",
    FREQUENCY = "FREQUENCY",
    WITH_RECURRENCE = "WITH_RECURRENCE",
    WITH_NO_RECURRENCE = "WITH_NO_RECURRENCE",
    COMPILANCE = "COMPILANCE",
    DUPLICATE = "DUPLICATE",
    RECURRENT = "RECURRENT",
    ONE_TIME = "ONE_TIME",
    HOURS = "HOURS",
    ACTIONS = "ACTIONS",
    UNIQUE = "UNIQUE",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    ANNUALY = "ANNUALY",
    TASK_PAGE_REGISTRY_TITLE = "TASK_PAGE_REGISTRY_TITLE",
    TASK_PAGE_REGISTRY_SUBTITLE = "TASK_PAGE_REGISTRY_SUBTITLE",
    TASK_PAGE_REGISTRY_SUBTITLE_SINGULAR = "TASK_PAGE_REGISTRY_SUBTITLE_SINGULAR",
    TASK_PAGE_TASK_SUBTITLE = "TASK_PAGE_TASK_SUBTITLE",
    NEW_TASK = "NEW_TASK",
    TASK_NAME_LABEL = "TASK_NAME_LABEL",
    TASK_NAME_PLACEHOLDER = "TASK_NAME_PLACEHOLDER",
    PLANIFICATION_NEW_TASK = "PLANIFICATION_NEW_TASK",

    // EDIT TASK
    TASK_ENDS = "TASK_ENDS",
    DATA_TO_REPORT_EDIT = "DATA_TO_REPORT_EDIT",
    APPCC_LABEL = "APPCC_LABEL",
    CHECKLIST_CANNOT_BE_EMPTY = "CHECKLIST_CANNOT_BE_EMPTY",
    TASK_DATA = "TASK_DATA",
    DATA_TO_REPORT_ATTACHED = "DATA_TO_REPORT_ATTACHED",

    // TASHISTORY TASK
    DEADLINE = "DEADLINE",
    TASK_HISTORY_TITLE = "TASK_HISTORY_TITLE",
    MOB_TASK_HISTORY_TITLE = "MOB_TASK_HISTORY_TITLE",
    TASK_HISTORY_COMPLETED = "TASK_HISTORY_COMPLETED",
    TASK_HISTORY_ASIGNED = "TASK_HISTORY_ASIGNED",
    TASK_HISTORY_FINISHED_BY = "TASK_HISTORY_FINISHED_BY",
    TASK_HISTORY_STATUS = "TASK_HISTORY_STATUS",
    TASK_HISTORY_VALUATION = "TASK_HISTORY_VALUATION",
    TASK_NO_PLAN = "TASK_NO_PLAN",
    TASK_STATUS_PENDING = "TASK_STATUS_PENDING",
    TASK_STATUS_COMPLETED = "TASK_STATUS_COMPLETED",
    TASK_STATUS_OUT_OF_TIME = "TASK_STATUS_OUT_OF_TIME",
    TASK_STATUS_UNKNOWN = "TASK_STATUS_UNKNOWN",
    ALL_TIME_DATES = "ALL_TIME_DATES",

    // TASKREVIEW
    NO_TASKS_PERFOMED = "NO_TASKS_PERFOMED",
    NO_TASKS_TO_PERFOM = "NO_TASKS_TO_PERFOM",
    MARK_ALL_AS_VIEWED = "MARK_ALL_AS_VIEWED",

    // NEW NOTIFICATION
    TO = "TO",
    FROM = "FROM",
    SENT = "SENT",
    SEND = "SEND",
    RECEIVED = "RECEIVED",
    PROGRAMMED = "PROGRAMMED",
    QR_PROGRAMMED = "QR_PROGRAMMED",
    WRITE_HERE = "WRITE_HERE",
    NEW_NOTIFICATION = "NEW_NOTIFICATION",
    SEND_TO = "SEND_TO",
    FILL_THIS_FIELD = "FILL_THIS_FIELD",
    NOTIFICATIONS = "NOTIFICATIONS",
    NOTIFICATIONS_LANGUAGE = "NOTIFICATIONS_LANGUAGE",
    NOTIFICATION_DAYS_DESCRIPTION = "NOTIFICATION_DAYS_DESCRIPTION",
    MARK_AS_VIEWED = "MARK_AS_VIEWED",
    READED = "READED",
    NOT_READED = "NOT_READED",
    REVIEW_LATER = "REVIEW_LATER",
    REVIEWS = "REVIEWS",
    WITH_REVIEWS = "WITH_REVIEWS",
    WITH_COMMENTS = "WITH_COMMENTS",
    WITH_NO_REVIEWS = "WITH_NO_REVIEWS",
    MARK_AS_READED = "MARK_AS_READED",
    SEEN = "SEEN",
    FILES = "FILES",
    FILE = "FILE",
    IMAGE = "IMAGE",
    IMAGES = "IMAGES",
    AUDIO = "AUDIO",
    AUDIOS = "AUDIOS",
    VIDEO = "VIDEO",
    VIDEOS = "VIDEOS",
    ATTACHED_FILES = "ATTACHED_FILES",
    COMMENTS_OR_QUESTIONS = "COMMENTS_OR_QUESTIONS",

    // ALERTS
    NEW_ALERT = "NEW_ALERT",
    SINGLE_ALERT = "SINGLE_ALERT",
    SUCCESS_ALERT_INFO = "SUCCESS_ALERT_INFO",
    SUCCESS_ALERT_INFO_SUPERVISOR = "SUCCESS_ALERT_INFO_SUPERVISOR",
    ALERT_TEXTAREA_PLACEHOLDER = "ALERT_TEXTAREA_PLACEHOLDER",

    // NOTIFICATION
    SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION",
    RECEIVED_NOTIFICATION = "RECEIVED_NOTIFICATION",
    NO_NOTIFICATION = "NO_NOTIFICATION",

    // VALIDATIONS
    NO_TIME_SELECTED = "NO_TIME_SELECTED",
    TIME_EMPTY = "TIME_EMPTY",
    MAX_TIME_EMPTY = "MAX_TIME_EMPTY",
    SAME_TIMES = "SAME_TIMES",
    TIME_SMALL = "TIME_SMALL",
    OVERLAP_TIME = "OVERLAP_TIME",

    // ASSET_PAGE
    ASSETS = "ASSETS",
    ASSETS_RANGE = "ASSETS_RANGE",
    EDIT_ASSET = "EDIT_ASSET",
    EDIT_ASSET_RANGE = "EDIT_ASSET_RANGE",
    TEMPERATURE = "TEMPERATURE",
    DESCRIPTION = "DESCRIPTION",
    ASSET_TYPE = "ASSET_TYPE",
    ASSET_TYPE_COLUMN = "ASSET_TYPE_COLUMN",
    ASSET_PAGE = "ASSET_PAGE",
    NEW_ASSET = "NEW_ASSET",
    NEW_ASSET_RANGE = "NEW_ASSET_RANGE",
    NAME = "NAME",
    ASSET_ID = "ASSET_ID",
    ASSET_MIN = "ASSET_MIN",
    ASSET_MAX = "ASSET_MAX",
    ASSET_LINK_TO_SENSOR = "ASSET_LINK_TO_SENSOR",
    ASSET_NAME_PLACEHOLDER = "ASSET_NAME_PLACEHOLDER",
    ASSET_DESCRIPTION_PLACEHOLDER = "ASSET_DESCRIPTION_PLACEHOLDER",
    ASSET_SELECT_OPTION = "ASSET_SELECT_OPTION",
    RANGE_REGISTER_SELECT_OPTION = "RANGE_REGISTER_SELECT_OPTION",
    ASSET_DELETE_MODAL_TITLE = "ASSET_DELETE_MODAL_TITLE",
    ASSET_DELETE_MODAL_SUBTITLE = "ASSET_DELETE_MODAL_SUBTITLE",
    SUCCESS_ACTIVE_ASSET = "SUCCESS_ACTIVE_ASSET",
    FAILED_ACTIVE_ASSET = "FAILED_ACTIVE_ASSET",
    SUCCESS_DEACTIVE_ASSET = "SUCCESS_DEACTIVE_ASSET",
    FAILED_DEACTIVE_ASSET = "FAILED_DEACTIVE_ASSET",
    ASSET_MODAL_TITLE_ACTIVATE = "ASSET_MODAL_TITLE_ACTIVATE",
    ASSET_MODAL_TITLE_DEACTIVATE = "ASSET_MODAL_TITLE_DEACTIVATE",
    ASSET_MODAL_TITLE_DEACTIVATE_NO_RELATED = "ASSET_MODAL_TITLE_DEACTIVATE_NO_RELATED",
    ASSET_MODAL_DESCRIPTION_ACTIVATE = "ASSET_MODAL_DESCRIPTION_ACTIVATE",
    ASSET_MODAL_DESCRIPTION_DEACTIVATE = "ASSET_MODAL_DESCRIPTION_DEACTIVATE",
    ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED = "ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED",
    ASSET_DYNAMIC_ASSETS_TITLE = "ASSET_DYNAMIC_ASSETS_TITLE",
    ASSET_ADD_DYNAMIC_FIELD_PLACEHOLDER = "ASSET_ADD_DYNAMIC_FIELD_PLACEHOLDER",
    ASSET_UNIT_OF_MEASURE = "ASSET_UNIT_OF_MEASURE",
    ASSET_LABEL = "ASSET_LABEL",
    ASSET_PLACEHOLDER_LABEL = "ASSET_PLACEHOLDER_LABEL",
    ASSET_OPTION_NUMERIC = "ASSET_OPTION_NUMERIC",
    ASSET_OPTION_TEXT = "ASSET_OPTION_TEXT",
    ASSET_OPTION_DROPDOWN = "ASSET_OPTION_DROPDOWN",
    ASSET_FIELD_LABEL = "ASSET_FIELD_LABEL",

    // INPUT PLACEHOLDERS ASSETS
    INPUT_TEXT_PLACEHOLDER_ASSETID_ASSET = "INPUT_TEXT_PLACEHOLDER_ASSETID_ASSET",
    INPUT_TEXT_PLACEHOLDER_MIN_ASSET = "INPUT_TEXT_PLACEHOLDER_MIN_ASSET",
    INPUT_TEXT_PLACEHOLDER_MAX_ASSET = "INPUT_TEXT_PLACEHOLDER_MAX_ASSET",

    // MULTI TASK HOUR
    ADD_HOUR = "ADD_HOUR",
    ADD_RANGE_HOUR = "ADD_RANGE_HOUR",

    // InstanceSelector
    RELOAD_INSTANCES = "RELOAD_INSTANCES",

    // INPUT PLACEHOLDERS MY PROFILE
    INPUT_PLACEHOLDER_EMAIL = "INPUT_PLACEHOLDER_EMAIL",
    INPUT_TEXT_PLACEHOLDER_USERNAME_MY_PROFILE = "INPUT_TEXT_PLACEHOLDER_USERNAME_MY_PROFILE",
    INPUT_TEXT_PLACEHOLDER_EMIAL_MY_PROFILE = "INPUT_TEXT_PLACEHOLDER_EMIAL_MY_PROFILE",
    INPUT_TEXT_PLACEHOLDER_FIRSTNAME_MY_PROFILE = "INPUT_TEXT_PLACEHOLDER_FIRSTNAME_MY_PROFILE",
    INPUT_TEXT_PLACEHOLDER_LASTNAME_MY_PROFILE = "INPUT_TEXT_PLACEHOLDER_LASTNAME_MY_PROFILE",
    INPUT_TEXT_PLACEHOLDER_DOCUMENT_NUMBER_MY_PROFILE = "INPUT_TEXT_PLACEHOLDER_DOCUMENT_NUMBER_MY_PROFILE",

    // TASK DETAIL
    COMPLETE_TASK = "COMPLETE_TASK",
    THREE_STAR_MESSAGE = "THREE_STAR_MESSAGE",
    FEEDBACK_TITLE_RATING_TWO_STARS = "FEEDBACK_TITLE_RATING_TWO_STARS",
    FEEDBACK_BODY_RATING_TWO_STARS = "FEEDBACK_BODY_RATING_TWO_STARS",
    FEEDBACK_TITLE_RATING = "FEEDBACK_TITLE_RATING",
    FEEDBACK_BODY_RATING = "FEEDBACK_BODY_RATING",
    WITH_REVIEW = "WITH_REVIEW",
    WITHOUT_REVIEW = "WITHOUT_REVIEW",
    DATA_OUT_OF_RANGE = "DATA_OUT_OF_RANGE",
    DATA_OUT_OF_RANGE_BODY = "DATA_OUT_OF_RANGE_BODY",
    EMPTY_OPTIONALS = "EMPTY_OPTIONALS",
    EMPTY_OPTIONALS_DESCRIPTION = "EMPTY_OPTIONALS_DESCRIPTION",

    COULD_NOT_CHECK_SUBTASK = "COULD_NOT_CHECK_SUBTASK",
    DELETE_IMAGE_MODAL_TITLE = "DELETE_IMAGE_MODAL_TITLE",
    TASK_COMMENTS_MAX_250_CHARACTERS = "TASK_COMMENTS_MAX_250_CHARACTERS",
    OPEN_ANOTHER_ISSUE = "OPEN_ANOTHER_ISSUE",

    // REVIEW TASK
    REVIEW_TASK_COMPLETED_OUT_OF_TIME = "REVIEW_TASK_COMPLETED_OUT_OF_TIME",
    REVIEW_TASK_COMPLETED_IN_TIME = "REVIEW_TASK_COMPLETED_IN_TIME",
    REVIEW_TASK_OFF_TIME = "REVIEW_TASK_OFF_TIME",
    REVIEW_TASK_UNSEEN = "REVIEW_TASK_UNSEEN",
    MENU_SIDEBAR_REVIEW_TASKS = "MENU_SIDEBAR_REVIEW_TASKS",
    FULFILLMENT = "FULFILLMENT",

    // LABLES FORMS
    LABEL_USERNAME = "LABEL_USERNAME",
    LABEL_FIRSTNAME = "LABEL_FIRSTNAME",
    LABEL_LASTNAME = "LABEL_LAST",
    LABEL_USER_CODE = "LABEL_USER_CODE",
    LABEL_EMAIL = "LABEL_EMAIL",
    LABEL_JOB_DESCRIPTION = "LABEL_JOB_DESCRIPTION",
    PLACEHOLDER_USERNAME = "PLACEHOLDER_USERNAME",
    PLACEHOLDER_FIRSTNAME = "PLACEHOLDER_FIRSTNAME",
    PLACEHOLDER_LASTNAME = "PLACEHOLDER_LAST",
    PLACEHOLDER_USER_CODE = "PLACEHOLDER_USER_CODE",
    PLACEHOLDER_EMAIL = "PLACEHOLDER_EMAIL",
    PLACEHOLDER_JOB_DESCRIPTION = "PLACEHOLDER_JOB_DESCRIPTION",
    LABEL_DEPARTMENT_NAME = "LABEL_DEPARTMENT_NAME",
    LABEL_INACTIVE_SINCE = "LABEL_INACTIVE_SINCE",
    DEPARTMENT_NAME = "DEPARTMENT_NAME",
    PLACEHOLDER_DEPARTMENT_NAME = "PLACEHOLDER_DEPARTMENT_NAME",
    NEW_DEPARTMENT = "NEW_DEPARTMENT",
    LABEL_SUBDEPARTMENT_NAME = "LABEL_SUBDEPARTMENT_NAME",
    LABEL_ROLE = "LABEL_ROLE",
    LABEL_INITIAL_VIEW = "LABEL_INITIAL_VIEW",
    LABEL_ROLE_SELECTION = "LABEL_ROLE_SELECTION",
    LABEL_COMPANY_SELECTION = "LABEL_COMPANY_SELECTION",
    LABEL_COMPANY_SELECTION_F = "LABEL_COMPANY_SELECTION_F",
    LABEL_COMPANY_AUTOMATIC_ACTIVATION = "LABEL_COMPANY_AUTOMATIC_ACTIVATION",
    LABEL_SELECT = "LABEL_SELECT",
    LABEL_PASSWORD_TYPE = "LABEL_PASSWORD_TYPE",
    LABEL_PIN = "LABEL_PIN",
    LABEL_PASSWORD = "LABEL_PASSWORD",
    LABEL_PROFILE_PICTURE = "LABEL_PROFILE_PICTURE",
    LABEL_SUPERVISOR = "LABEL_SUPERVISOR",
    LABEL_NOTIFICATION_SCHEDULE = "LABEL_NOTIFICATION_SCHEDULE",
    LABEL_USER_TYPE = "LABEL_USER_TYPE",
    NOTIFICATION_SCHEDULE = "NOTIFICATION_SCHEDULE",
    USER_TYPE = "USER_TYPE",

    LABEL_MIN_NOTIF_HOUR = "LABEL_MIN_NOTIF_HOUR",
    LABEL_MAX_NOTIF_HOUR = "LABEL_MAX_NOTIF_HOUR",

    //supervisorValorationDetail

    SUPERVISOR_VALORATION_SECTION_TASK_VALORATION = "SUPERVISOR_VALORATION_SECTION_TASK_VALORATION",
    SUPERVISOR_VALORATION_SECTION_COMMENTS = "SUPERVISOR_VALORATION_SECTION_COMMENTS",
    SUPERVISOR_VALORATION_SECTION_YOUR_TASKS = "SUPERVISOR_VALORATION_SECTION_YOUR_TASKS",
    SUPERVISOR_VALORATION_SECTION_TASK_TYPES = "SUPERVISOR_VALORATION_SECTION_TASK_TYPES",
    SUPERVISOR_VALORATION_SECTION_YOUR_TEAMS = "SUPERVISOR_VALORATION_SECTION_YOUR_TEAMS",
    SUPERVISOR_VALORATION_SECTION_TEAM_AVG = "SUPERVISOR_VALORATION_SECTION_TEAM_AVG",

    //worker
    WORKER_VALORATION_SECTION_TASK = "WORKER_VALORATION_SECTION_TASK",
    WORKER_VALORATION_SECTION_TASK_SUBTITLE = "WORKER_VALORATION_SECTION_TASK_SUBTITLE",

    SUPERVISOR_VALORATION_SECTION_TASK_SUBTITLE = "SUPERVISOR_VALORATION_SECTION_TASK_SUBTITLE",
    SUPERVISOR_VALORATION_SECTION_TASK_DATE = "SUPERVISOR_VALORATION_SECTION_TASK_DATE",
    SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_SUBTITLE = "SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_SUBTITLE",
    SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_DATE = "SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_DATE",

    // COMPANY
    LABEL_NAME = "LABEL_NAME",
    LABEL_PHONE = "LABEL_PHONE",
    LABEL_CUSTOMERINSTANCEID = "LABEL_CUSTOMERINSTANCEID",
    LABEL_CITY = "LABEL_CITY",
    LABEL_LEGAL_NAME = "LABEL_LEGAL_NAME",
    LABEL_ADDRESS = "LABEL_ADDRESS",
    LABEL_ZIP_CODE = "LABEL_ZIP_CODE",
    LABEL_LOGO = "LABEL_LOGO",

    PLACEHOLDER_ADDRESS = "PLACEHOLDER_ADDRESS",
    PLACEHOLDER_CITY = "PLACEHOLDER_CITY",
    PLACEHOLDER_ZIP_CODE = "PLACEHOLDER_ZIP_CODE",
    PLACEHOLDER_PHONE = "PLACEHOLDER_PHONE",
    PLACEHOLDER_LEGAL_NAME = "PLACEHOLDER_LEGAL_NAME",

    NEW_GROUP_COMPANY = "NEW_GROUP_COMPANY",
    NEW_COMPANY = "NEW_COMPANY",
    NEW_COMPANY_F = "NEW_COMPANY_F",
    DELETE_COMPANY_TITLE = "DELETE_COMPANY_TITLE",

    //ROLE
    TITLE_PERMISSIONS = "TITLE_PERMISSIONS",
    TITLE_BASE_ROLE = "TITLE_BASE_ROLE",
    TITLE_FROM = "TITLE_FROM",
    TITLE_TO = "TITLE_TO",
    TITLE_ACTIVE = "TITLE_ACTIVE",
    TITLE_INACTIVE = "TITLE_INACTIVE",
    INACTIVE_SINCE = "INACTIVE_SINCE",
    ROLE_DETAIL_MODAL_SUCCESS_ADD_MESSAGE = "ROLE_DETAIL_MODAL_SUCCESS_ADD_MESSAGE",
    USERS_DETAIL_MODAL_SUCCESS_ADD_MESSAGE = "USERS_DETAIL_MODAL_SUCCESS_ADD_MESSAGE",

    ROLE_ADDED_SUCCESSFULLY = "ROLE_ADDED_SUCCESSFULLY",

    EDIT_ROLE_FORM_NAME = "EDIT_ROLE_FORM_NAME",
    EDIT_ROLE_FORM_PLACEHOLDER_NAME = "EDIT_ROLE_FORM_PLACEHOLDER_NAME",
    EDIT_TEMPORARY_ROLE_FORM_NAME = "EDIT_TEMPORARY_ROLE_FORM_NAME",
    EDIT_TEMPORARY_ROLE_FORM_PLACEHOLDER_NAME = "EDIT_TEMPORARY_ROLE_FORM_PLACEHOLDER_NAME",

    // InstanceConfig
    TITLE_DATE = "TITLE_DATE",
    TITLE_TIME = "TITLE_TIME",
    TITLE = "TITLE",
    TITLE_LOGO_SMALL_BASE_64 = "TITLE_LOGO_SMALL_BASE_64",

    //COMPANY WIZARD
    WIZARD_COMPANY_TITLE = "WIZARD_COMPANY_TITLE",
    WIZARD_COMPANY_NAME = "WIZARD_COMPANY_NAME",
    WIZARD_COMPANY_NAME_F = "WIZARD_COMPANY_NAME_F",
    WIZARD_COMPANY_LOGO = "WIZARD_COMPANY_LOGO",
    WIZARD_COMPANY_BELONGS_TO = "WIZARD_COMPANY_BELONGS_TO",
    WIZARD_COMPANY_CIF = "WIZARD_COMPANY_CIF",
    WIZARD_COMPANY_FISCAL_NAME = "WIZARD_COMPANY_FISCAL_NAME",
    WIZARD_COMPANY_WHERE_IS = "WIZARD_COMPANY_WHERE_IS",
    WIZARD_COMPANY_HOW_TO_CONTACT = "WIZARD_COMPANY_HOW_TO_CONTACT",
    WIARD_COMPANY_INVENTORY_FREQ = "WIARD_COMPANY_INVENTORY_FREQ",
    WIZARD_COMPANY_WANT_TO_CLONE = "WIZARD_COMPANY_WANT_TO_CLONE",
    WIZARD_COMPANY_WANT_TO_CLONE_F = "WIZARD_COMPANY_WANT_TO_CLONE_F",
    WIZARD_COMPANY_SELECT_TO_INCLUDE = "WIZARD_COMPANY_SELECT_TO_INCLUDE",
    WIZARD_COMPANY_WICH_OF = "WIZARD_COMPANY_WICH_OF",
    WIZARD_COMPANY_NO_SUBDEPARTMENTS = "WIZARD_COMPANY_NO_SUBDEPARTMENTS",
    WIZARD_COMPANY_WANT_TO_CLONE_TASK = "WIZARD_COMPANY_WANT_TO_CLONE_TASK",
    WIZARD_COMPANY_IMPORT_USER_STRUCT = "WIZARD_COMPANY_IMPORT_USER_STRUCT",
    WIZARD_EDIT_INTERNAL_ORG = "WIZARD_EDIT_INTERNAL_ORG",
    WIZARD_COMPANY_AT_LEAST_ONE = "WIZARD_COMPANY_AT_LEAST_ONE",
    WIZARD_COMPANY_MARK_SUBDEPARTMENTS = "WIZARD_COMPANY_MARK_SUBDEPARTMENTS",
    NO_SUBDEPARTMENT_MESSAGE = "NO_SUBDEPARTMENT_MESSAGE",
    WIZARD_COMPANY_SELECT_COMPANY = "WIZARD_COMPANY_SELECT_COMPANY",
    WIZARD_COMPANY_SELECT_COMPANY_F = "WIZARD_COMPANY_SELECT_COMPANY_F",
    WIZARD_COMPANY_SELECT_ONE_DEPARTMENT = "WIZARD_COMPANY_SELECT_ONE_DEPARTMENT",

    WIZARD_COMPANY_PLACEHOLDER_NAME = "WIZARD_COMPANY_PLACEHOLDER_NAME",
    WIZARD_COMPANY_PLACEHOLDER_NAME_F = "WIZARD_COMPANY_PLACEHOLDER_NAME_F",
    WIZARD_COMPANY_PLACEHOLDER_CIF = "WIZARD_COMPANY_PLACEHOLDER_CIF",
    WIZARD_COMPANY_PLACEHOLDER_FISCAL_NAME = "WIZARD_COMPANY_PLACEHOLDER_FISCAL_NAME",
    WIZARD_COMPANY_PLACEHOLDER_CITY = "WIZARD_COMPANY_PLACEHOLDER_CITY",
    WIZARD_COMPANY_PLACEHOLDER_DIRECTION = "WIZARD_COMPANY_PLACEHOLDER_FISCAL_DIRECTION",
    WIZARD_COMPANY_PLACEHOLDER_EMAIL = "WIZARD_COMPANY_PLACEHOLDER_FISCAL_EMAIL",
    WIZARD_COMPANY_PLACEHOLDER_PHONE = "WIZARD_COMPANY_PLACEHOLDER_FISCAL_PHONE",
    WIZARD_COMPANY_PLACEHOLDER_POSTAL_CODE = "WIZARD_COMPANY_PLACEHOLDER_FISCAL_POSTAL_CODE",
    WIZARD_COMPANY_PLACEHOLDER_LOGO = "WIZARD_COMPANY_PLACEHOLDER_LOGO",

    WIZARD_COMPANY_CHECK_LAST_STEP = "WIZARD_COMPANY_CHECK_LAST_STEP",
    WIZARD_COMPANY_CHECK_LAST_STEP_F = "WIZARD_COMPANY_CHECK_LAST_STEP_F",
    WIZARD_COMPANY_CLONE_LAST_STEP = "WIZARD_COMPANY_CLONE_LAST_STEP",

    // LICENSE WIZARD
    WIZARD_INSTANCE_NAME = "WIZARD_INSTANCE_NAME",
    WIZARD_INSTANCE_PLACEHOLDER = "WIZARD_INSTANCE_PLACEHOLDER",
    WIZARD_INSTANCE_MAIN_CONTACT = "WIZARD_INSTANCE_MAIN_CONTACT",
    WIZARD_INSTANCE_STEP_TWO_TITLE = "WIZARD_INSTANCESTEP_TWO_TITLE",
    WIZARD_INSTANCE_PRIMARY_ADMIN = "WIZARD_INSTANCE_PRIMARY_ADMIN",
    WIZARD_INSTANCE_EMAIL = "WIZARD_INSTANCE_EMAIL",
    WIZARD_INSTANCE_DOCUMENT = "WIZARD_INSTANCE_DOCUMENT",
    WIZARD_INSTANCE_CONFIRMATION = "WIZARD_INSTANCE_CONFIRMATION",

    // ROLE WIZARD
    WIZARD_ROLE_NAME = "WIZARD_ROLE_NAME",
    WIZARD_ROLE_PLACEHOLDER_NAME = "WIZARD_ROLE_PLACEHOLDER_NAME",
    WIZARD_ROLE_COPY_PERMISSIONS = "WIZARD_ROLE_COPY_PERMISSIONS",
    WIZARD_ROLE_SELECT_TO_CLONE = "WIZARD_ROLE_SELECT_TO_CLONE",
    WIZARD_ROLE_SELECT_ROLE = "WIZARD_ROLE_SELECT_ROLE",
    WIZARD_ROLE_SELECT_PERSMISION = "WIZARD_ROLE_SELECT_PERSMISION",
    WIZARD_ROLE_CONFIRM_CREATION = "WIZARD_ROLE_CONFIRM_CREATION",
    WIZARD_TEMPORARY_ROLE_USER_SELECT = "WIZARD_TEMPORARY_ROLE_USER_SELECT",
    WIZARD_TEMPORARY_ROLE_DATE_PICKER = "WIZARD_TEMPORARY_ROLE_DATE_PICKER",
    WIZARD_TEMPORARY_ROLE_CONFIRM = "WIZARD_TEMPORARY_ROLE_CONFIRM",
    WIZARD_ROLE_ADD_PATENT = "WIZARD_ROLE_ADD_PATENT",
    ROLES = "ROLES",

    // DEPARTMENT WIZARD
    SELECT_DEPARTMENT_TITLE = "SELECT_DEPARTMENT_TITLE",
    SELECT_SUBDEPARTMENT_TITLE = "SELECT_SUBDEPARTMENT_TITLE",
    CHOOSE_DEPARTMENT = "CHOOSE_DEPARTMENT",
    CHOOSE_SUBDEPARTMENT = "CHOOSE_SUBDEPARTMENT",

    // TASK HISTORY
    NO_TASK_TO_SHOW = "NO_TASK_TO_SHOW",

    // TASKINSTANCE STATUS
    PENDING_OUT_OF_TIME_TASKI = "PENDING_OUT_OF_TIME",
    PENDING_TO_EXPIRE_TASKI = "PENDING_TO_EXPIRE",
    DONE_TASKI = "DONE",

    // EMPTY LIST TASKINSTANCES
    EMPTY_LIST_TASKI = "EMPTY_LIST_TASKI",
    EMPTY_LIST_TASK_REVIEW = "EMPTY_LIST_TASK_REVIEW",

    // Valoration POPUP
    LOW_EVALUATION_REASON = "LOW_EVALUATION_REASON",
    ADD_EVALUATION_COMMENT = "ADD_EVALUATION_COMMENT",
    TASK_POORLY_DONE = "TASK_POORLY_DONE",
    TASK_DONE_DONE = "TASK_DONE_DONE",
    MESSAGE_IS_REQUIRED = "MESSAGE_IS_REQUIRED",
    VALORATE_WITH_NO_MESSAGE = "VALORATE_WITH_NO_MESSAGE",
    SEND_MESSAGE = "SEND_MESSAGE",
    VALORATE_TASK = "VALORATE_TASK",
    VALORATION_COMMENTS = "VALORATION_COMMENTS",
    VALORATION_TEXTAREA_PLACEHOLDER = "VALORATION_TEXTAREA_PLACEHOLDER",

    // MY PROFILE PAGE
    MY_DATA = "MY_DATA",
    MY_ALERTS = "MY_ALERTS",
    MY_CONFIGURATION = "MY_CONFIGURATION",
    ALERT_USER_SELECT_PLACEHOLDER = "ALERT_USER_SELECT_PLACEHOLDER",
    ALERT_DEP_SELECT_PLACEHOLDER = "ALERT_DEP_SELECT_PLACEHOLDER",

    // MY CONFIGURATION
    DO_NOT_DISTURB = "DO_NOT_DISTURB",
    DO_NOT_DISTURB_MODE = "DO_NOT_DISTURB_MODE",
    DO_NOT_DISTURB_SCHEDULED = "DO_NOT_DISTURB_SCHEDULED",
    DO_NOT_DISTURB_SCHEDULED_DESCRIPTION = "DO_NOT_DISTURB_SCHEDULED_DESCRIPTION",
    DO_NOT_DISTURB_DESCRIPTION = "DO_NOT_DISTURB_DESCRIPTION",
    SELECT_YOUR_LANGUAGE = "SELECT_YOUR_LANGUAGE",

    // REVIEW FULFILLMENT
    WORKERS = "WORKERS",
    TEAMS = "TEAMS",

    // REVIEW WORKER PROFILE
    NOT_REVIEWED = "NOT_REVIEWED",
    EVALUATED = "EVALUATED",

    // UserReview
    BETTER_THAN_LAST_MONTH = "BETTER_THAN_LAST_MONTH",
    WORSE_THAN_LAST_MONTH = "WORSE_THAN_LAST_MONTH",
    SAME_THAN_LAST_MONTH = "SAME_THAN_LAST_MONTH",

    // AUTH

    BACK_TO_LOGIN = "BACK_TO_LOGIN",
    PASSWORD = "PASSWORD",
    NEW_PASSWORD = "NEW_PASSWORD",
    RECOVER_PASSWORD = "RECOVER_PASSWORD",
    PASSWORD_CONFIRM = "PASSWORD_CONFIRM",
    CHECK_EMAIL = "CHECK_EMAIL",
    USERNAME = "USERNAME",
    EMAIL = "EMAIL",
    PLEASE_INPUT = "PLEASE_INPUT",
    MODULES = "MODULES",
    GO_TO_MODULE = "GO_TO_MODULE",
    SECURITY = "SECURITY",
    TASKS = "TASKS",
    ACTION_NO_REVERSIBLE = "ACTION_NO_REVERSIBLE",
    LOGIN = "LOGIN",
    LOGIN_TITLE = "LOGIN_TITLE",
    LOGIN_CHANGE_PROFILE = "LOGIN_CHANGE_PROFILE",
    EMPTY_LOGIN_USERNAME = "EMPTY_LOGIN_USERNAME",
    EMPTY_LOGIN_PASSWORD = "EMPTY_LOGIN_PASSWORD",

    // TABLE
    TABLE_NO_DATA = "TABLE_NO_DATA",
    TABLE_EXPORT_TO_CSV = "TABLE_EXPORT_TO_CSV",

    // PAGINATION
    PAGINATION_DE = "PAGINATION_DE",

    // SIGNALR
    RECONNECTING = "RECONNECTING",

    // TABLE EMPTY STATE
    TABLE_EMPTY_STATE_TASK = "TABLE_EMPTY_STATE_TASK",

    // TABLE COLS
    TABLE_USERCODE = "TABLE_USERCODE",
    TABLE_AREA = "TABLE_AREA",

    // SECONDARY FILTER
    SECONDARY_FILTER_CLEAR_FILTERS = "SECONDARY_FILTER_CLEAR_FILTERS",

    // SECONDARY FILTER STATUS SELECTOR
    PENDING_STATUS = "PENDING_STATUS",
    NEAR_END_STATUS = "NEAR_END_STATUS",
    TIMED_OUT_STATUS = "TIMED_OUT_STATUS",
    IN_TIME_STATUS = "IN_TIME_STATUS",
    OUT_OF_TIME_STATUS = "OUT_OF_TIME_STATUS",
    STATUS_CODE = "STATUS_CODE",
    CANNOT_FETCH = "CANNOT_FETCH",

    // REASIGN TASKS
    REASIGN_TASK_TITLE = "REASIGN_TASK_TITLE",
    REASIGN_TASK = "REASIGN_TASK",
    REASIGN_TASK_STATUS_PERMANENT = "REASIGN_TASK_STATUS_PERMANENT",
    REASIGN_TASK_STATUS_TEMPORAL = "REASIGN_TASK_STATUS_TEMPORAL",
    REASIGN_TASK_TO = "REASIGN_TASK_TO",
    REASIGN_TASK_CHANGE_Will_BE = "REASIGN_TASK_CHANGE_Will_BE",
    REASIGN_TASK_START_DATE_PLACEHOLDER = "REASIGN_TASK_START_DATE_PLACEHOLDER",
    REASIGN_TASK_START_DATE_LABEL = "REASIGN_TASK_START_DATE_LABEL",
    REASIGN_TASK_END_DATE_PLACEHOLDER = "REASIGN_TASK_END_DATE_PLACEHOLDER",
    REASIGN_TASK_END_DATE_LABEL = "REASIGN_TASK_END_DATE_LABEL",
    REASIGN_TASK_SELECT_USER = "REASIGN_TASK_SELECT_USER",
    REASIGN_TASK_SELECT_DEPARTMENT = "REASIGN_TASK_SELECT_DEPARTMENT",
    REASIGN_TASK_SELECT_SUBDEPARTMENT = "REASIGN_TASK_SELECT_SUBDEPARTMENT",

    FEEDBACK_MODAL_ORDER_RECENT = "FEEDBACK_MODAL_ORDER_RECENT",
    FEEDBACK_MODAL_ORDER_LOWER = "FEEDBACK_MODAL_ORDER_LOWER",
    FEEDBACK_MODAL_ORDER_HIGHER = "FEEDBACK_MODAL_ORDER_HIGHER",

    // EDIT COMPANY FORM
    EDIT_FORM_COMPANY_NAME = "EDIT_FORM_COMPANY_NAME",
    EDIT_FORM_COMPANY_PLACEHOLDER_NAME = "EDIT_FORM_COMPANY_PLACEHOLDER_NAME",
    EDIT_FORM_COMPANY_ADDRESS = "EDIT_FORM_COMPANY_ADDRESS",
    EDIT_FORM_COMPANY_PLACEHOLDER_ADDRESS = "EDIT_FORM_COMPANY_PLACEHOLDER_ADDRESS",
    EDIT_FORM_COMPANY_CITY = "EDIT_FORM_COMPANY_CITY",
    EDIT_FORM_COMPANY_PLACEHOLDER_CITY = "EDIT_FORM_COMPANY_PLACEHOLDER_CITY",
    EDIT_FORM_COMPANY_NIF = "EDIT_FORM_COMPANY_NIF",
    EDIT_FORM_COMPANY_MAIL = "EDIT_FORM_COMPANY_MAIL",
    EDIT_FORM_COMPANY_POSTAL_CODE = "EDIT_FORM_COMPANY_POSTAL_CODE",
    EDIT_FORM_COMPANY_PLACEHOLDER_POSTAL_CODE = "EDIT_FORM_COMPANY_PLACEHOLDER_POSTAL_CODE",
    EDIT_FORM_COMPANY_GROUP = "EDIT_FORM_COMPANY_GROUP",
    EDIT_COMPANY_TAB_INFO = "EDIT_COMPANY_TAB_INFO",
    EDIT_COMPANY_TAB_INTERNAL_ORG = "EDIT_COMPANY_TAB_INTERNAL_ORG",
    UNDO_CHANGES = "UNDO_CHANGES",
    LOADING_MESSAGE = "LOADING_MESSAGE",
    EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY = "EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY",
    EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_F = "EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_F",
    EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED = "EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED",
    EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED_F = "EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED_F",
    COMPANY_OPEN = "COMPANY_OPEN",
    COMPANY_OPEN_F = "COMPANY_OPEN_F",
    COMPANY_CLOSED = "COMPANY_CLOSED",
    COMPANY_CLOSED_F = "COMPANY_CLOSED_F",
    WORKS_WITH_QR = "WORKS_WITH_QR",

    // EDIT USER FROM
    EDIT_FORM_USER_DEACTIVATE = "EDIT_FROM_USER_DEACTIVATE",
    EDIT_FORM_USER_ACTIVATE = "EDIT_FROM_USER_ACTIVATE",
    EDIT_FORM_USER_WILL_DEACTIVATE = "EDIT_FORM_USER_WILL_DEACTIVATE",
    EDIT_FORM_USER_WILL_DEACTIVATE_NO_RELATED_TASK = "EDIT_FORM_USER_WILL_DEACTIVATE_NO_RELATED_TASK",
    EDIT_FORM_USER_DEACTIVATE_QUESTION = "EDIT_FORM_USER_DEACTIVATE_QUESTION",
    EDIT_FORM_USER_WILL_ACTIVATE = "EDIT_FORM_USER_WILL_ACTIVATE",
    EDIT_FORM_USER_ACTIVATE_QUESTION = "EDIT_FORM_USER_ACTIVATE_QUESTION",
    EDIT_COMPANY_GROUP_TITLE = "EDIT_COMPANY_GROUP_TITLE",

    // PENDING ALERTS
    THERE_ARE_NO_MORE_ALERTS = "THERE_ARE_NO_MORE_ALERTS",

    //SIDEBAR ITEMS
    MENU_SIDEBAR_NEW_TASK_WIZARD_TITLE = "MENU_SIDEBAR_NEW_TASK_WIZARD_TITLE",
    MENU_SIDEBAR_ALERTS = "MENU_SIDEBAR_ALERTS",
    MENU_SIDEBAR_NEW_REGISTRY = "MENU_SIDEBAR_NEW_REGISTRY",

    // CREATE TASK
    CANCEL_REASIGNATION_TITLE = "CANCEL_REASIGNATION_TITLE",

    // LICENSE WIZARD
    WIZARD_LICENSE_TITLE = "WIZARD_LICENSE_TITLE",
    WIZARD_LICENSE_TITLE_DATA = "WIZARD_LICENSE_TITLE_DATA",
    WIZARD_LICENSE_TITLE_MODULES = "WIZARD_LICENSE_TITLE_MODULES",
    WIZARD_LICENSE_TITLE_ADMIN = "WIZARD_LICENSE_TITLE_ADMIN",
    WIZARD_LICENSE_CONTACT_NAME = "WIZARD_LICENSE_CONTACT_NAME",
    WIZARD_LICENSE_PLACEHOLDER_CONTACT_NAME = "WIZARD_LICENSE_PLACEHOLDER_CONTACT_NAME",
    WIZARD_LICENSE_CONTACT_EMAIL = "WIZARD_LICENSE_CONTACT_EMAIL",
    WIZARD_LICENSE_PLACEHOLDER_CONTACT_EMAIL = "WIZARD_LICENSE_PLACEHOLDER_CONTACT_EMAIL",
    WIZARD_LICENSE_CONTACT_PHONE = "WIZARD_LICENSE_CONTACT_PHONE",
    WIZARD_LICENSE_PLACEHOLDER_CONTACT_PHONE = "WIZARD_LICENSE_PLACEHOLDER_CONTACT_PHONE",
    WIZARD_LICENSE_INITIALS = "WIZARD_LICENSE_INITIALS",
    WIZARD_LICENSE_PLACEHOLDER_INITIALS = "WIZARD_LICENSE_PLACEHOLDER_INITIALS",
    WIZARD_LICENSE_ADMIN_CONTACT_NAME = "WIZARD_LICENSE_ADMIN_CONTACT_NAME",
    WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_NAME = "WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_NAME",
    WIZARD_LICENSE_ADMIN_CONTACT_LASTNAME = "WIZARD_LICENSE_ADMIN_CONTACT_LASTNAME",
    WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_LASTNAME = "WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_LASTNAME",
    WIZARD_LICENSE_ADMIN_CONTACT_USERNAME = "WIZARD_LICENSE_ADMIN_CONTACT_USERNAME",
    WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_USERNAME = "WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_USERNAME",
    WIZARD_LICENSE_ADMIN_CONTACT_EMAIL = "WIZARD_LICENSE_ADMIN_CONTACT_EMAIL",
    WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_EMAIL = "WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_EMAIL",
    WIZARD_LICENSE_ADMIN_CONTACT_DOCUMENT_NUMBER = "WIZARD_LICENSE_ADMIN_CONTACT_DOCUMENT_NUMBER",
    WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_DOCUMENT_NUMBER = "WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_DOCUMENT_NUMBER",
    WIZARD_LICENSE_PHONE_NUMBER = "WIZARD_LICENSE_PHONE_NUMBER",
    WIZARD_LICENSE_NAME_AND_LASTNAME = "WIZARD_LICENSE_NAME_AND_LASTNAME",
    WIZARD_LICENSE_EMIAL = "WIZARD_LICENSE_EMIAL",
    WIZARD_LICENSE_DOCUMENT_NUMBER = "WIZARD_LICENSE_DOCUMENT_NUMBER",

    // GROUP COMPANY
    GROUP_COMPANY_DELETE_TITLE = "GROUP_COMPANY_DELETE_TITLE",
    GROUP_COMPANY_FORM_NAME_LABEL = "GROUP_COMPANY_FORM_NAME_LABEL",
    GROUP_COMPANY_FORM_NAME_PLACEHOLDER = "GROUP_COMPANY_FORM_NAME_PLACEHOLDER",

    // WIZARD SELECT DEPARTMENT
    SELCT_DEPARTMENT_COMPANY_TITLE = "SELCT_DEPARTMENT_COMPANY_TITLE",
    SELCT_DEPARTMENT_COMPANY_TITLE_SUPERVISOR = "SELCT_DEPARTMENT_COMPANY_TITLE_SUPERVISOR",

    SELCT_DEPARTMENT_DEPARTMENT_TITLE = "SELCT_DEPARTMENT_DEPARTMENT_TITLE",
    SELCT_DEPARTMENTS_DEPARTMENT_TITLE = "SELCT_DEPARTMENTS_DEPARTMENT_TITLE",
    SELCT_DEPARTMENT_SUBDEPARTMENT_TITLE = "SELCT_DEPARTMENT_SUBDEPARTMENT_TITLE",
    SELCT_DEPARTMENT_SUBDEPARTMENTS_TITLE = "SELCT_DEPARTMENT_SUBDEPARTMENTS_TITLE",

    // INSTABCE PAGE FORM
    INSTANCE_FORM_LABEL_NAME = "INSTANCE_FORM_LABEL_NAME",
    INSTANCE_FORM_PLACEHOLDER_NAME = "INSTANCE_FORM_PLACEHOLDER_NAME",
    INSTANCE_FORM_LABEL_ADMIN_NAME = "INSTANCE_FORM_LABEL_ADMIN_NAME",
    INSTANCE_FORM_PLACEHOLDER_ADMIN_NAME = "INSTANCE_FORM_PLACEHOLDER_ADMIN_NAME",
    INSTANCE_FORM_LABEL_ADMIN_EMAIL = "INSTANCE_FORM_LABEL_ADMIN_EMAIL",
    INSTANCE_FORM_PLACEHOLDER_ADMIN_EMAIL = "INSTANCE_FORM_PLACEHOLDER_ADMIN_EMAIL",
    INSTANCE_FORM_LABEL_ADMIN_PHONE = "INSTANCE_FORM_LABEL_ADMIN_PHONE",
    INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE = "INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE",

    // RANKING
    MENU_SIDEBAR_USER_RANKING = "MENU_SIDEBAR_USER_RANKING",
    RANKING_TITLE_USER = "RANKING_TITLE_USER",
    RANKING_ASC_NAME = "RANKING_ASC_NAME",
    RANKING_DESC_NAME = "RANKING_DESC_NAME",
    RANKING_ASC_STARS = "RANKING_ASC_STARS",
    RANKING_DESC_STARS = "RANKING_DESC_STARS",
    RANKING_COMPLETED_OUT_OF_TIME = "RANKING_COMPLETED_OUT_OF_TIME",
    RANKING_NOT_COMPLETED = "RANKING_NOT_COMPLETED",

    // WORKING POSITION
    CODE = "CODE",
    CODES = "CODES",
    WORKING_POSITION_EDIT_TITLE = "WORKING_POSITION_EDIT_TITLE",
    WORKING_POSITION_NEW_TITLE = "WORKING_POSITION_NEW_TITLE",
    WORKING_POSITION_DISABLE_TITLE = "WORKING_POSITION_DISABLE_TITLE",
    WORKING_POSITION_DISABLE_DESCRIPTION = "WORKING_POSITION_DISABLE_DESCRIPTION",
    WORKING_POSITION_DISABLE_DESCRIPTION_NO_RELATED = "WORKING_POSITION_DISABLE_DESCRIPTION_NO_RELATED",
    WORKING_POSITION_ENABLE_TITLE = "WORKING_POSITION_ENABLE_TITLE",
    WORKING_POSITION_ENABLE_DESCRIPTION = "WORKING_POSITION_ENABLE_DESCRIPTION",
    WORKING_POSITION_ENABLE = "WORKING_POSITION_ENABLE",
    WORKING_POSITION_DISABLE = "WORKING_POSITION_DISABLE",
    WORKING_POSITION_ACTIVE = "WORKING_POSITION_ACTIVE",
    WORKING_POSITION_DEACTIVE = "WORKING_POSITION_DEACTIVE",
    WORKING_POSITION_NAME_LABEL = "WORKING_POSITION_NAME_LABEL",
    WORKING_POSITION_NAME_PLACEHOLDER = "WORKING_POSITION_NAME_PLACEHOLDER",
    WORKING_POSITION_CODE_LABEL = "WORKING_POSITION_CODE_LABEL",
    WORKING_POSITION_CODE_PLACEHOLDER = "WORKING_POSITION_CODE_PLACEHOLDER",
    WORKING_POSITION_DESCRIPTION_LABEL = "WORKING_POSITION_DESCRIPTION_LABEL",
    WORKING_POSITION_DESCRIPTION_PLACEHOLDER = "WORKING_POSITION_DESCRIPTION_PLACEHOLDER",
    WORKING_POSITION_FAILED_ACTIVATE = "WORKING_POSITION_FAILED_ACTIVATE",
    WORKING_POSITION_FAILED_DEACTIVATE = "WORKING_POSITION_FAILED_DEACTIVATE",
    WORKING_POSITION_SUCCESS_ACTIVATE = "WORKING_POSITION_SUCCESS_ACTIVATE",
    WORKING_POSITION_SUCCESS_DEACTIVATE = "WORKING_POSITION_SUCCESS_DEACTIVATE",
    WORKING_POSITION_ALL = "WORKING_POSITION_ALL",
    PRINT_QR = "PRINT_QR",

    // User Scan QR
    QR_CHECKER_TITLE = "QR_CHECKER_TITLE",

    // ANALIST
    SEE_RANKING_USERS = " SEE_RANKING_USERS",
    RANKING_DEPARTMENTS = " RANKING_DEPARTMENTS",

    SEE_GLOBAL_RANKING_USERS = " SEE_GLOBAL_RANKING_USERS",
    SEE_RANKING_DEPARTMENTS = " SEE_RANKING_DEPARTMENTS",
    SEE_GLOBAL_RANKING_DEPARTMENTS = " SEE_GLOBAL_RANKING_DEPARTMENTS",
    GLOBAL_RANKING_DEPARTMENTS = "GLOBAL_RANKING_DEPARTMENTS",
    GLOBAL_RANKING_USERS = "GLOBAL_RANKING_USERS",
    GLOBAL_RANKING_COMPANIES = "GLOBAL_RANKING_COMPANIES",

    ANALIST_TITLE_DONUT = "ANALIST_TITLE_DONUT",
    ANALIST_TITLE_DONUT_DEPARTMENTLIST = "ANALIST_TITLE_DONUT_DEPARTMENTLIST",
    ANALIST_TITLE_DONUT_USERLIST = "ANALIST_TITLE_DONUT_USERLIST",
    ANALIST_TITLE_DONUT_TASKLIST = "ANALIST_TITLE_DONUT_TASKLIST",
    ANALIST_TITLE_STARS = "ANALIST_TITLE_STARS",
    ANALIST_TITLE_LINECHART = "ANALIST_TITLE_LINECHART",
    ANALIST_TITLE_TASKLIST = "ANALIST_TITLE_TASKLIST",

    ANALIST_SHARED_TIMED_OUT = "ANALIST_SHARED_TIMED_OUT",
    ANALIST_TIMED_OUT = "ANALIST_TIMED_OUT",
    ANALIST_OUT_OF_TIME = "ANALIST_OUT_OF_TIME",
    ANALIST_IN_TIME = "ANALIST_IN_TIME",
    ANALIST_DISABLED_USER_TITLE = "ANALIST_DISABLED_USER_TITLE",
    ANALIST_DISABLED_USER_BODY = "ANALIST_DISABLED_USER_BODY",

    ANALIST_TASKLIST_COMMENTS_TAB = "ANALIST_TASKLIST_COMMENTS_TAB",
    ANALIST_TASKLIST_FEEDBACK_TAB = "ANALIST_TASKLIST_FEEDBACK_TAB",
    // ANALIST END

    // Menu sidebar
    MENU_SIDEBAR_TASK_MANAGER = "MENU_SIDEBAR_TASK_MANAGER",
    MENU_SIDEBAR_TASK_REVIEW = "MENU_SIDEBAR_TASK_REVIEW",
    MENU_SIDEBAR_REPORTS = "MENU_SIDEBAR_REPORTS",
    MENU_SIDEBAR_TASK_VALORATION = "MENU_SIDEBAR_TASK_VALORATION",

    // Edit company
    MENU_SIDEBAR_COMUNICATION = "MENU_SIDEBAR_COMUNICATION",
    MENU_SIDEBAR_CONTROL_PANEL = "MENU_SIDEBAR_CONTROL_PANEL",
    MENU_SIDEBAR_APPCC_PLAN = "MENU_SIDEBAR_APPCC_PLAN",
    MENU_SIDEBAR_AUDIT = "MENU_SIDEBAR_AUDIT",
    EDIT_COMPANY_DELETE_DEPARTMENT = "EDIT_COMPANY_DELETE_DEPARTMENT",
    EDIT_COMPANY_DELETE_DEPARTMENT_NO_RELATED = "EDIT_COMPANY_DELETE_DEPARTMENT_NO_RELATED",
    EDIT_COMPANY_DELETE_SUBDEPARTMENT = "EDIT_COMPANY_DELETE_SUBDEPARTMENT",
    EDIT_COMPANY_DELETE_SUBDEPARTMENT_NO_RELATED = "EDIT_COMPANY_DELETE_SUBDEPARTMENT_NO_RELATED",

    // Select Profile
    SELECT_PROFILE_TITLE = "SELECT_PROFILE_TITLE",
    SELECT_PROFILE_ADD_NEW_PROFILE = "SELECT_PROFILE_ADD_NEW_PROFILE",

    // Issue
    ISSUE_COUNT = "ISSUE_COUNT",
    ISSUE_COUNT_TOTAL = "ISSUE_COUNT_TOTAL",
    MOBILE_ISSUE_COUNT = "MOBILE_ISSUE_COUNT",
    MOBILE_ISSUE_COUNT_TOTAL = "MOBILE_ISSUE_COUNT_TOTAL",
    OPEN_ISSUE = "OPEN_ISSUE",
    ISSUE_CREATED_SUCCESSFULLY = "ISSUE_CREATED_SUCCESSFULLY",
    PROBLEM_DETECTED_LABEL = "PROBLEM_DETECTED_LABEL",
    PROBLEM_DETECTED_PLACEHOLDER = "PROBLEM_DETECTED_PLACEHOLDER",
    ISSUE_PROBLEM_DETECTED_LABEL = "ISSUE_PROBLEM_DETECTED_LABEL",
    ATTACH_FILES_LABEL = "ATTACH_FILES_LABEL",
    CORRECTIVE_MEASSURE_LABEL = "CORRECTIVE_MEASSURE_LABEL",
    CORRECTIVE_MEASSURE_PLACEHOLDER = "CORRECTIVE_MEASSURE_PLACEHOLDER",
    ISSUE_ASSET_LABEL = "ISSUE_ASSET_LABEL",
    SUPERVISOR_ISSUE_LABEL = "SUPERVISOR_ISSUE_LABEL",
    SUPERVISOR_ISSUE_PLACEHOLDER = "SUPERVISOR_ISSUE_PLACEHOLDER",
    SEND_MANUAL_ISSUE = "SEND_MANUAL_ISSUE",
    SEND_AND_COMPLETE_MANUAL_ISSUE = "SEND_AND_COMPLETE_MANUAL_ISSUE",
    CREATE_MANUAL_ISSUE_TITLE = "CREATE_MANUAL_ISSUE_TITLE",
    ISSUE_ASSET_STEP_TITLE = "ISSUE_ASSET_STEP_TITLE",
    ISSUE_ASSET_STEP_TITLE_2 = "ISSUE_ASSET_STEP_TITLE_2",
    ISSUE_ASSET_STEP_TITLE_3 = "ISSUE_ASSET_STEP_TITLE_3",
    ISSUE_ASSET_STEP_TITLE_4 = "ISSUE_ASSET_STEP_TITLE_4",
    ISSUE_ASSET_STEP_SUBTITLE_SINGULAR = "ISSUE_ASSET_STEP_SUBTITLE_SINGULAR",
    ISSUE_ASSET_STEP_SUBTITLE_PLURAL = "ISSUE_ASSET_STEP_SUBTITLE_PLURAL",
    ISSUE_ASSET_STEP_SUBTITLE = "ISSUE_ASSET_STEP_SUBTITLE",
    ISSUE_ASSET_LAST_STEP_TITLE = "ISSUE_ASSET_LAST_STEP_TITLE",
    ISSUE_TYPE_TASK = "ISSUE_TYPE_TASK",
    ISSUE_TYPE_ASSET = "ISSUE_TYPE_ASSET",
    ISSUE_TYPE_GENERIC = "ISSUE_TYPE_GENERIC",
    ISSUE_TYPE_AUDIT = "ISSUE_TYPE_AUDIT",
    ISSUE_TYPE_TASK_TAB = "ISSUE_TYPE_TASK_TAB",
    ISSUE_TYPE_ASSET_TAB = "ISSUE_TYPE_ASSET_TAB",
    ISSUE_TYPE_GENERIC_TAB = "ISSUE_TYPE_GENERIC_TAB",
    ISSUE_TYPE_AUDIT_TAB = "ISSUE_TYPE_AUDIT_TAB",

    ISSUE_ID = "ISSUE_ID",
    ISSUE_DESCRIPTION = "ISSUE_DESCRIPTION",
    ISSUE_OPEN_DATE = "ISSUE_OPEN_DATE",
    ISSUE_OPEN_BY = "ISSUE_OPEN_BY",
    ISSUE_ACTUAL_STATUS = "ISSUE_ACTUAL_STATUS",
    ISSUE_STATUS_OPEN = "ISSUE_STATUS_OPEN",
    ISSUE_STATUS_CLOSE = "ISSUE_STATUS_CLOSE",
    ISSUE_STATUS_OPEN_AND_CLOSE = "ISSUE_STATUS_OPEN_AND_CLOSE",
    ISSUE_STATE = "ISSUE_STATE",
    ISSUES = "ISSUES",
    ISSUE_MENU_TITLE = "ISSUE_MENU_TITLE",

    // Job Description
    JOB_DESCRIPTION_CREATE_NEW_TITLE = "JOB_DESCRIPTION_CREATE_NEW_TITLE",
    JOB_DESCRIPTION_EDIT_TITLE = "JOB_DESCRIPTION_EDIT_TITLE",
    JOB_DESCRIPTION = "JOB_DESCRIPTION",
    ADD_NEW_JOB_DESCRIPTION = "ADD_NEW_JOB_DESCRIPTION",

    // DragFile
    DRAG_FILE_MAKE_PHOTO = "DRAG_FILE_MAKE_PHOTO",
    DRAG_FILE_GALLERY = "DRAG_FILE_GALLERY",

    // LOGIN
    LOGGIN_USERNAME = "LOGGIN_USERNAME",
    LOGGIN_PLACEHOLDER_USERNAME = "LOGGIN_PLACEHOLDER_USERNAME",
    LOGGIN_PASSWORD = "LOGGIN_PASSWORD",
    LOGGIN_PLACEHOLDER_PASSWORD = "LOGGIN_PLACEHOLDER_PASSWORD",
    RECOVER_PASSWORD_EMAIL = "RECOVER_PASSWORD_EMAIL",
    RECOVER_PASSWORD_PLACEHOLDER_EMAIL = "RECOVER_PASSWORD_PLACEHOLDER_EMAIL",
    RESET_PASSWORD = "RESET_PASSWORD",
    RESET_PASSWORD_PLACEHOLDER = "RESET_PASSWORD_PLACEHOLDER",
    RESET_PASSWORD_CONFIRM_PASSWORD = "RESET_PASSWORD_CONFIRM_PASSWORD",
    RESET_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD = "RESET_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD",

    // PLACEHOLDER PAGE
    SITE_UNDER_CONSTRUCTION = "SITE_UNDER_CONSTRUCTION",

    // PLAN APPCC
    PLAN_DELETE = "PLAN_DELETE",
    PLAN_EDIT = "PLAN_EDIT",
    PLAN_CREATE = "PLAN_CREATE",
    PLAN_ID = "PLAN_ID",
    PLAN_NAME = "PLAN_NAME",
    PLAN_NAME_TASK_COUNT = "PLAN_NAME_TASK_COUNT",
    PLAN_NAME_ASSET_COUNT = "PLAN_NAME_ASSET_COUNT",
    PLAN_NAME_CORRECTIVE_MEASSURE_COUNT = "PLAN_NAME_CORRECTIVE_MEASSURE_COUNT",
    PLANS = "PLANS",

    // EDIT FORM PLAN
    EDIT_FORM_PLAN_NAME_LABEL = "EDIT_FORM_PLAN_NAME_LABEL",
    EDIT_FORM_PLAN_NAME_PLACEHOLDER = "EDIT_FORM_PLAN_NAME_PLACEHOLDER",

    // AUDIT TEMPLATE
    ADD_AUDIT = "ADD_AUDIT",
    AUDIT_NAME_LABEL = "AUDIT_NAME_LABEL",
    NEW_AUDIT = "NEW_AUDIT",
    EDIT_AUDIT = "EDIT_AUDIT",
    AUDIT_LIST_EMPTY_TITLE = "AUDIT_LIST_EMPTY_TITLE",
    AUDIT_LIST_EMPTY_BODY = "AUDIT_LIST_EMPTY_BODY",

    // AUDIT GROUP
    AUDIT_GROUP_NAME_LABEL = "AUDIT_GROUP_NAME_LABEL",
    ADD_AUDIT_GROUP = "ADD_AUDIT_GROUP",
    NEW_AUDIT_GROUP = "NEW_AUDIT_GROUP",
    EDIT_AUDIT_GROUP = "EDIT_AUDIT_GROUP",
    AUDIT_GROUP_EMPTY_TITLE = "AUDIT_GROUP_EMPTY_TITLE",
    AUDIT_GROUP_EMPTY_BODY = "AUDIT_GROUP_EMPTY_BODY",

    // AUDIT GROUP CHECKLIST
    NEW_AUDIT_GROUP_CHECKLIST = "NEW_AUDIT_GROUP_CHECKLIST",
    EDIT_AUDIT_GROUP_CHECKLIST = "EDIT_AUDIT_GROUP_CHECKLIST",
    AUDIT_GROUP_CHECKLIST_NAME_LABEL = "AUDIT_GROUP_CHECKLIST_NAME_LABEL",
    AUDIT_GROUP_CHECKLIST_NAME = "AUDIT_GROUP_CHECKLIST_NAME",
    AUDIT_GROUP_CHECKLISTS = "AUDIT_GROUP_CHECKLISTS",
    AUDIT_GROUP_CHECKLIST_EMPTY_TITLE = "AUDIT_GROUP_CHECKLIST_EMPTY_TITLE",
    AUDIT_GROUP_CHECKLIST_EMPTY_BODY = "AUDIT_GROUP_CHECKLIST_EMPTY_BODY",
    AUDIT_GROUP_CHECKLIST_ITEM_COUNT = "AUDIT_GROUP_CHECKLIST_ITEM_COUNT",

    // AUDIT GROUP CHECKLIST ITEM
    ADD_AUDIT_GROUP_CHECKLIST_ITEM = "ADD_AUDIT_GROUP_CHECKLIST_ITEM",

    // BASE TASK MODAL
    BASE_TASK_MODAL_TITLE = "BASE_TASK_MODAL_TITLE",
    BASE_TASK_MODAL_REGISTRY_TITLE = "BASE_TASK_MODAL_REGISTRY_TITLE",
    BASE_TASK_MODAL_IS_APPCC = "BASE_TASK_MODAL_IS_APPCC",
    BASE_TASK_MODAL_IS_TASK_TYPE_LABEL = "BASE_TASK_MODAL_IS_TASK_TYPE_LABEL",
    BASE_TASK_MODAL_TASKS_LABEL = "BASE_TASK_MODAL_TASKS_LABEL",
    BASE_TASK_MODAL_CREATE_NEW = "BASE_TASK_MODAL_CREATE_NEW",
    BASE_TASK_LABEL = "BASE_TASK_LABEL",

    // BASE TASK TYPE
    BASE_TASK_SAVE_TITLE = "BASE_TASK_SAVE_TITLE",
    BASE_TASK_EDIT_TITLE = "BASE_TASK_EDIT_TITLE",
    BASE_TASK_TYPE_ADD_NEW_TYPE_TITLE = "BASE_TASK_TYPE_ADD_NEW_TYPE_TITLE",

    // CORRECTIVE MEASURES
    CORRECTIVE_MEASSURE_MEASURE = "CORRECTIVE_MEASSURE_MEASURE",
    CORRECTIVE_MEASSURE_MODAL_TITLE_CREATE = "CORRECTIVE_MEASSURE_MODAL_TITLE_CREATE",
    CORRECTIVE_MEASSURE_MODAL_TITLE_EDIT = "CORRECTIVE_MEASSURE_MODAL_TITLE_EDIT",
    CORRECTIVE_MEASSURE_MODAL_LABEL = "CORRECTIVE_MEASSURE_MODAL_LABEL",
    CORRECTIVE_MEASSURE_MODAL_PLACEHOLDER = "CORRECTIVE_MEASSURE_MODAL_PLACEHOLDER",
    CORRECTIVE_MEASSURE_DESCRIPTION = "CORRECTIVE_MEASSURE_DESCRIPTION",
    CORRECTIVE_MEASSURE_PLAN = "CORRECTIVE_MEASSURE_PLAN",
    CORRECTIVE_MEASSURE_ID = "CORRECTIVE_MEASSURE_ID",

    // AUDIT INSTANCE
    AUDIT = "AUDIT",
    START_AUDIT = "START_AUDIT",
    START_AUDIT_MODAL_TITLE = "START_AUDIT_MODAL_TITLE",
    START_AUDIT_MODAL_AUDIT_LABEL = "START_AUDIT_MODAL_AUDIT_LABEL",
    AUDIT_NOT_EXIST = "AUDIT_NOT_EXIST",
    AUDIT_CHECKLIST_INSTANC_NOT_EXIST = "AUDIT_CHECKLIST_INSTANC_NOT_EXIST",
    COMPLETE_CHECKLIST = "COMPLETE_CHECKLIST",
    AUDIT_CHECKLIST_ITEM_REQUIRED = "AUDIT_CHECKLIST_ITEM_REQUIRED",
    AUDIT_TOTAL = "AUDIT_TOTAL",
    AUDIT_STATUS = "AUDIT_STATUS",
    AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED = "AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED",
    AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS = "AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS",
    AUDIT_ID = "AUDIT_ID",
    AUDIT_OPENED_BY = "AUDIT_OPENED_BY",
    AUDIT_OPENED_DATE = "AUDIT_OPENED_DATE",
    AUDIT_CLOSED_BY = "AUDIT_CLOSED_BY",
    AUDIT_CLOSED_DATE = "AUDIT_CLOSED_DATE",
    AUDIT_CLOSED = "AUDIT_CLOSED",
    AUDIT_OPEN = "AUDIT_OPEN",
    AUDITS = "AUDITS",
    AUDIT_OPEN_TAB = "AUDIT_OPEN_TAB",
    AUDIT_ALL_CHECKLIST_REQUIRED = "AUDIT_ALL_CHECKLIST_REQUIRED",
    AUDIT_FAILED_COMPLETE = "AUDIT_FAILED_COMPLETE",
    AUDIT_SUCCESS_COMPLETE = "AUDIT_SUCCESS_COMPLETE",
    AUDIT_FINISH = "AUDIT_FINISH",
    AUDIT_FINISH_CONFIRM_MODAL_TITLE = "AUDIT_FINISH_CONFIRM_MODAL_TITLE",
    AUDIT_FINISH_CONFIRM_MODAL_SUBTITLE = "AUDIT_FINISH_CONFIRM_MODAL_SUBTITLE",
    AUDIT_OPEN_DAY = "AUDIT_OPEN_DAY",
    AUDIT_CLOSED_DAY = "AUDIT_CLOSED_DAY",

    // MEASUREMENT UNIT
    ADD_MEASUREMENT_UNIT = "ADD_MEASUREMENT_UNIT",
    SAVE_MEASUREMENT_UNIT_TITLE = "SAVE_MEASUREMENT_UNIT_TITLE",
    EDIT_MEASUREMENT_UNIT_TITLE = "EDIT_MEASUREMENT_UNIT_TITLE",

    // ALL THINGS
    ALL_TASKS = "ALL_TASKS",
    ALL_QRS = "ALL_QRS",
    ALL_PLANS = "ALL_PLANS",
    ALL_COMPANIES = "ALL_COMPANIES",
    ALL_COMPANIES_F = "ALL_COMPANIES_F",

    // FAQs
    MENU_SIDEBAR_FAQS = "MENU_SIDEBAR_FAQS",

    // PROFILE
    MY_PROFILE = "MY_PROFILE",

    // LICENSES
    ENABLED_MODULES = "ENABLED_MODULES",

    // POST LOGIN
    WELCOME_COMPANY = "WELCOME_COMPANY",
}

export enum TranslationModules {
    CONFIGURATION = "CONFIGURATION",
    COM_AND_NOTIF = "COM_AND_NOTIF",
    TAR_REGISTRY = "TAR_REGISTRY",
    TAR_PLAN = "TAR_PLAN",
    TAR_REVISION = "TAR_REVISION",
    REPORT = "REPORT",
    ANALYTICS = "ANALYTICS",
}

export enum TranslationErrors {
    // GENERIC
    NO_COMPANY_ASSIGNED = "NO_COMPANY_ASSIGNED",
    NO_COMPANY_ASSIGNED_F = "NO_COMPANY_ASSIGNED_F",
    APP_FAILED_TOKEN = "APP_FAILED_TOKEN",
    NO_PERMISSIONS_TITLE = "NO_PERMISSIONS_TITLE",
    NO_PERMISSIONS_SUBTITLE = "NO_PERMISSIONS_SUBTITLE",
    ERROR_UNDER_MAINTENIANCE_SUBTITLE = "ERROR_UNDER_MAINTENIANCE_SUBTITLE",
    ERROR_UNDER_MAINTENIANCE_TITLE = "ERROR_UNDER_MAINTENIANCE_TITLE",
    NO_COMPANY_ASSIGNED_TITLE = "NO_COMPANY_ASSIGNED_TITLE",
    NO_COMPANY_ASSIGNED_TITLE_F = "NO_COMPANY_ASSIGNED_TITLE_F",
    PAGE_NOT_FOUND_TITLE = "PAGE_NOT_FOUND_TITLE",
    GENERIC_ERROR_MESSAGE_TITLE = "GENERIC_ERROR_MESSAGE_TITLE",
    GENERIC_ERROR_MESSAGE_VALUES_IS_OBLIGATORY = "GENERIC_ERROR_MESSAGE_VALUES_IS_OBLIGATORY",
    GENERIC_ERROR_MESSAGE_VALUES_MUST_BE_GREATER_THAN_ZERO = "GENERIC_ERROR_MESSAGE_VALUES_MUST_BE_GREATER_THAN_ZERO",
    SELECT_AT_LEAST_ONE_OPTION = "SELECT_AT_LEAST_ONE_OPTION",
    ADD_AT_LEAST_ONE = "ADD_AT_LEAST_ONE",

    // USER
    USERNAME_ALREADY_REGISTERED = "USERNAME_ALREADY_REGISTERED",
    USER_EMAIL_ALREADY_REGISTERED = "USER_EMAIL_ALREADY_REGISTERED",
    INVALID_CHARACTERS = "INVALID_CHARACTERS",
    USERNAME_INVALID_CHARACTERS = "USERNAME_INVALID_CHARACTERS",
    USERNAME_IS_REQUIRED = "USERNAME_IS_REQUIRED",
    EMAIL_IS_REQUIRED = "EMAIL_IS_REQUIRED",
    EMAIL_IS_INVALID = "EMAIL_IS_INVALID",
    FIRSTNAME_IS_REQUIRED = "FIRSTNAME_IS_REQUIRED",
    LASTNAME_IS_REQUIRED = "LASTNAME_IS_REQUIRED",
    USERCODE_IS_REQUIRED = "USERCODE_IS_REQUIRED",
    PROFILEPICTUREID_IS_INVALID = "PROFILEPICTUREID_IS_INVALID",
    INVALID_DAY_CODE = "INVALID_DAY_CODE",
    USERCOMPANIES_0_IS_INVALID = "USERCOMPANIES_0_IS_INVALID",
    USERCOMPANIES_1_IS_INVALID = "USERCOMPANIES_1_IS_INVALID",
    USERCOMPANIES_2_IS_INVALID = "USERCOMPANIES_2_IS_INVALID",
    USERCOMPANIES_3_IS_INVALID = "USERCOMPANIES_3_IS_INVALID",
    USERCOMPANIES_4_IS_INVALID = "USERCOMPANIES_4_IS_INVALID",
    USERCOMPANIES_5_IS_INVALID = "USERCOMPANIES_5_IS_INVALID",
    USERCOMPANIES_6_IS_INVALID = "USERCOMPANIES_6_IS_INVALID",
    USERCOMPANIES_7_IS_INVALID = "USERCOMPANIES_7_IS_INVALID",
    USERCOMPANIES_8_IS_INVALID = "USERCOMPANIES_8_IS_INVALID",
    USERCOMPANIES_9_IS_INVALID = "USERCOMPANIES_9_IS_INVALID",
    USERCOMPANIES_0_IS_INVALID_F = "USERCOMPANIES_0_IS_INVALID_F",
    USERCOMPANIES_1_IS_INVALID_F = "USERCOMPANIES_1_IS_INVALID_F",
    USERCOMPANIES_2_IS_INVALID_F = "USERCOMPANIES_2_IS_INVALID_F",
    USERCOMPANIES_3_IS_INVALID_F = "USERCOMPANIES_3_IS_INVALID_F",
    USERCOMPANIES_4_IS_INVALID_F = "USERCOMPANIES_4_IS_INVALID_F",
    USERCOMPANIES_5_IS_INVALID_F = "USERCOMPANIES_5_IS_INVALID_F",
    USERCOMPANIES_6_IS_INVALID_F = "USERCOMPANIES_6_IS_INVALID_F",
    USERCOMPANIES_7_IS_INVALID_F = "USERCOMPANIES_7_IS_INVALID_F",
    USERCOMPANIES_8_IS_INVALID_F = "USERCOMPANIES_8_IS_INVALID_F",
    USERCOMPANIES_9_IS_INVALID_F = "USERCOMPANIES_9_IS_INVALID_F",
    INVALID_LANGUAGE = "INVALID_LANGUAGE",
    USERCOMPANIES_SELECT_AT_LEAST_ONE_COMPANY = "USERCOMPANIES_SELECT_AT_LEAST_ONE_COMPANY",

    // WIZARD COMPANY
    WIZARD_COMPANY_ERROR_NAME = "WIZARD_COMPANY_ERROR_NAME",
    WIZARD_COMPANY_ERROR_NAME_F = "WIZARD_COMPANY_ERROR_NAME_F",
    WIZARD_COMPANY_ERROR_COPY_EXISTING_COMPANY = "WIZARD_COMPANY_ERROR_COPY_EXISTING_COMPANY",
    WIZARD_COMPANY_ERROR_SELECTED_COMPANY = "WIZARD_COMPANY_ERROR_SELECTED_COMPANY",
    WIZARD_COMPANY_ERROR_SELECTED_COMPANY_F = "WIZARD_COMPANY_ERROR_SELECTED_COMPANY_F",
    WIZARD_COMPANY_ERROR_SELECT_AT_LEAST_ONE_DEPARTMENT = "WIZARD_COMPANY_ERROR_SELECT_AT_LEAST_ONE_DEPARTMENT",
    WIZARD_COMPANY_ERROR_COPY_EXISTING_USER = "WIZARD_COMPANY_ERROR_COPY_EXISTING_USER",

    // WIZARD LICENSE
    INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
    INITIALS_CANNOT_BE_EMPTY = "INITIALS_CANNOT_BE_EMPTY",
    INITIALS_MAX_LENGTH = "INITIALS_MAX_LENGTH",
    INITIALS_INVALID_FORMAT = "INITIALS_INVALID_FORMAT",
    INITIALS_ALREADY_USED = "INITIALS_ALREADY_USED",
    INVALID_CUSTOMER_TYPE = "INVALID_CUSTOMER_TYPE",

    // FEEDBACK
    FEEDBACK_COUNTERS_ERROR = "FEEDBACK_COUNTERS_ERROR",

    // WIZARD TASK
    ERROR_MESSAGE_CANCEL_REASIGNATION_FAILED = "ERROR_MESSAGE_CANCEL_REASIGNATION_FAILED",

    // ASSET
    ASSET_NAME_REQUIRED = "ASSET_NAME_REQUIRED",
    ASSET_MIN_TEMP_REQUIRED = "ASSET_MIN_TEMP_REQUIRED",
    ASSET_MAX_TEMP_REQUIRED = "ASSET_MAX_TEMP_REQUIRED",
    ASSET_MIN_TEMP_BIGGER_THAN_MAX = "ASSET_MIN_TEMP_BIGGER_THAN_MAX",
    ASSET_MIN_MAX_LENGTH_ASSETID = "ASSET_MIN_MAX_LENGTH_ASSETID",
    ASSET_MIN_MAX_LENGTH_SENSOR_SERIAL_NUMBER = "ASSET_MIN_MAX_LENGTH_SENSOR_SERIAL_NUMBER",
    ASSET_ID_ALREADY_IN_USE = "ASSET_ID_ALREADY_IN_USE",
    ASSET_MIN_MAX_REQUIRED = "ASSET_MIN_MAX_REQUIRED",
    SENSOR_NUMBER_INVALID = "SENSOR_NUMBER_INVALID",

    // PROFILE
    TIME_RANGE_ERROR = "TIME_RANGE_ERROR",
    START_TIME_NULL_OR_EMPTY = "START_TIME_NULL_OR_EMPTY",
    END_TIME_NULL_OR_EMPTY = "END_TIME_NULL_OR_EMPTY",

    // LOGIN
    PASS_ADMIN_INVALID = "PASS_ADMIN_INVALID",
    PASSWORDS_NOT_MATCH = "PASSWORDS_NOT_MATCH",
    GENERIC_ERROR = "GENERIC_ERROR",
    LOGIN_INVALID_CREDENTIALS = "LOGIN_INVALID_CREDENTIALS",
    RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE = "RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE",
    PASSWORD_CONTAINS_ASTERISK = "PASSWORD_CONTAINS_ASTERISK",

    // RESET PASSWORD
    RESET_PASSWORD_ERROR_LENGTH_MESSAGE = "RESET_PASSWORD_ERROR_LENGTH_MESSAGE",
    RESET_PASSWORD_ERROR_CAPITAL_LETTER_AND_MINUSCULE_MESSAGE = "RESET_PASSWORD_ERROR_CAPITAL_LETTER_AND_MINUSCULE_MESSAGE",
    RESET_PASSWORD_ERROR_NUMBER_MESSAGE = "RESET_PASSWORD_ERROR_NUMBER_MESSAGE",
    RESET_PASSWORD_ERROR_SPECIAL_CHARACTER_MESSAGE = "RESET_PASSWORD_ERROR_SPECIAL_CHARACTER_MESSAGE",

    // REASSIGN TASK
    REASIGN_TASK_DATES_ERROR_MESSAGE = "REASIGN_TASK_DATES_ERROR_MESSAGE",

    // WORKING POSITION
    WORKING_POSITION_ALREADY_EXIST = "WORKING_POSITION_ALREADY_EXIST",
    WORKING_POSITION_ENTITY_NOT_FOUND = "WORKING_POSITION_ENTITY_NOT_FOUND",
    WORKING_POSITION_TASKS_EMPTY = "WORKING_POSITION_TASKS_EMPTY",
    WORKING_POSITION_FAILED_LOAD_DATA = "WORKING_POSITION_FAILED_LOAD_DATA",
    WORKING_POSITION_ALREADY_SELECTED = "WORKING_POSITION_ALREADY_SELECTED",
    TASKS_ALREADY_IMPORTED = "TASKS_ALREADY_IMPORTED",

    // DEPARTMENT
    DEPARTMENT_NOT_EXIST = "DEPARTMENT_NOT_EXIST",
    DEPARTMENT_IS_REQUIRED = "DEPARTMENT_IS_REQUIRED",
    MAX_DEPARTMENTS_ALLOWED_ERROR = "MAX_DEPARTMENTS_ALLOWED_ERROR",
    MAX_DEPARTMENTS_ALLOWED_ERROR_DESCRIPTION = "MAX_DEPARTMENTS_ALLOWED_ERROR_DESCRIPTION",

    // SUBDEPARTMENT
    SUBDEPARTMENT_NOT_EXIST = "SUBDEPARTMENT_NOT_EXIST",

    // COMPANY
    COMPANIES_FAILED_LOAD_DATA = "COMPANIES_FAILED_LOAD_DATA",
    COMPANIES_FAILED_LOAD_DATA_F = "COMPANIES_FAILED_LOAD_DATA_F",
    MAX_EMAIL_LENGTH_REACHED = "MAX_EMAIL_LENGTH_REACHED",
    INVALID_EMAIL = "INVALID_EMAIL",

    // TASK INSTANCE
    TASK_INSTANCE_ALREADY_COMPLETED = "TASK_INSTANCE_ALREADY_COMPLETED",
    TASK_INSTANCE_ALREADY_REOPENED = "TASK_INSTANCE_ALREADY_REOPENED",
    TASK_INSTANCE_NOT_YOURS = "TASK_INSTANCE_NOT_YOURS",
    TASK_INSTANCE_NOT_EXIST = "TASK_INSTANCE_NOT_EXIST",
    TASK_INSTANCE_CHECKLIST_NOT_YOURS = "TASK_INSTANCE_CHECKLIST_NOT_YOURS",
    TASK_INSTANCE_CHECKLIST_FAILED = "TASK_INSTANCE_CHECKLIST_FAILED",
    TASK_INSTANCE_PHOTOS_NULL_OR_EMPTY = "TASK_INSTANCE_PHOTOS_NULL_OR_EMPTY",
    TASK_INSTANCE_PHOTOS_NOT_FOUND = "TASK_INSTANCE_PHOTOS_NOT_FOUND",
    TASK_INSTANCE_PHOTOS_MAX_FILES = "TASK_INSTANCE_PHOTOS_MAX_FILES",
    TASK_INSTANCE_PHOTOS_NO_PHOTOS = "TASK_INSTANCE_PHOTOS_NO_PHOTOS",
    TASK_INSTANCE_PHOTOS_NOT_MINE = "TASK_INSTANCE_PHOTOS_NOT_MINE",
    TASK_INSTANCE_PHOTOS_TASK_ALREADY_COMPLETED = "TASK_INSTANCE_PHOTOS_TASK_ALREADY_COMPLETED",
    TASK_INSTANCE_PHOTOS_FAILED_UPLOADING = "TASK_INSTANCE_PHOTOS_FAILED_UPLOADING",
    TASK_INSTANCE_PHOTOS_NOT_EXIST = "TASK_INSTANCE_PHOTOS_NOT_EXIST",
    STATUS_CODE_INVALID = "STATUS_CODE_INVALID",

    // ANALIST
    ANALIST_DETAIL_ERROR = "ANALIST_DETAIL_ERROR",
    ANALIST_TASK_COUNTERS_ERROR = "ANALIST_TASK_COUNTERS_ERROR",
    ANALIST_DETAIL_LIST_ERROR = "ANALIST_DETAIL_LIST_ERROR",

    // ISSUE
    ERROR_MESSAGE_MAX_10_COR_MES = "ERROR_MESSAGE_MAX_10_COR_MES",
    ERROR_MESSAGE_MAX_20_SUPERVISORS = "ERROR_MESSAGE_MAX_20_SUPERVISORS",
    ERROR_MESSAGE_ISSUE_NOT_CREATED = "ERROR_MESSAGE_ISSUE_NOT_CREATED",

    // ISSUE TABLE
    TABLE_ERROR_ISSUE_TASK = "TABLE_ERROR_ISSUE_TASK",
    TABLE_ERROR_ISSUE_TASK_OPEN = "TABLE_ERROR_ISSUE_TASK_OPEN",
    TABLE_ERROR_ISSUE_TASK_CLOSE = "TABLE_ERROR_ISSUE_TASK_CLOSE",
    TABLE_ERROR_ISSUE_TASK_ASSET = "TABLE_ERROR_ISSUE_TASK_ASSET",
    TABLE_ERROR_ISSUE_TASK_ASSET_OPEN = "TABLE_ERROR_ISSUE_TASK_ASSET_OPEN",
    TABLE_ERROR_ISSUE_TASK_ASSET_CLOSE = "TABLE_ERROR_ISSUE_TASK_ASSET_CLOSE",
    TABLE_ERROR_ISSUE_GENERIC = "TABLE_ERROR_ISSUE_GENERIC",
    TABLE_ERROR_ISSUE_GENERIC_OPEN = "TABLE_ERROR_ISSUE_GENERIC_OPEN",
    TABLE_ERROR_ISSUE_GENERIC_CLOSE = "TABLE_ERROR_ISSUE_GENERIC_CLOSE",
    TABLE_ERROR_ISSUE_AUDIT = "TABLE_ERROR_ISSUE_AUDIT",
    TABLE_ERROR_ISSUE_AUDIT_OPEN = "TABLE_ERROR_ISSUE_AUDIT_OPEN",
    TABLE_ERROR_ISSUE_AUDIT_CLOSE = "TABLE_ERROR_ISSUE_AUDIT_CLOSE",

    // JOB DESCRIPTION
    JOB_DESCRIPTION_NOT_FOUND = "JOB_DESCRIPTION_NOT_FOUND",
    JOB_DESCRIPTION_ALREADY_EXIST = "JOB_DESCRIPTION_ALREADY_EXIST",
    ERROR_DELETING_JOB_DESCRIPTION = "ERROR_DELETING_JOB_DESCRIPTION",

    // AUDIT GROUP CHECKLIST
    AUDIT_GROUP_CHECKLIST_NAME_ERROR = "AUDIT_GROUP_CHECKLIST_NAME_ERROR",
    AUDIT_GROUP_CHECKLIST_SUBMIT_ERROR_TITLE = "AUDIT_GROUP_CHECKLIST_SUBMIT_ERROR_TITLE",

    // AUDIT GROUP CHECKLIST ITEM
    AUDIT_GROUP_CHECKLIST_ITEM_ERROR = "AUDIT_GROUP_CHECKLIST_ITEM_ERROR",

    // BASE TASK
    BASE_TASK_TYPE_NAME_ERROR = "BASE_TASK_TYPE_NAME_ERROR",
    BASE_TASK_TYPE_NOT_FOUND = "BASE_TASK_TYPE_NOT_FOUND",
    BASE_TASK_NOT_FOUND = "BASE_TASK_NOT_FOUND",

    // AUDIT INSTANCE
    AUDIT_CHECKLIST_ERROR_FORM_VALUES = "AUDIT_CHECKLIST_ERROR_FORM_VALUES",

    // PLAN APPCC
    PLAN_NOT_FOUND = "PLAN_NOT_FOUND",
    PLANS_NOT_FOUND = "PLANS_NOT_FOUND",

    // AUDIT
    AUDIT_NOT_FOUND = "AUDIT_NOT_FOUND",

    // AUDIT GROUP
    AUDIT_GROUP_NOT_FOUND = "AUDIT_GROUP_NOT_FOUND",

    // TASK
    EMPTY_TASK_ID_LIST = "EMPTY_TASK_ID_LIST",
    CHECKLIST_CANNOT_BE_ALL_DISABLED = "CHECKLIST_CANNOT_BE_ALL_DISABLED",

    // MEASUREMENT UNIT
    MEASUREMENT_UNIT_CANNOT_BE_NULL = "MEASUREMENT_UNIT_CANNOT_BE_NULL",

    // ONBOARDING
    ONBOARDING_SUBSTEP_INSTANCE_NOT_FOUND = "ONBOARDING_SUBSTEP_INSTANCE_NOT_FOUND",
    ONBOARDING_SUBSTEP_INSTANCE_INVALID_STATUS = "ONBOARDING_SUBSTEP_INSTANCE_INVALID_STATUS",
    ONBOARDING_STEP_INSTANCE_NOT_FOUND = "ONBOARDING_STEP_INSTANCE_NOT_FOUND",
    ONBOARDING_STEP_INSTANCE_INVALID_STATUS = "ONBOARDING_STEP_INSTANCE_INVALID_STATUS",
    ONBOARDING_STEP_INSTANCE_COMMENT_CANNOT_BE_EMPTY = "ONBOARDING_STEP_INSTANCE_COMMENT_CANNOT_BE_EMPTY",
    ONBOARDING_SUBSTEPS_NOT_COMPLETED = "ONBOARDING_SUBSTEPS_NOT_COMPLETED",
    ONBOARDING_FAILED_FETCH = "ONBOARDING_FAILED_FETCH",
    ONBOARDING_FAILED_COMPLETE = "ONBOARDING_FAILED_COMPLETE",
    ONBOARDING_FAILED_SEND = "ONBOARDING_FAILED_SEND",
    ONBOARDING_FAILED_REVIEW = "ONBOARDING_FAILED_REVIEW",
    ONBOARDING_FAILED_IN_PROGRESS = "ONBOARDING_FAILED_IN_PROGRESS",

    // SENSOR
    SENSOR_NOT_EXIST = "SENSOR_NOT_EXIST",

    // EMAIL DISTRIBUTION LIST
    NOTIFICATION_DISTRIBUTION_LIST_NOT_FOUND = "NOTIFICATION_DISTRIBUTION_LIST_NOT_FOUND",
    NOTIFICATION_DISTRIBUTION_LIST_ALREADY_ADDED = "NOTIFICATION_DISTRIBUTION_LIST_ALREADY_ADDED",
    MAX_USERIDLIST_EXCEDED = "MAX_USERIDLIST_EXCEDED",

    // PATCH NOTES
    PATCH_NOTE_VERSION_DESCRIPTION_NOT_FOUND = "PATCH_NOTE_VERSION_DESCRIPTION_NOT_FOUND",

    // ALERTS
    DELETE_ALERTS_ERROR_TITLE = "DELETE_ALERTS_ERROR_TITLE",
    DELETE_ALERTS_ERROR_DESCRIPTION = "DELETE_ALERTS_ERROR_DESCRIPTION",

    // TaskTemporalityDateRange
    START_DAY_GREATER_THAN_END_DAY = "START_DAY_GREATER_THAN_END_DAY",
    TASK_TEMPORALITY_SELECT_A_RANGE = "TASK_TEMPORALITY_AT_LEAST_A_RANGE",
    TASK_TEMPORALITY_WEEK_GREATER_THAN = "TASK_TEMPORALITY_WEEK_GREATER_THAN",
    TASK_TEMPORALITY_MONTH_GREATER_THAN = "TASK_TEMPORALITY_MONTH_GREATER_THAN",
    TASK_TEMPORALITY_MONTH_BETWEEN = "TASK_TEMPORALITY_MONTH_BETWEEN",
    TASK_TEMPORALITY_WEEKLY_SUNDAY_ERROR_TITLE = "TASK_TEMPORALITY_WEEKLY_SUNDAY_ERROR_TITLE",
    TASK_TEMPORALITY_WEEKLY_SUNDAY_ERROR_DESCRIPTION = "TASK_TEMPORALITY_WEEKLY_SUNDAY_ERROR_DESCRIPTION",

    // Emails
    INVALID_EMAIL_ADDRESSESS = "INVALID_EMAIL_ADDRESSESS",
    INVALID_EMAIL_ADDRESSESS_TITLE = "INVALID_EMAIL_ADDRESSESS_TITLE",
    INVALID_EMAIL_ADDRESSESS_SUBTITLE = "INVALID_EMAIL_ADDRESSESS_SUBTITLE",
    EMAIL_ALREADY_ADDED = "EMAIL_ALREADY_ADDED",
}

export enum TranslationModals {
    // GENERIC
    SUCCESS_SAVE = "SUCCESS_SAVE",
    FAILED_SAVE = "FAILED_SAVE",
    SUCCESS_EDIT = "SUCCESS_EDIT",
    FAILED_EDIT = "FAILED_EDIT",
    SUCCESS_DELETE = "SUCCESS_DELETE",
    TOAST_GENERIC_ERROR = "TOAST_GENERIC_ERROR",
    TOAST_SUCCESS_SAVE = "TOAST_SUCCESS_SAVE",
    TOAST_SUCCESS_EDIT = "TOAST_SUCCESS_EDIT",
    TOAST_SUCCESS_TASK = "TOAST_SUCCESS_TASK",
    TOAST_WARNING_SELECT_OPTION = "TOAST_WARNING_SELECT_OPTION",

    // TASK
    SUCCESS_EDIT_TITLE_TOAST = "SUCCESS_EDIT_TITLE_TOAST",
    SUCCESS_EDIT_SUBTITLE_TOAST = "SUCCESS_EDIT_SUBTITLE_TOAST",
    FAILED_SAVE_TASK_TITLE = "FAILED_SAVE_TASK_TITLE",
    FAILED_SAVE_TASK_DESCRIPTION = "FAILED_SAVE_TASK_DESCRIPTION",
    REGENERATING_TASKS_TITLE = "REGENERATING_TASKS_TITLE",
    GENERATING_TASKS_TITLE = "GENERATING_TASKS_TITLE",
    GENERATING_TASKS_DESCRIPTION = "GENERATING_TASKS_DESCRIPTION",
    DELETE_TASK_TITLE = "DELETE_TASK_TITLE",
    DELETE_TASK_DESCRIPTION = "DELETE_TASK_DESCRIPTION",
    DELETE_REGISTRY_DESCRIPTION = "DELETE_REGISTRY_DESCRIPTION",

    // RESTABLISH PASSWORD
    RESTABLISH_PASSWORD_TITLE_TOAST = "RESTABLISH_PASSWORD_TITLE_TOAST",
    RESTABLISH_PASSWORD_SUBTITLE_TOAST = "RESTABLISH_PASSWORD_SUBTITLE_TOAST",

    // USER
    EDIT_FORM_USER_TITLE_TOAST = "EDIT_FORM_USER_TITLE_TOAST",
    EDIT_FORM_USER_TITLE_MODAL = "EDIT_FORM_USER_TITLE_MODAL",
    EDIT_FORM_USER_DESCRIPTION_MODAL = "EDIT_FORM_USER_DESCRIPTION_MODAL",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_TITLE = "EDIT_FORM_USER_ADD_ALL_COMPANIES_TITLE",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_TITLE_F = "EDIT_FORM_USER_ADD_ALL_COMPANIES_TITLE_F",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_DESCRIPTION = "EDIT_FORM_USER_ADD_ALL_COMPANIES_DESCRIPTION",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_DESCRIPTION_F = "EDIT_FORM_USER_ADD_ALL_COMPANIES_DESCRIPTION_F",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE = "EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE_F = "EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE_F",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION = "EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION",
    EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION_F = "EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION_F",

    // PHOTO?
    TOAST_WARNING_PHOTO_REQUIRED = "TOAST_WARNING_PHOTO_REQUIRED",
    TOAST_ERROR_LOADING_PHOTO = "TOAST_ERROR_LOADING_PHOTO",
    TOAST_WARNING_PHOTO_ALREADY_UPLOADED = "TOAST_WARNING_PHOTO_ALREADY_UPLOADED",
    TOAST_PHOTO_NOT_ALLOWED_TITLE = "TOAST_PHOTO_NOT_ALLOWED_TITLE",
    TOAST_PHOTO_NOT_ALLOWED_SUBTITLE = "TOAST_PHOTO_NOT_ALLOWED_SUBTITLE",
    TOAST_PHOTO_ERROR_TITLE = "TOAST_PHOTO_ERROR_TITLE",
    TOAST_PHOTO_ERROR_SUBTITLE = "TOAST_PHOTO_ERROR_SUBTITLE",
    TOAST_PHOTO_MIN_PHOTOS_ALLOWED = "TOAST_PHOTO_MIN_PHOTOS_ALLOWED",

    // LOGIN
    TOAST_ERROR_LOGIN = "TOAST_ERROR_LOGIN",
    TOAST_ERROR_USER_NOT_FOUND_LOGIN = "TOAST_ERROR_USER_NOT_FOUND_LOGIN",

    // ALERT
    TOAST_ALERT_SENDED = "TOAST_ALERT_SENDED",

    // COMPANY
    TOAST_ERROR_COMPANY_GROUP = "TOAST_ERROR_COMPANY_GROUP",
    TOAST_ERROR_COMPANY = "TOAST_ERROR_COMPANY",
    TOAST_ERROR_COMPANY_F = "TOAST_ERROR_COMPANY_F",
    COMPANIES_ADD_DELETE_USERS_TITLE = "COMPANIES_ADD_DELETE_USERS_TITLE",
    COMPANIES_ADD_DELETE_USERS_DESCRIPTION = "COMPANIES_ADD_DELETE_USERS_DESCRIPTION",
    COMPANIES_ADD_USERS_TITLE = "COMPANIES_ADD_USERS_TITLE",
    COMPANIES_DELETE_USERS_TITLE = "COMPANIES_DELETE_USERS_TITLE",
    COMPANIES_DELETE_USERS_DESCRIPTION = "COMPANIES_DELETE_USERS_DESCRIPTION",

    // DEPARTMENT
    TOAST_ERROR_DEPARTMENT = "TOAST_ERROR_DEPARTMENT",

    // SUBDEPARTMENT
    TOAST_ERROR_SUBDEPARTMENT = "TOAST_ERROR_SUBDEPARTMENT",

    // QR
    TOAST_QR_ERROR = "TOAST_QR_ERROR",
    QR_CHECKER_MODAL_TITLE = "QR_CHECKER_MODAL_TITLE",
    QR_CHECKER_MODAL_DESCRIPTION = "QR_CHECKER_MODAL_DESCRIPTION",

    // ISSUE
    ISSUES_TOAST_FAILED_LOAD_DATA = "ISSUES_TOAST_FAILED_LOAD_DATA",
    ISSUE_IMAGE_LOADING_TITLE = "ISSUE_IMAGE_LOADING_TITLE",
    ISSUE_IMAGE_LOADING_DESCRIPTION = "ISSUE_IMAGE_LOADING_DESCRIPTION",

    // BASE TASK
    BASE_TASK_TOAST_FAILED_LOAD_DATA = "BASE_TASK_TOAST_FAILED_LOAD_DATA",
    TOAST_SAVE_APPCC_BASE_TASK = "TOAST_SAVE_APPCC_BASE_TASK",
    TOAST_SAVE_BASE_TASK = "TOAST_SAVE_BASE_TASK",
    TOAST_EDIT_APPCC_BASE_TASK = "TOAST_EDIT_APPCC_BASE_TASK",
    TOAST_EDIT_BASE_TASK = "TOAST_EDIT_BASE_TASK",

    // BASE TASK TYPE
    BASE_TASK_TYPE_SAVED_SUCCESSFULLY = "BASE_TASK_TYPE_SAVED_SUCCESSFULLY",
    BASE_TASK_TYPE_EDITED_SUCCESSFULLY = "BASE_TASK_TYPE_EDITED_SUCCESSFULLY",
    BASE_TASK_TYPE_DELETE_MODAL_TITLE = "BASE_TASK_TYPE_DELETE_MODAL_TITLE",
    BASE_TASK_TYPE_DELETE_MODAL_DESCRIPTION = "BASE_TASK_TYPE_DELETE_MODAL_DESCRIPTION",
    BASE_TASK_TYPE_DELETED_SUCCESSFULLY = "BASE_TASK_TYPE_DELETED_SUCCESSFULLY",
    CANNOT_DELETE_BASE_TASK_TYPE_TITLE = "CANNOT_DELETE_BASE_TASK_TYPE_TITLE",
    CANNOT_DELETE_BASE_TASK_TYPE_IN_USE = "CANNOT_DELETE_BASE_TASK_TYPE_IN_USE",

    // AUDIT TEMPLATE
    AUDIT_SAVE_TOAST = "AUDIT_SAVE_TOAST",
    DELETE_AUDIT_TITLE = "DELETE_AUDIT_TITLE",
    DELETE_AUDIT_BODY = "DELETE_AUDIT_BODY",
    AUDIT_EDIT_TOAST = "AUDIT_EDIT_TOAST",
    AUDIT_DELETE_TOAST = "AUDIT_DELETE_TOAST",

    // AUDIT GROUP
    DELETE_AUDIT_GROUP_TITLE = "DELETE_AUDIT_GROUP_TITLE",
    DELETE_AUDIT_GROUP_BODY = "DELETE_AUDIT_GROUP_BODY",
    AUDIT_GROUP_SAVE_TOAST = "AUDIT_GROUP_SAVE_TOAST",
    AUDIT_GROUP_EDIT_TOAST = "AUDIT_GROUP_EDIT_TOAST",
    AUDIT_GROUP_DELETE_TOAST = "AUDIT_GROUP_DELETE_TOAST",

    // AUDIT GROUP CHECKLIST
    DELETE_AUDIT_GROUP_CHECKLIST_TITLE = "DELETE_AUDIT_GROUP_CHECKLIST_TITLE",
    DELETE_AUDIT_GROUP_CHECKLIST_BODY = "DELETE_AUDIT_GROUP_CHECKLIST_BODY",
    AUDIT_GROUP_CHECKLIST_SAVE_TOAST = "AUDIT_GROUP_CHECKLIST_SAVE_TOAST",
    AUDIT_GROUP_CHECKLIST_EDIT_TOAST = "AUDIT_GROUP_CHECKLIST_EDIT_TOAST",
    AUDIT_GROUP_CHECKLIST_DELETE_TOAST = "AUDIT_GROUP_CHECKLIST_DELETE_TOAST",

    // AUDIT GROUP CHECKLIST ITEM INSTANCE
    AUDIT_CHECKLIST_ITEM_FAILED_UPDATE = "AUDIT_CHECKLIST_ITEM_FAILED_UPDATE",
    AUDIT_CHECKLIST_MODAL_TITLE = "AUDIT_CHECKLIST_MODAL_TITLE",
    AUDIT_CHECKLIST_MODAL_DESCRIPTION = "AUDIT_CHECKLIST_MODAL_DESCRIPTION",
    AUDIT_CHECKLIST_FAILED_COMPLETE = "AUDIT_CHECKLIST_FAILED_COMPLETE",
    AUDIT_CHECKLIST_COMPLETED = "AUDIT_CHECKLIST_COMPLETED",

    // MEASUREMENT UNIT
    MEASUREMENT_UNIT_SAVED_TOAST = "MEASUREMENT_UNIT_SAVED_TOAST",
    MEASUREMENT_UNIT_EDITED_TOAST = "MEASUREMENT_UNIT_EDITED_TOAST",
    MEASUREMENT_UNIT_DELETE_TITLE = "MEASUREMENT_UNIT_DELETE_TITLE",
    MEASUREMENT_UNIT_DELETE_DESCRIPTION = "MEASUREMENT_UNIT_DELETE_DESCRIPTION",
    MEASUREMENT_UNIT_DELETED_TOAST = "MEASUREMENT_UNIT_DELETED_TOAST",
    CANNOT_DELETE_MEASUREMENT_UNIT_TOAST_TITLE = "CANNOT_DELETE_MEASUREMENT_UNIT_TOAST_TITLE",
    CANNOT_DELETE_MEASUREMENT_UNIT_IN_USE = "CANNOT_DELETE_MEASUREMENT_UNIT_IN_USE",

    // ASSET
    ASSET_FAILED_TITLE = "ASSET_FAILED_TITLE",
    ASSET_FAILED_DESCRIPTION = "ASSET_FAILED_DESCRIPTION",

    // TASK DETAIL
    INACTIVE_CHECKLIST_ITEMS_TITLE = "INACTIVE_CHECKLIST_ITEMS_TITLE",
    INACTIVE_CHECKLIST_ITEMS_DESCRIPTION = "INACTIVE_CHECKLIST_ITEMS_DESCRIPTION",
    REOPENING_TASK_CONFIRM_TEXT = "REOPENING_TASK_CONFIRM_TEXT",
    TASK_DETAIL_DYNAMIC_FIELDS_WITH_ISSUE_TITLE = "TASK_DETAIL_DYNAMIC_FIELDS_WITH_ISSUE_TITLE",
    TASK_DETAIL_DYNAMIC_FIELDS_WITH_ISSUE_DESCRIPTION = "TASK_DETAIL_DYNAMIC_FIELDS_WITH_ISSUE_DESCRIPTION",

    // JOB DESCRIPTION
    JOB_DESCRIPTION_DELETE_MODAL_TITLE = "JOB_DESCRIPTION_DELETE_MODAL_TITLE",
    JOB_DESCRIPTION_DELETE_MODAL_DESCRIPTION = "JOB_DESCRIPTION_DELETE_MODAL_DESCRIPTION",

    // PLAN APPCC
    PLANS_FAILED_EDIT = "PLANS_FAILED_EDIT",
    PLANS_FAILED_CREATE = "PLANS_FAILED_CREATE",
    PLANS_SUCCESS_EDIT = "PLANS_SUCCESS_EDIT",
    PLANS_SUCCESS_CREATE = "PLANS_SUCCESS_CREATE",
    PLANS_SUCCESS_DELETE = "PLANS_SUCCESS_DELETE",
    PLANS_DELETE_MODAL_TITLE = "PLANS_DELETE_MODAL_TITLE",
    PLANS_DELETE_MODAL_DESCRIPTION = "PLANS_DELETE_MODAL_DESCRIPTION",

    // CORRECTIVE MEASURE
    CORRECTIVE_MEASSURE_FAILED_DELETE = "CORRECTIVE_MEASSURE_FAILED_DELETE",
    CORRECTIVE_MEASSURE_DELETE_MODAL_DESCRIPTION = "CORRECTIVE_MEASSURE_DELETE_MODAL_DESCRIPTION",
    CORRECTIVE_MEASSURE_DELETE_MODAL_TITLE = "CORRECTIVE_MEASSURE_DELETE_MODAL_TITLE",

    // BASE AUDIT GROUP CHECKLIST ITEM
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_TITLE = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_TITLE",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_DESCRIPTION = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_DESCRIPTION",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_SUCCESS_TOAST = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_SUCCESS_TOAST",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_SAVE = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_SAVE",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_EDIT = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_EDIT",

    // BASE AUDIT GROUP CHECKLIST ITEM TYPE
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_SUCCESS_TOAST = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_SUCCESS_TOAST",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_SAVE_SUCCESS_TOAST = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_SAVE_SUCCESS_TOAST",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_EDIT_SUCCESS_TOAST = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_EDIT_SUCCESS_TOAST",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_TITLE = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_TITLE",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_DESCRIPTION = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_DESCRIPTION",
    CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE = "CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE",
    CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE_TITLE_TOAST = "CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE_TITLE_TOAST",

    // Base Task Modal
    BASE_TASK_COMPLETE_ALL_TASK = "BASE_TASK_COMPLETE_ALL_TASK",
    BASE_TASK_COMPLETE_ALL_REGISTRY = "BASE_TASK_COMPLETE_ALL_REGISTRY",

    // Preview Checklist Modal
    PREVIEW_CHECKLIST_TITLE = "PREVIEW_CHECKLIST_TITLE",

    // Notification Distribution Modal
    NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_TITLE = "NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_TITLE",
    NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_DESCRIPTION = "NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_DESCRIPTION",
    NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_TITLE = "NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_TITLE",
    NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_TITLE_F = "NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_TITLE_F",
    NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_DESCRIPTION = "NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_DESCRIPTION",
    NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_DESCRIPTION_F = "NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_DESCRIPTION_F",
}

export enum AriaLabels {
    AL_RANKING_PILL_COUNTER_ALARM = "AL_RANKING_PILL_COUNTER_ALARM",
    AL_RANKING_PILL_COUNTER_DEFAULT_ALARM = "AL_RANKING_PILL_COUNTER_DEFAULT_ALARM",
    AL_RANKING_PILL_COUNTER_ERROR = "AL_RANKING_PILL_COUNTER_ERROR",
    AL_RANKING_PILL_COUNTER_SUCCESS = "AL_RANKING_PILL_COUNTER_SUCCESS",
    AL_RANKING_PILL_COUNTER_CROSS = "AL_RANKING_PILL_COUNTER_CROSS",
    AL_RANKING_PILL_COUNTER_EQUALS = "AL_RANKING_PILL_COUNTER_EQUALS",
    AL_RANKING_PILL_COUNTER_TOTAL = "AL_RANKING_PILL_COUNTER_TOTAL",
    AL_RANKING_PILL_COUNTERS_TOTAL = "AL_RANKING_PILL_COUNTERS_TOTAL",
    AL_RANKING_PILL_INFO_AVATAR_DESCRIPTION = "AL_RANKING_PILL_INFO_AVATAR_DESCRIPTION",
    AL_COLLAPSABLE_TITLE = "AL_COLLAPSABLE_TITLE",
    AL_COLLAPSABLE_TITLE_ICON = "AL_COLLAPSABLE_TITLE_ICON",

    // AssetMobileBadges
    AL_ISSUE_COUNT = "AL_ISSUE_COUNT",
    AL_ISSUE_COUNT_TOTAL = "AL_ISSUE_COUNT_TOTAL",

    // GenerateQr
    AL_QR_COMPANY_PHOTO = "AL_QR_COMPANY_PHOTO",
    AL_QR_COMPANY_PHOTO_F = "AL_QR_COMPANY_PHOTO_F",
    AL_QR_FORULINK_PHOTO = "AL_QR_FORULINK_PHOTO",

    // Popover
    AL_POPOVER = "AL_POPOVER",

    // Alert pill body
    AL_ALERT_PILL_READED_BADGE = "AL_ALERT_PILL_READED_BADGE",
    AL_ALERT_PILL_SENDED_BADGE = "AL_ALERT_PILL_SENDED_BADGE",

    // EditImage
    AL_EDIT_IMAGE_RECTANGLE = "AL_EDIT_IMAGE_RECTANGLE",
    AL_EDIT_IMAGE_CIRCLE = "AL_EDIT_IMAGE_CIRCLE",
    AL_EDIT_IMAGE_UNDO = "AL_EDIT_IMAGE_UNDO",
    AL_EDIT_IMAGE_MIRROR = "AL_EDIT_IMAGE_MIRROR",
    AL_EDIT_IMAGE_ROTATE = "AL_EDIT_IMAGE_ROTATE",
    AL_EDIT_IMAGE_CROPPER = "AL_EDIT_IMAGE_CROPPER",
    AL_EDIT_IMAGE_FORMS = "AL_EDIT_IMAGE_FORMS",
    AL_EDIT_IMAGE_DRAW = "AL_EDIT_IMAGE_DRAW",
    AL_EDIT_IMAGE_CROP = "AL_EDIT_IMAGE_CROP",
    AL_EDIT_IMAGE_CANCEL_CROP = "AL_EDIT_IMAGE_CANCEL_CROP",

    // Avatar
    AL_AVATAR_PROFILE_PICTURE = "AL_AVATAR_PROFILE_PICTURE",

    // Icon Select
    AL_CHEVRON_UP = "AL_CHEVRON_UP",
    AL_CHEVRON_DOWN = "AL_CHEVRON_DOWN",

    // Star Percentage
    AL_STAR_PERCENTAGE_OVERLAY = "AL_STAR_PERCENTAGE_OVERLAY",

    // StarsReviews
    AL_STAR_VALORATION = "AL_STAR_VALORATION",

    // StarRatingBadge
    AL_USER_VALORATION = "AL_USER_VALORATION",
    AL_STAR_AND_VALORATION_SKELETON = "AL_STAR_AND_VALORATION_SKELETON",
}

export enum TranslationFAQs {
    FAQ_1_TITLE = "FAQ_1_TITLE",
    FAQ_1_DESCRIPTION = "FAQ_1_DESCRIPTION",
    FAQ_2_TITLE = "FAQ_2_TITLE",
    FAQ_2_DESCRIPTION = "FAQ_2_DESCRIPTION",
    FAQ_3_TITLE = "FAQ_3_TITLE",
    FAQ_3_DESCRIPTION = "FAQ_3_DESCRIPTION",
}

export enum IssueTranslation {
    /* Issue Component */
    ISSUE_BODY_DESCRIPTION_TITLE = "ISSUE_BODY_DESCRIPTION_TITLE",
    ISSUE_BODY_CHECKLIST_ITEM_TITLE = "ISSUE_BODY_CHECKLIST_ITEM_TITLE",
    ISSUE_BODY_DESCRIPTION_ASSET_NAME = "ISSUE_BODY_DESCRIPTION_ASSET_NAME",
    ISSUE_BODY_DESCRIPTION_RANGE_REGISTER_NAME = "ISSUE_BODY_DESCRIPTION_RANGE_REGISTER_NAME",
    ISSUE_BODY_DESCRIPTION_ASSET_RANGE = "ISSUE_BODY_DESCRIPTION_ASSET_RANGE",
    ISSUE_BODY_RESPONSIBLE_TITLE = "ISSUE_BODY_RESPONSIBLE_TITLE",
    ISSUE_BODY_ATTACHMENT_TITLE = "ISSUE_BODY_ATTACHMENT_TITLE",
    ISSUE_BODY_ATTACHMENT_ADD_DOCUMENT = "ISSUE_BODY_ATTACHMENT_ADD_DOCUMENT",
    ISSUE_HISTORY_TITLE = "ISSUE_HISTORY_TITLE",
    ISSUE_HISTORY_OPEN = "ISSUE_HISTORY_OPEN",
    ISSUE_HISTORY_CLOSE = "ISSUE_HISTORY_CLOSE",
    ISSUE_HISTORY_REOPEN_ISSUE = "ISSUE_HISTORY_REOPEN_ISSUE",
    ISSUE_HISTORY_CLOSE_ISSUE = "ISSUE_HISTORY_CLOSE_ISSUE",
    ISSUE_HISTORY_COMMENT_PLACEHOLDER = "ISSUE_HISTORY_COMMENT_PLACEHOLDER",
    ISSUE_TITLE_AUDIT = "ISSUE_TITLE_AUDIT",
    ISSUE_TITLE_GENERIC = "ISSUE_TITLE_GENERIC",
    ISSUE_TITLE_TASK = "ISSUE_TITLE_TASK",
    ISSUE_TITLE_ASSET = "ISSUE_TITLE_ASSET",
    ISSUE_BODY_GO_TO_TASK = "ISSUE_BODY_GO_TO_TASK",
    ISSUE_SAVE_CONFIRM_BUTTON = "ISSUE_SAVE_CONFIRM_BUTTON",
    ISSUE_MODAL_AUDIT_CREATE_TASK = "ISSUE_MODAL_AUDIT_CREATE_TASK",

    /* Edit Issue */
    ISSUE_MODAL_AUDIT_TITLE = "ISSUE_MODAL_AUDIT_TITLE",
    ISSUE_MODAL_AUDIT_NON_CONFORMITY = "ISSUE_MODAL_AUDIT_NON_CONFORMITY",
    AUDIT_CODE = "AUDIT_CODE",
    CREATING_AUDIT_TITLE_TOAST = "CREATING_AUDIT_TITLE_TOAST",
    CREATING_AUDIT_DESCRIPTION_TOAST = "CREATING_AUDIT_DESCRIPTION_TOAST",
    DELETE_NON_CONFORMITY_TITLE = "DELETE_NON_CONFORMITY_TITLE",
    DELETE_NON_CONFORMITY_DESCRIPTION = "DELETE_NON_CONFORMITY_DESCRIPTION",
    DELETE_NON_CONFORMITY_AND_TASK_TITLE = "DELETE_NON_CONFORMITY_AND_TASK_TITLE",
    DELETE_NON_CONFORMITY_AND_TASK_DESCRIPTION = "DELETE_NON_CONFORMITY_AND_TASK_DESCRIPTION",
    ISSUE_CLASSIFICATION = "ISSUE_CLASSIFICATION",
    DELETE_ISSUE_CLASSIFICATION_TITLE = "DELETE_ISSUE_CLASSIFICATION_TITLE",
    DELETE_ISSUE_CLASSIFICATION_DESCRIPTION = "DELETE_ISSUE_CLASSIFICATION_DESCRIPTION",
    ADD_NEW_ISSUE_CLASSIFICATION = "ADD_NEW_ISSUE_CLASSIFICATION",
    NEW_ISSUE_CLASSIFICATION = "NEW_ISSUE_CLASSIFICATION",
    EDIT_ISSUE_CLASSIFICATION = "EDIT_ISSUE_CLASSIFICATION",
    ERROR_DELETING_ISSUE_CLASSIFICATION = "ERROR_DELETING_ISSUE_CLASSIFICATION",
}

export enum AnalistTranslation {
    TOTAL_TASKS_NUMBER = "TOTAL_TASKS_NUMBER",
}

export enum AuditGridTranslation {
    EMPTY_LIST_OPEN = "EMPTY_LIST_OPEN",
    EMPTY_LIST_CLOSED = "EMPTY_LIST_CLOSED",
}

export enum AssetTranslations {
    ASSET_INFO_TITLE = "ASSET_INFO_TITLE",
    IS_ASSET_NOT_WORKING = "IS_ASSET_NOT_WORKING",
    ASSET_NOT_WORKING_QUESTION = "ASSET_NOT_WORKING_QUESTION",
    ASSET_IS_OPERATIVE_LABEL = "ASSET_IS_OPERATIVE_LABEL",
    CANNOT_BE_REPAIRED = "CANNOT_BE_REPAIRED",
    ASSET_REPARATION_TITLE = "ASSET_REPARATION_TITLE",
    ASSET_REPARATION_DESCRIPTION = "ASSET_REPARATION_DESCRIPTION",
    ASSET_IS_OPERATIVE_TITLE = "ASSET_IS_OPERATIVE_TITLE",
    ASSET_IS_OPERATIVE_DESCRIPTION = "ASSET_IS_OPERATIVE_DESCRIPTION",
    ASSET_IS_NOT_OPERATIVE_TITLE = "ASSET_IS_NOT_OPERATIVE_TITLE",
    ASSET_IS_NOT_OPERATIVE_DESCRIPTION = "ASSET_IS_NOT_OPERATIVE_DESCRIPTION",
    ASSET_NOT_WORKING = "ASSET_NOT_WORKING",
    ASSET_REPAIRED = "ASSET_REPAIRED",
    ASSET_LINKED_TO_A_SENSOR = "ASSET_LINKED_TO_A_SENSOR",
    ASSET_CONSECUTIVE_ISSUES_LABEL = "ASSET_CONSECUTIVE_ISSUES_LABEL",
    WITHOUT_SENSOR = "WITHOUT_SENSOR",
    WITH_SENSOR = "WITH_SENSOR",
    DEACTIVATED = "DEACTIVATED",
    ASSET_REDIRECT_AUTOMATIC_RECORD_LABEL = "ASSET_REDIRECT_AUTOMATIC_RECORD_LABEL",
    ASSET_SENSOR_TITLE = "ASSET_SENSOR_TITLE",
    ASSET_SENSOR_NO_DATA = "ASSET_SENSOR_NO_DATA",
    ASSET_FORM_REPLACE_SENSOR_ENTRIES_TITLE = "ASSET_FORM_REPLACE_SENSOR_ENTRIES_TITLE",
    ASSET_FORM_REPLACE_SENSOR_ENTRIES_DESCRIPTION = "ASSET_FORM_REPLACE_SENSOR_ENTRIES_DESCRIPTION",
    ASSET_NOT_FOUND = "ASSET_NOT_FOUND",
}

export enum AssetTypeTranslations {
    OTHER_OPTION = "OTHER_OPTION",
    FRIDGE = "FRIDGE",
    FREEZER = "FREEZER",
    SWIMMING_POOL = "SWIMMING_POOL",
    ELEVATOR = "ELEVATOR",
    CORRECT_INCORRECT = "CORRECT_INCORRECT",
}

export enum AssetDataTypeTranslations {
    TEMPERATURE_OPTION = "TEMPERATURE_OPTION",
    PH = "PH",
}

export enum AuditTranslation {
    AUDIT_TITLE = "AUDIT_TITLE",
    AUDIT_NON_CONFORMITY = "AUDIT_NON_CONFORMITY",
    AUDIT_REPORT = "AUDIT_REPORT",
    AUDIT_FINISH_REVIEW = "AUDIT_FINISH_REVIEW",
    AUDIT_CLOSE_AUDIT = "AUDIT_CLOSE_AUDIT",
    AUDIT_IN_PROGRESS = "AUDIT_IN_PROGRESS",
    AUDIT_IN_REVIEW = "AUDIT_IN_REVIEW",
    AUDIT_REVIEWED = "AUDIT_REVIEWED",
    AUDIT_REVIEWED_SINGULAR = "AUDIT_REVIEWED_SINGULAR",
    AUDIT_CLOSED_TAB = "AUDIT_CLOSED_TAB",
    AUDIT_IN_PROGRESS_TAB = "AUDIT_IN_PROGRESS_TAB",
    AUDIT_CLOSED = "AUDIT_CLOSED",
    AUDIT_FAILED_CLOSE = "AUDIT_FAILED_CLOSE",
    AUDIT_FAILED_FINISH_REVIEW = "AUDIT_FAILED_FINISH_REVIEW",
    AUDIT_FINISH_MODAL_TITLE = "AUDIT_FINISH_MODAL_TITLE",
    AUDIT_FINISH_MODAL_DESCRIPTION = "AUDIT_FINISH_MODAL_DESCRIPTION",
    AUDIT_CLOSE_MODAL_TITLE = "AUDIT_CLOSE_MODAL_TITLE",
    AUDIT_CLOSE_MODAL_DESCRIPTION = "AUDIT_CLOSE_MODAL_DESCRIPTION",
    AUDIT_ISSUE_MODAL_TITLE = "AUDIT_ISSUE_MODAL_TITLE",
    AUDIT_ISSUE_DELETE_NON_CONFORMITY_BUTTON = "AUDIT_ISSUE_DELETE_NON_CONFORMITY_BUTTON",
    AUDIT_ISSUE_DELETE_NON_CONFORMITY = "AUDIT_ISSUE_DELETE_NON_CONFORMITY",
    AUDIT_ISSUE_PLACEHOLDER = "AUDIT_ISSUE_PLACEHOLDER",
    AUDIT_ALL_ISSUES_HAS_TO_BE_CLOSED = "AUDIT_ALL_ISSUES_HAS_TO_BE_CLOSED",
    AUDIT_IN_PROGRESS_FILTER_BUTTON = "AUDIT_IN_PROGRESS_FILTER_BUTTON",
    AUDIT_DONE_FILTER_BUTTON = "AUDIT_DONE_FILTER_BUTTON",
    AUDIT_ORDER_BY_AUDITOR_DEFAULT = "AUDIT_ORDER_BY_AUDITOR_DEFAULT",
    AUDIT_ORDER_BY_AUDITOR_ASC = "AUDIT_ORDER_BY_AUDITOR_ASC",
    AUDIT_ORDER_BY_AUDITOR_DESC = "AUDIT_ORDER_BY_AUDITOR_DESC",
}

export enum CloseCompanyTranslations {
    CLOSE_COMPANIES_MODAL_TITLE = "CLOSE_COMPANIES_MODAL_TITLE",
    OPEN_COMPANIES_COLLAPSABLE_TITLE = "OPEN_COMPANIES_COLLAPSABLE_TITLE",
    CLOSE_COMPANIES_COLLAPSABLE_TITLE = "CLOSE_COMPANIES_COLLAPSABLE_TITLE",
    CLOSE_COMPANIES_COLLAPSABLE_LABEL = "CLOSE_COMPANIES_COLLAPSABLE_LABEL",
    OPEN_COMPANIES_COLLAPSABLE_LABEL = "OPEN_COMPANIES_COLLAPSABLE_LABEL",
    ADD_NEW_CLOSE_DATE_RANGE = "ADD_NEW_CLOSE_DATE_RANGE",
    OVERLAPING_DATES_NOT_PERMITTED = "OVERLAPING_DATES_NOT_PERMITTED",
    CLOSE_COMPANY = "CLOSE_COMPANY",
    CLOSE_COMPANY_F = "CLOSE_COMPANY_F",
    COMPANY_CLOSED_DAYS = "COMPANY_CLOSED_DAYS",
}

export enum AnalistRankingTranslations {
    JOB_DESCRIPTION_LABEL = "JOB_DESCRIPTION_LABEL",
}

export enum TaskInstanceTranslations {
    CANNOT_REOPEN_TASK_INSTANCE = "CANNOT_REOPEN_TASK_INSTANCE",
}

export enum TaskTranslations {
    IS_TASK_SPORADIC = "IS_TASK_SPORADIC",
    TASK_TYPE_SPORADIC = "TASK_TYPE_SPORADIC",
    TASK_TYPE_NORMAL = "TASK_TYPE_NORMAL",
    TASK_TYPE_AUDIT = "TASK_TYPE_AUDIT",
    TASK_TYPE_TITLE = "TASK_TYPE_TITLE",
    TASK_ASSIGNED_TITLE = "TASK_ASSIGNED_TITLE",
    TASK_DATE_TITLE = "TASK_DATE_TITLE",
    TASK_ATTATHCMENTS_TITLE = "TASK_ATTATHCMENTS_TITLE",
    TASK_APPCC_LABEL = "TASK_APPCC_LABEL",
    TASK_APPCC_PLACEHOLDER = "TASK_APPCC_PLACEHOLDER",
    TASK_ALL = "TASK_ALL",
    TASK_ALL_COLUMN = "TASK_ALL_COLUMN",
    TASK_SAVE_CONFIRM_BUTTON = "TASK_SAVE_CONFIRM_BUTTON",
    CREATE_TASK_LABEL = "CREATE_TASK_LABEL",
    TASK_TEMPORALITY_PERIODICAL = "TASK_TEMPORALITY_PERIODICAL",
    TASK_TEMPORALITY_ONE_DAY = "TASK_TEMPORALITY_ONE_DAY",
    TASK_TEMPORALITY_SPORADIC = "TASK_TEMPORALITY_SPORADIC",
    TASK_TEMPORALITY_RANGE_DATES = "TASK_TEMPORALITY_RANGE_DATES",
    ADD_TASK_TEMPORALITY_RANGE_DATES = "ADD_TASK_TEMPORALITY_RANGE_DATES",
    DATE_OVERLAPING = "DATE_OVERLAPING",
    MIN_ALLOWED_PHOTOS_LABEL = "MIN_ALLOWED_PHOTOS_LABEL",
}

export enum TaskTemporalityReadOnlyTranslations {
    TASK_TEMPORALITY_PERIODICAL_DAY_SINGULAR = "TASK_TEMPORALITY_PERIODICAL_DAY_SINGULAR",
    TASK_TEMPORALITY_PERIODICAL_WEEK_SINGULAR = "TASK_TEMPORALITY_PERIODICAL_WEEK_SINGULAR",
    TASK_TEMPORALITY_PERIODICAL_MONTH_SINGULAR = "TASK_TEMPORALITY_PERIODICAL_MONTH_SINGULAR",
    TASK_TEMPORALITY_PERIODICAL_YEAR_SINGULAR = "TASK_TEMPORALITY_PERIODICAL_YEAR_SINGULAR",
    TASK_TEMPORALITY_PERIODICAL_DAY_PLURAL = "TASK_TEMPORALITY_PERIODICAL_DAY_PLURAL",
    TASK_TEMPORALITY_PERIODICAL_WEEK_PLURAL = "TASK_TEMPORALITY_PERIODICAL_WEEK_PLURAL",
    TASK_TEMPORALITY_PERIODICAL_MONTH_PLURAL = "TASK_TEMPORALITY_PERIODICAL_MONTH_PLURAL",
    TASK_TEMPORALITY_PERIODICAL_YEAR_PLURAL = "TASK_TEMPORALITY_PERIODICAL_YEAR_PLURAL",
    TASK_TEMPORALITY_PERIODICAL_NEVER_ENDS = "TASK_TEMPORALITY_PERIODICAL_NEVER_ENDS",
    TASK_TEMPORALITY_PERIODICAL_SPECIFIC_DAY = "TASK_TEMPORALITY_PERIODICAL_SPECIFIC_DAY",
    TASK_TEMPORALITY_PERIODICAL_TIMES = "TASK_TEMPORALITY_PERIODICAL_TIMES",
    TASK_TEMPORALITY_HOURS_TITLE = "TASK_TEMPORALITY_HOURS_TITLE",
    TASK_TEMPORALITY_DATE_RANGE_TITLE = "TASK_TEMPORALITY_DATE_RANGE_TITLE",
}

export enum BaseAuditGroupCheckListItemTranslations {
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_ID_LABEL = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_ID_LABEL",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL",
    TYPE_LABEL = "TYPE_LABEL",
    PONDERATION_LEVEL_LABEL = "PONDERATION_LEVEL_LABEL",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_GRID_TITLE = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_GRID_TITLE",
    CELL_BUTTONS_LABEL = "CELL_BUTTONS_LABEL",
    NAME_IS_REQUIRED = "NAME_IS_REQUIRED",
    FK_BASEAUDITGROUPCHECKLISTITEMTYPE_IS_REQUIRED = "FK_BASEAUDITGROUPCHECKLISTITEMTYPE_IS_REQUIRED",
    PONDERATIONLEVEL_IS_REQUIRED = "PONDERATIONLEVEL_IS_REQUIRED",
    PONDERATION_RANGE_ERROR = "PONDERATION_RANGE_ERROR",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL",
}

export enum BaseAuditGroupCheckListItemTypeTranslations {
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_ADD_TITLE = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_ADD_TITLE",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_LABEL = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_LABEL",
    NAME_IS_REQUIRED = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_IS_REQUIRED",
    BASE_AUDIT_GROUP_CHECKLIST_ITEM_ALL_TYPES = "BASE_AUDIT_GROUP_CHECKLIST_ITEM_ALL_TYPES",
}

export enum ScanQRTranslations {
    // CUSTOM QR SCANNER
    QR_SCANNER_SCANNING = "QR_SCANNER_SCANNING",
    QR_SCANNER_START_SCANNING = "QR_SCANNER_START_SCANNING",
    QR_SCANNER_STOP_SCANNING = "QR_SCANNER_STOP_SCANNING",
    QR_SCANNER_MODAL_TITLE = "QR_SCANNER_MODAL_TITLE",
    QR_SCANNER_MODAL_ALTERNATIVE_TITLE = "QR_SCANNER_MODAL_ALTERNATIVE_TITLE",
    QR_SCANNER_MODAL_SCAN = "QR_SCANNER_MODAL_SCAN",
    QR_SCANNER_MODAL_MANUAL_SCAN = "QR_SCANNER_MODAL_MANUAL_SCAN",
    QR_SCANNER_MODAL_CLOSE_SCANNER = "QR_SCANNER_MODAL_CLOSE_SCANNER",
}

export enum AuditGroupCheckListItemTranslations {
    AUDIT_GROUP_CHECKLIST_ITEM_PONDERATION_LABEL = "AUDIT_GROUP_CHECKLIST_ITEM_PONDERATION_LABEL",
    AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_TITLE_LABEL = "AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_TITLE_LABEL",
    AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_DESCRIPTION_LABEL = "AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_DESCRIPTION_LABEL",
}

export enum AuditInstanceTranslations {
    AUDIT_INSTANCE_REPORT_ERROR = "AUDIT_INSTANCE_REPORT_ERROR",
    AUDIT_INSTANCE_REPORT_BARCHART_TITLE = "AUDIT_INSTANCE_REPORT_BARCHART_TITLE",
    EMPTY_BAR_CHART_TITLE = "EMPTY_BAR_CHART_TITLE",
    EMPTY_BAR_CHART_DESCRIPTION = "EMPTY_BAR_CHART_DESCRIPTION",
}

export enum AuditGroupCheckListInstanceTranslations {
    AUDIT_GROUP_CHECKLIST_INSTANCE_NAME = "AUDIT_GROUP_CHECKLIST_INSTANCE_NAME",
    AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_TITLE = "AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_TITLE",
    AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION = "AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION",
    AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION_FILTER = "AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION_FILTER",
    AUDIT_GROUP_CHECKLIST_INSTANCE_GRADE = "AUDIT_GROUP_CHECKLIST_INSTANCE_GRADE",
    AUDIT_GROUP_CHECKLIST_INSTANCE_VALORATION = "AUDIT_GROUP_CHECKLIST_INSTANCE_VALORATION",
    AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE = "AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE",
    AUDIT_GROUP_CHECKLIST_ISSUE_COUNT = "AUDIT_GROUP_CHECKLIST_ISSUE_COUNT",
    AUDIT_GROUP_CHECKLIST_ISSUE_COUNT_SMALL = "AUDIT_GROUP_CHECKLIST_ISSUE_COUNT_SMALL",
}

export enum ReviewTaskTranslations {
    ALL_DATA_TO_REPORT = "ALL_DATA_TO_REPORT",
    NO_DATA_TO_REPORT = "NO_DATA_TO_REPORT",
    WITH_DATA_TO_REPORT = "WITH_DATA_TO_REPORT",
    WITH_CHECKLIST_TO_REPORT = "WITH_CHECKLIST_TO_REPORT",
    WITH_ASSET_TO_REPORT = "WITH_ASSET_TO_REPORT",
    LINKED_DATA = "LINKED_DATA",
}

export enum NewUserTabsTranslation {
    RESET_PASSWORD_USER = "RESET_PASSWORD_USER",
    USER_DATA_TITLE = "USER_DATA_TITLE",
    USER_PERMISSIONS_TITLE = "USER_PERMISSIONS_TITLE",
    ASSIGNED_COMPANIES_TITLE = "ASSIGNED_COMPANIES_TITLE",
}

export enum OnBoardingStepsTranslation {
    ONBOARDING_INITIAL_CONFIG = "ONBOARDING_INITIAL_CONFIG",
    ONBOARDING_CREATE_COMPANY = "ONBOARDING_CREATE_COMPANY",
    ONBOARDING_CREATE_ROLES = "ONBOARDING_CREATE_ROLES",
    ONBOARDING_CREATE_USERS = "ONBOARDING_CREATE_USERS",
    ONBOARDING_ASSIGN_USERS_TO_TEAMS = "ONBOARDING_ASSIGN_USERS_TO_TEAMS",
    ONBOARDING_APPCC_CONFIG = "ONBOARDING_APPCC_CONFIG",
    ONBOARDING_CREATE_PLANS = "ONBOARDING_CREATE_PLANS",
    ONBOARDING_CREATE_CORRECTIVE_MEASURES = "ONBOARDING_CREATE_CORRECTIVE_MEASURES",
    ONBOARDING_CREATE_ASSETS = "ONBOARDING_CREATE_ASSETS",
    ONBOARDING_BASETASK_CONFIG = "ONBOARDING_BASETASK_CONFIG",
    ONBOARDING_CREATE_BASETASK = "ONBOARDING_CREATE_BASETASK",
    ONBOARDING_CREATE_APPCC_BASETASK = "ONBOARDING_CREATE_APPCC_BASETASK",
    ONBOARDING_TASK_CONFIG = "ONBOARDING_TASK_CONFIG",
    ONBOARDING_CREATE_QR = "ONBOARDING_CREATE_QR",
    ONBOARDING_CREATE_TASK = "ONBOARDING_CREATE_TASK",
    ONBOARDING_CREATE_APPCC_TASK = "ONBOARDING_CREATE_APPCC_TASK",
    ONBOARDING_AUDIT_CONFIG = "ONBOARDING_AUDIT_CONFIG",
    ONBOARDING_CREATE_BASE_AUDIT_ITEMS = "ONBOARDING_CREATE_BASE_AUDIT_ITEMS",
    ONBOARDING_CREATE_AUDIT_TEMPLATE = "ONBOARDING_CREATE_AUDIT_TEMPLATE",
    ONBOARDING_CREATE_DISTRIBUTION_LIST = "ONBOARDING_CREATE_DISTRIBUTION_LIST",
    ONBOARDING_TITLE = "ONBOARDING_TITLE",
    ONBOARDING_UNLOCK_TITLE = "ONBOARDING_UNLOCK_TITLE",
    ONBOARDING_COMPLETE_TITLE = "ONBOARDING_COMPLETE_TITLE",
    ONBOARDING_COMPLETE = "ONBOARDING_COMPLETE",
    ONBOARDING_COMPLETE_SUBSTEP = "ONBOARDING_COMPLETE_SUBSTEP",
    ONBOARDING_COMPLETE_STEP = "ONBOARDING_COMPLETE_STEP",
    ONBOARDING_REVIEW_STEP = "ONBOARDING_REVIEW_STEP",
    ONBOARDING_UNLOCK_STEP = "ONBOARDING_UNLOCK_STEP",
    ONBOARDING_COMPLETE_DESCRIPTION = "ONBOARDING_COMPLETE_DESCRIPTION",
    ONBOARDING_UNLOCK = "ONBOARDING_UNLOCK",
    ONBOARDING_UNLOCK_DESCRIPTION = "ONBOARDING_UNLOCK_DESCRIPTION",
    ONBOARDING_COMPLETE_SUBSTEP_DESCRIPTION = "ONBOARDING_COMPLETE_SUBSTEP_DESCRIPTION",
    ONBOARDING_REVIEW_CORRECT = "ONBOARDING_REVIEW_CORRECT",
    ONBOARDING_REVIEW_INCORRECT = "ONBOARDING_REVIEW_INCORRECT",
    ONBOARDING_REVIEW_SEND_REVIEW = "ONBOARDING_REVIEW_SEND_REVIEW",
    ONBOARDING_REVIEW_SEND_REVIEW_TITLE = "ONBOARDING_REVIEW_SEND_REVIEW_TITLE",
    ONBOARDING_REVIEW_SEND_REVIEW_OPTION = "ONBOARDING_REVIEW_SEND_REVIEW_OPTION",
    ONBOARDING_REVIEW_SEND_REVIEW_COMMENT = "ONBOARDING_REVIEW_SEND_REVIEW_COMMENT",
    ONBOARDING_STATUS_MODAL_STEP_BLOCKED = "ONBOARDING_STATUS_MODAL_STEP_BLOCKED",
    ONBOARDING_STATUS_MODAL_STEP_DONE = "ONBOARDING_STATUS_MODAL_STEP_DONE",
    ONBOARDING_STATUS_MODAL_STEP_IN_PROGRESS = "ONBOARDING_STATUS_MODAL_STEP_IN_PROGRESS",
    ONBOARDING_STATUS_MODAL_STEP_REVIEW = "ONBOARDING_STATUS_MODAL_STEP_REVIEW",
    ONBOARDING_STATUS_MODAL_SUBSTEP_BLOCKED = "ONBOARDING_STATUS_MODAL_SUBSTEP_BLOCKED",
    ONBOARDING_STATUS_MODAL_SUBSTEP_DONE = "ONBOARDING_STATUS_MODAL_SUBSTEP_DONE",
    ONBOARDING_STATUS_MODAL_SUBSTEP_IN_PROGRESS = "ONBOARDING_STATUS_MODAL_SUBSTEP_IN_PROGRESS",
    ONBOARDING_STATUS_MODAL_SUBSTEP_REVIEW = "ONBOARDING_STATUS_MODAL_SUBSTEP_REVIEW",
    ONBOARDING_STATUS_BLOCKED = "ONBOARDING_STATUS_BLOCkED",
    ONBOARDING_STATUS_IN_PROGRESS = "ONBOARDING_STATUS_IN_PROGRESS",
    ONBOARDING_STATUS_REVIEW = "ONBOARDING_STATUS_REVIEW",
    ONBOARDING_STATUS_DONE = "ONBOARDING_STATUS_DONE",
    ONBOARDING_FINISH_TITLE = "ONBOARDING_FINISH_TITLE",
    ONBOARDING_FINISH_DESCRIPTION = "ONBOARDING_FINISH_DESCRIPTION",
    ONBOARDING_SENDING_EMAIL_TITLE = "ONBOARDING_SENDING_EMAIL_TITLE",
    ONBOARDING_EMAIL_SENDED_TITLE = "ONBOARDING_EMAIL_SENDED_TITLE",
    ONBOARDING_RESET = "ONBOARDING_RESET",
    ONBOARDING_RESET_MODAL_TITLE = "ONBOARDING_RESET_MODAL_TITLE",
    ONBOARDING_RESET_MODAL_DESCRIPTION = "ONBOARDING_RESET_MODAL_DESCRIPTION",
    ONBOARDING_RESET_TOAST_SUCCESS_TITLE = "ONBOARDING_RESET_TOAST_SUCCESS_TITLE",
}

export enum AutomaticRecordTranslations {
    AUTOMATIC_RECORD_ASSET_LABEL = "AUTOMATIC_RECORD_ASSET_LABEL",
    SENSOR_SERIAL_NUMBER_LABEL = "SENSOR_SERIAL_NUMBER_LABEL",
    GATEWAY_SERIAL_NUMBER_SMALL_LABEL = "GATEWAY_SERIAL_NUMBER_SMALL_LABEL",
    SENSOR_SERIAL_NUMBER_SMALL_LABEL = "SENSOR_SERIAL_NUMBER_SMALL_LABEL",
    TEMP_LABEL = "TEMP_LABEL",
    BATTERY_LEVEL_LABEL = "BATTERY_LEVEL_LABEL",
    SIGNAL_LEVEL_LABEL = "SIGNAL_LEVEL_LABEL",
    DATE_TIME_ENTRY_LABEL = "DATE_TIME_ENTRY_LABEL",
    BATTERY_LEVEL_LOW_LABEL = "BATTERY_LEVEL_LOW_LABEL",
    BATTERY_LEVEL_MEDIUM_LABEL = "BATTERY_LEVEL_MEDIUM_LABEL",
    BATTERY_LEVEL_HIGH_LABEL = "BATTERY_LEVEL_HIGH_LABEL",
    SIGNAL_LEVEL_LOW_LABEL = "SIGNAL_LEVEL_LOW_LABEL",
    SIGNAL_LEVEL_MEDIUM_LABEL = "SIGNAL_LEVEL_MEDIUM_LABEL",
    SIGNAL_LEVEL_HIGH_LABEL = "SIGNAL_LEVEL_HIGH_LABEL",
    REGISTRY_NUMBER = "REGISTRY_NUMBER",
}

export enum BaseTaskTranslations {
    // BASE TASK
    BASE_TASK_ID = "BASE_TASK_ID",
    BASE_TASK_NAME = "BASE_TASK_NAME",
    BASE_TASK_DESCRIPTION = "BASE_TASK_DESCRIPTION",
    BASE_TASKS = "BASE_TASKS",
    BASE_TASKS_APPCC = "BASE_TASKS_APPCC",
    BASE_TASK_TYPE_LABEL = "BASE_TASK_TYPE_LABEL",
    BASE_TASKS_PLAN_APPCC_LABEL = "BASE_TASKS_PLAN_APPCC_LABEL",
    NEW_APPCC_BASE_TASK = "NEW_APPCC_BASE_TASK",
    NEW_BASE_TASK = "NEW_BASE_TASK",
    EDIT_APPCC_BASE_TASK = "EDIT_APPCC_BASE_TASK",
    DUPLICATE_APPCC_BASE_TASK = "DUPLICATE_APPCC_BASE_TASK",
    EDIT_BASE_TASK = "EDIT_BASE_TASK",
    DUPLICATE_BASE_TASK = "DUPLICATE_BASE_TASK",
    MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_TITLE = "MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_TITLE",
    MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_BODY = "MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_BODY",
    BASE_TASK_NOT_ALLOWED_DATA = "BASE_TASK_NOT_ALLOWED_DATA",
    BASE_TASK_ADD_CHECKLIST = "BASE_TASK_ADD_CHECKLIST",
    BASE_TASK_ADD_DYNAMIC_FIELDS = "BASE_TASK_ADD_DYNAMIC_FIELDS",
    BASE_TASK_ALLOW_ANY_DATA = "BASE_TASK_ALLOW_ANY_DATA",
    BASE_TASK_ASSETS_IS_REQUIRED = "BASE_TASK_ASSETS_IS_REQUIRED",
    BASE_TASK_RANGE_REGISTER_IS_REQUIRED = "BASE_TASK_RANGE_REGISTER_IS_REQUIRED",
    BASE_TASKS_REPORT_TYPE_LABEL = "BASE_TASKS_REPORT_TYPE_LABEL",
    BASE_TASKS_TYPE_LABEL = "BASE_TASKS_TYPE_LABEL",
    BASE_TASK_ALL_REPORT_TYPES_LABEL = "BASE_TASK_ALL_REPORT_TYPES_LABEL",
    BASE_TASK_NONE_REPORT_TYPES_LABEL = "BASE_TASK_NONE_REPORT_TYPES_LABEL",
    BASE_TASK_DYNAMIC_FIELD_REPORT_TYPES_LABEL = "BASE_TASK_DYNAMIC_FIELD_REPORT_TYPES_LABEL",
    BASE_TASK_CHECKLIST_REPORT_TYPES_LABEL = "BASE_TASK_CHECKLIST_REPORT_TYPES_LABEL",
    BASE_TASK_ASSET_REPORT_TYPES_LABEL = "BASE_TASK_ASSET_REPORT_TYPES_LABEL",
    BASE_TASKS_IS_PHOTO_REQUIRED_LABEL = "BASE_TASKS_IS_PHOTO_REQUIRED_LABEL",
    BASE_TASK_ASSOCIATED_TASKS_COUNT_LABEL = "BASE_TASK_ASSOCIATED_TASKS_COUNT_LABEL",
    BASE_TASK_SEARCHING_TASKS = "BASE_TASK_SEARCHING_TASKS",
    BASE_TASK_SEARCHING_REGISTRIES = "BASE_TASK_SEARCHING_REGISTRIES",
    BASE_TASK_SIMILAR_TASKS = "BASE_TASK_SIMILAR_TASKS",
    BASE_TASK_SIMILAR_REGISTRIES = "BASE_TASK_SIMILAR_REGISTRIES",
    BASE_TASK_SAME_TITLE_TASK_TITLE = "BASE_TASK_SAME_TITLE_TASK_TITLE",
    BASE_TASK_SAME_TITLE_REGISTRY_TITLE = "BASE_TASK_SAME_TITLE_REGISTRY_TITLE",
    BASE_TASK_SAME_TITLE_TASK_DESCRIPTION = "BASE_TASK_SAME_TITLE_TASK_DESCRIPTION",
    BASE_TASK_SAME_TITLE_REGISTRY_DESCRIPTION = "BASE_TASK_SAME_TITLE_REGISTRY_DESCRIPTION",
    BASE_TASK_START_DATE_LABEL = "BASE_TASK_START_DATE_LABEL",
    BASE_TASK_HINT_TITLE = "BASE_TASK_HINT_TITLE",
    BASE_TASK_HINT_EXAMPLE = "BASE_TASK_HINT_EXAMPLE",
}

export enum AuditFilterTranslations {
    AUDIT_FILTER_START_DATE = "AUDIT_FILTER_START_DATE",
    AUDIT_FILTER_CLOSED_DATE = "AUDIT_FILTER_CLOSED_DATE",
}

export enum ChatBotGPTTranslations {
    CHAT_BOT_GTP_LOADING_MESSAGE = "CHAT_BOT_GTP_LOADING_MESSAGE",
    CHAT_BOT_GTP_ERROR_MESSAGE = "CHAT_BOT_GTP_ERROR_MESSAGE",
    CHAT_BOT_GTP_INPUT_PLACEHOLDER = "CHAT_BOT_GTP_INPUT_PLACEHOLDER",
    CHAT_BOT_GTP_IA_PRESENTATION_MESSAGE = "CHAT_BOT_GTP_IA_PRESENTATION_MESSAGE",
    CHAT_BOT_GTP_USER_OPTION = "CHAT_BOT_GTP_USER_OPTION",
    CHAT_BOT_GTP_TASK_OPTION = "CHAT_BOT_GTP_TASK_OPTION",
    CHAT_BOT_GTP_APPCC_OPTION = "CHAT_BOT_GTP_APPCC_OPTION",
    CHAT_BOT_GTP_WORKER_TEXT = "CHAT_BOT_GTP_WORKER_TEXT",
}

export enum CompanyTranslations {
    EDIT_COMPANY_DETAIL_TITLE = "EDIT_COMPANY_DETAIL_TITLE",
    EDIT_COMPANY_DETAIL_TITLE_F = "EDIT_COMPANY_DETAIL_TITLE_F",
    EDIT_COMPANY_OPTIONS_TITLE = "EDIT_COMPANY_OPTIONS_TITLE",
    COMPANY_STATUS = "COMPANY_STATUS",
}

export enum RoleCollapsableTranslations {
    ROLE_COLLAPSABLE_MARK_ALL = "ROLE_COLLAPSABLE_MARK_ALL",
}

export enum SensorModalTranslations {
    SENSOR_NOT_LINKED_TITLE = "SENSOR_NOT_LINKED_TITLE",
    SENSOR_NOT_LINKED_PLACEHOLDER_TITLE = "SENSOR_NOT_LINKED_PLACEHOLDER_TITLE",
    SENSOR_NOT_LINKED_DESCRIPTION = "SENSOR_NOT_LINKED_DESCRIPTION",
    SENSOR_NOT_LINKED_CREATE_ASSET = "SENSOR_NOT_LINKED_CREATE_ASSET",
    SENSOR_NOT_LINKED_REPLACE = "SENSOR_NOT_LINKED_REPLACE",
    SENSOR_TOAST_LOADING = "SENSOR_TOAST_LOADING",
    SENSOR_LINKED_TITLE = "SENSOR_LINKED_TITLE",
    SENSOR_LINKED_REPLACE = "SENSOR_LINKED_REPLACE",
    SENSOR_LINKED_DELETE_ASSET = "SENSOR_LINKED_DELETE_ASSET",
    SENSOR_LINKED_ASSET_NAME = "SENSOR_LINKED_ASSET_NAME",
    SENSOR_LINKED_ASSET_DESCRIPTION = "SENSOR_LINKED_ASSET_DESCRIPTION",
    SENSOR_LINKED_ASSET_TYPE = "SENSOR_LINKED_ASSET_TYPE",
    SENSOR_LINKED_ASSET_PLAN = "SENSOR_LINKED_ASSET_PLAN",
    SENSOR_LINKED_ASSET_NOT_WORKING = "SENSOR_LINKED_ASSET_NOT_WORKING",
    SENSOR_LINKED_TO_SENSOR_LINKED_TITLE = "SENSOR_LINKED_TO_SENSOR_LINKED_TITLE",
    SENSOR_LINKED_TO_SENSOR_NOT_LINKED_TITLE = "SENSOR_LINKED_TO_SENSOR_NOT_LINKED_TITLE",
    SENSOR_LINKED_TO_SENSOR_LINKED_DESCRIPTION = "SENSOR_LINKED_TO_SENSOR_LINKED_DESCRIPTION",
    SENSOR_LINKED_TO_SENSOR_NOT_LINKED_DESCRIPTION = "SENSOR_LINKED_TO_SENSOR_NOT_LINKED_DESCRIPTION",
    SENSOR_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON = "SENSOR_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON",
    SENSOR_LINKED_TO_SENSOR_NOT_LINKED_CONFIRM_BUTTON = "SENSOR_LINKED_TO_SENSOR_NOT_LINKED_CONFIRM_BUTTON",
    SENSOR_LINKED_TO_SENSOR_LINKED_SUCCESS_REPLACE_TITLE = "SENSOR_LINKED_TO_SENSOR_LINKED_SUCCESS_REPLACE_TITLE",
    SENSOR_LINKED_TO_SENSOR_NOT_LINKED_SUCCESS_REPLACE_TITLE = "SENSOR_LINKED_TO_SENSOR_NOT_LINKED_SUCCESS_REPLACE_TITLE",
    SENSOR_SAME_QR_ERROR_TITLE = "SENSOR_SAME_QR_ERROR_TITLE",
    SENSOR_QR_SCANNER_TITLE = "SENSOR_QR_SCANNER_TITLE",
    SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_TITLE = "SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_TITLE",
    SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_DESCRIPTION = "SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_DESCRIPTION",
    SENSOR_NOT_LINKED_QR_SCANNER_TITLE = "SENSOR_NOT_LINKED_QR_SCANNER_TITLE",
    SENSOR_NOT_LINKED_TO_SENSOR_LINKED_TITLE = "SENSOR_NOT_LINKED_TO_SENSOR_LINKED_TITLE",
    SENSOR_NOT_LINKED_TO_SENSOR_LINKED_DESCRIPTION = "SENSOR_NOT_LINKED_TO_SENSOR_LINKED_DESCRIPTION",
    SENSOR_NOT_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON = "SENSOR_NOT_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON",
}

export enum NotificationDistributionListTranslations {
    // Key translations
    DISTRIBUTION_LIST_SPORADIC_TASK = "DISTRIBUTION_LIST_SPORADIC_TASK",
    DISTRIBUTION_LIST_AUDIT_REPORT = "DISTRIBUTION_LIST_AUDIT_REPORT",
    DISTRIBUTION_LIST_PATCH_NOTES = "DISTRIBUTION_LIST_PATCH_NOTES",
    DISTRIBUTION_LIST_ISSUE_DYNAMIC_FIELD = "DISTRIBUTION_LIST_ISSUE_DYNAMIC_FIELD",

    NOTIFICATION_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_COMPANY_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_COMPANY_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_USER_COUNT_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_USER_COUNT_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_EMAIL_COUNT_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_EMAIL_COUNT_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_SAVE_SUCCESS = "NOTIFICATION_DISTRIBUTION_LIST_SAVE_SUCCESS",
    NOTIFICATION_DISTRIBUTION_LIST_EDIT_SUCCESS = "NOTIFICATION_DISTRIBUTION_LIST_EDIT_SUCCESS",
    NOTIFICATION_DISTRIBUTION_LIST_TYPE_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_TYPE_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE = "NOTIFICATION_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE",
    NOTIFICATION_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE = "NOTIFICATION_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE",
    NOTIFICATION_DISTRIBUTION_LIST_DELETE_TITLE_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_DELETE_TITLE_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_SUCCESS_DELETE = "NOTIFICATION_DISTRIBUTION_LIST_SUCCESS_DELETE",
    NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_USERS = "NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_USERS",
    NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL = "NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL",
    NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_LABEL = "NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_LABEL",
    NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_PLACEHOLDER = "NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_PLACEHOLDER",

    // FILTER
    ALL_DISTRIBUTION_LISTS = "ALL_DISTRIBUTION_LISTS",
}

export enum TaskPillTranslations {
    DIFFERENCE_BETWEEN_TWO_DATES = "DIFFERENCE_BETWEEN_TWO_DATES",
    DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR = "DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR",
    DIFFERENCE_BETWEEN_TWO_DATES_EARLY = "DIFFERENCE_BETWEEN_TWO_DATES_EARLY",
    DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_EARLY = "DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_EARLY",
    DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_EARLY_SINGULAR = "DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_EARLY_SINGULAR",
    DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_EARLY_PLURAL = "DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_EARLY_PLURAL",
    DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_SINGULAR = "DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_SINGULAR",
    DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_PLURAL = "DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_PLURAL",
    TASK_PILL_DATE_RANGE_PENDING_BADGE = "TASK_PILL_DATE_RANGE_PENDING_BADGE",
}

export enum TranscriptionTranslations {
    BROWSER_NOT_COMPATIBLE = "BROWSER_NOT_COMPATIBLE",
    ERROR_STARTING_TRANSCRIPTING = "ERROR_STARTING_TRANSCRIPTING",
}

export enum TaskHistoryTranslations {
    TASK_HISTORY_LIST = "TASK_HISTORY_LIST",
    TASK_HISTORY_TABLE = "TASK_HISTORY_TABLE",
}

export enum IconTitleTranslations {
    IT_STAR = "IT_STAR",
}

export enum AlertModalTranslations {
    QR_LABEL = "QR_LABEL",
    SELECT_HOUR_QR = "SELECT_HOUR_QR",
}

export enum DragFileTranslations {
    INVALID_FILE_TYPE = "INVALID_FILE_TYPE",
}

export enum PatchNotesTranslations {
    PATCH_NOTES_VERSION_LABEL = "PATCH_NOTES_VERSION_LABEL",
    PATCH_NOTES_TITLE_LABEL = "PATCH_NOTES_TITLE_LABEL",
    PATCH_NOTES_PUBLISH_DATE_LABEL = "PATCH_NOTES_PUBLISH_DATE_LABEL",
    PATCH_NOTES_CUSTOMERS_COUNT_LABEL = "PATCH_NOTES_CUSTOMERS_COUNT_LABEL",
    PATCH_NOTE_DELETE_TITLE_LABEL = "PATCH_NOTE_DELETE_TITLE_LABEL",
    PATCH_NOTE_DELETE_DESCRIPTION_LABEL = "PATCH_NOTE_DELETE_DESCRIPTION_LABEL",
    PATCH_NOTES_CREATE_MODAL_TITLE = "PATCH_NOTES_CREATE_MODAL_TITLE",
    PATCH_NOTE_CREATED_SUCCESSFULLY = "PATCH_NOTE_CREATED_SUCCESSFULLY",
    PATCH_NOTES_EDIT_MODAL_TITLE = "PATCH_NOTES_EDIT_MODAL_TITLE",
    PATCH_NOTE_EDITED_SUCCESSFULLY = "PATCH_NOTE_EDITED_SUCCESSFULLY",
    PATCH_NOTE_CUSTOMER_INSTANCES_LABEL = "PATCH_NOTE_CUSTOMER_INSTANCES_LABEL",
    PATCH_NOTES_NOTE_LABEL = "PATCH_NOTES_NOTE_LABEL",
    PATCH_NOTE_CANCEL_CHANGES_TITLE_LABEL = "PATCH_NOTE_CANCEL_CHANGES_TITLE_LABEL",
    PATCH_NOTE_CANCEL_CHANGES_DESCRIPTION_LABEL = "PATCH_NOTE_CANCEL_CHANGES_DESCRIPTION_LABEL",
    PATCH_NOTES_SEND_SUCCESSFULLY = "PATCH_NOTES_SEND_SUCCESSFULLY",
    PATCH_NOTE_SEND_TITLE_LABEL = "PATCH_NOTE_SEND_TITLE_LABEL",
    PATCH_NOTE_SEND_DESCRIPTION_LABEL = "PATCH_NOTE_SEND_DESCRIPTION_LABEL",
    PATCH_NOTES_ADD_NOTE_LABEL = "PATCH_NOTES_ADD_NOTE_LABEL",
    SEND_PATCH_NOTES_SELECTOR_USER_TYPE_LABEL = "SEND_PATCH_NOTES_SELECTOR_USER_TYPE_LABEL",
}

export enum WorkingPositionTranslations {
    WORKING_POSITION_TASK_COUNT = "WORKING_POSITION_TASK_COUNT",
    WORKING_POSITION_APPCC_COUNT = "WORKING_POSITION_APPCC_COUNT",
}

export enum DepartmentTranslations {
    DEPARTMENT_DELETE_MODAL_TITLE = "DEPARTMENT_DELETE_MODAL_TITLE",
    CLOSE_DEPARTMENT = "CLOSE_DEPARTMENT",
    CLOSED_DEPARTMENT_SUCCESSFULLY = "CLOSED_DEPARTMENT_SUCCESSFULLY",
    CLOSE_DEPARTMENT_DATES = "CLOSE_DEPARTMENT_DATES",
    DEPARTMENT_STATUS = "DEPARTMENT_STATUS",
    DEPARTMENTS_TITLE = "DEPARTMENTS_TITLE",
    CLOSED_DEPARTMENT_OPEN_TITLE = "CLOSED_DEPARTMENT_OPEN_TITLE",
    CLOSED_DEPARTMENT_CLOSED_TITLE = "CLOSED_DEPARTMENT_CLOSED_TITLE",
    CLOSED_DEPARTMENT_OPEN_DESCRIPTION = "CLOSED_DEPARTMENT_OPEN_DESCRIPTION",
    CLOSED_DEPARTMENT_CLOSED_DESCRIPTION = "CLOSED_DEPARTMENT_CLOSED_DESCRIPTION",
    OPEN_DEPARTMENT_SUCCESSFULLY = "OPEN_DEPARTMENT_SUCCESSFULLY",
    CLOSED_DEPARTMENT_ERROR_NO_CLOSE_DATE = "CLOSED_DEPARTMENT_ERROR_NO_CLOSE_DATE",
    CLOSED_DEPARTMENT_OPEN = "CLOSED_DEPARTMENT_OPEN",
}

export enum NotificationsTranslations {
    NOTIFICATION_WHICH_COMPANY_LABEL = "NOTIFICATION_WHICH_COMPANY_LABEL",
    NOTIFICATION_ASSIGN_USER = "NOTIFICATION_ASSIGN_USER",
    NOTIFICATION_ASSIGN_DEPARTMENT = "NOTIFICATION_ASSIGN_DEPARTMENT",
    NOTIFICATION_ASSIGN_COMPANY = "NOTIFICATION_ASSIGN_COMPANY",
    NOTIFICATION_ASSIGN_QR = "NOTIFICATION_ASSIGN_QR",
}

export enum UserTypeTranslations {
    WORKER = "WORKER",
    SUPERVISOR = "SUPERVISOR",
    ANALIST = "ANALIST",
    ADMIN = "ADMIN",
}

export enum PendingTasksCompanyDepartmentTranslations {
    PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_TITLE = "PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_TITLE",
    PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION = "PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION",
    PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION_F = "PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION_F",
    PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE = "PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE",
    PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE_F = "PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE_F",
    PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED = "PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED",
    PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED_F = "PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED_F",
    CONFIRM_MODAL_NOT_CLOSED_TITLE = "CONFIRM_MODAL_NOT_CLOSED_TITLE",
    CONFIRM_MODAL_NOT_CLOSED_TITLE_F = "CONFIRM_MODAL_NOT_CLOSED_TITLE_F",
    CONFIRM_MODAL_CLOSED_TITLE = "CONFIRM_MODAL_CLOSED_TITLE",
    CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION = "CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION",
    CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION_F = "CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION_F",
    CONFIRM_MODAL_CLOSED_DESCRIPTION = "CONFIRM_MODAL_CLOSED_DESCRIPTION",
    CONFIRM_MODAL_CLOSED_DESCRIPTION_F = "CONFIRM_MODAL_CLOSED_DESCRIPTION_F",
    SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS = "SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS",
    SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS_F = "SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS_F",
    SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS = "SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS",
    SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS_F = "SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS_F",
    COMPANY_COLLAPSABLE_TITLE = "COMPANY_COLLAPSABLE_TITLE",
    COMPANY_COLLAPSABLE_TITLE_F = "COMPANY_COLLAPSABLE_TITLE_F",
    DEPARTMENT_COLLAPSABLE_TITLE = "DEPARTMENT_COLLAPSABLE_TITLE",
}

export enum WizardLicenseTranslations {
    RESTAURANT = "RESTAURANT",
    HOTEL = "HOTEL",
    PROPERTY_MANAGEMENT = "PROPERTY_MANAGEMENT",
    WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL = "WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL",
}

export enum CustomerTypeTranslations {
    COMPANY = "COMPANY",
    COMPANIES = "COMPANIES",
    COMMUNITY = "COMMUNITY",
    COMMUNITIES = "COMMUNITIES",
}

export enum TaskTemporalityDateRangeTranslations {
    TASK_TEMPORALITY_DATE_RANGE_WEEKLY_LABEL = "TASK_TEMPORALITY_DATE_RANGE_WEEKLY_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_CUSTOM_LABEL = "TASK_TEMPORALITY_DATE_RANGE_CUSTOM_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_MONTLHY_FIRST_X_DAYS_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTLHY_FIRST_X_DAYS_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_MONTLHY_LAST_X_DAYS_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTLHY_LAST_X_DAYS_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_X_DAYS_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_X_DAYS_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_START_DATE_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_START_DATE_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_END_DATE_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_END_DATE_LABEL",
    TASK_TEMPORALITY_DATE_RANGE_WEEKLY_REPEAT_EVERY_LABEL_SINGULAR = "TASK_TEMPORALITY_DATE_RANGE_WEEKLY_REPEAT_EVERY_LABEL_SINGULAR",
    TASK_TEMPORALITY_DATE_RANGE_WEEKLY_REPEAT_EVERY_LABEL_PLURAL = "TASK_TEMPORALITY_DATE_RANGE_WEEKLY_REPEAT_EVERY_LABEL_PLURAL",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_REPEAT_EVERY_LABEL_SINGULAR = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_REPEAT_EVERY_LABEL_SINGULAR",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_REPEAT_EVERY_LABEL_PLURAL = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_REPEAT_EVERY_LABEL_PLURAL",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_FIRST_X_DAYS_LABEL_SINGULAR = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_FIRST_X_DAYS_LABEL_SINGULAR",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_FIRST_X_DAYS_LABEL_PLURAL = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_FIRST_X_DAYS_LABEL_PLURAL",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LAST_X_DAYS_LABEL_SINGULAR = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LAST_X_DAYS_LABEL_SINGULAR",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LAST_X_DAYS_LABEL_PLURAL = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LAST_X_DAYS_LABEL_PLURAL",
    TASK_TEMPORALITY_DATE_RANGE_MONTHLY_CUSTOM_LABEL = "TASK_TEMPORALITY_DATE_RANGE_MONTHLY_CUSTOM_LABEL",
}

export enum DynamicFieldTranslations {
    CORRECT = "CORRECT",
    INCORRECT = "INCORRECT",
}

export enum UserTranslations {
    USERS_LOGGED_ONCE_LABEL = "USERS_LOGGED_ONCE_LABEL",
    USERS_LOGGED_ONCE_ALL = "USERS_LOGGED__ONCEALL",
    USERS_LOGGED_ONCE = "USERS_LOGGED_ONCE",
    USERS_NOT_LOGGED_ONCE = "USERS_NOT_LOGGED_ONCE",
    USERS_PROFILE_LABEL = "USERS_PROFILE_LABEL",
    USERS_HAS_PROFILE_PICTURE = "USERS_HAS_PROFILE_PICTURE",
    USERS_NOT_PROFILE_PICTURE = "USERS_NOT_PROFILE_PICTURE",
}

export enum TaskFieldTranslations {
    TASKFIELD_ONLY_MIN_PLACEHOLDER = "TASKFIELD_ONLY_MIN_PLACEHOLDER",
    TASKFIELD_ONLY_MAX_PLACEHOLDER = "TASKFIELD_ONLY_MAX_PLACEHOLDER",
}

export enum AlertTranslation {
    ALERT_DELETE_TITLE = "ALERT_DELETE_TITLE",
    ALERT_DELETE_DESCRIPTION = "ALERT_DELETE_DESCRIPTION",
}
