import { useState } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { getInitials } from "app/helpers/getInitials";

export const useCompanies = () => {
    const [companies, setCompanies] = useState<OptionModel[]>([]);

    const getCompanies = async (onlyCompaniesWithQr?: boolean) => {
        const extraParams = new URLSearchParams();
        if (onlyCompaniesWithQr) extraParams.append("OnlyCompaniesWithQr", "true");
        const companiesSr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: extraParams.toString(),
        });

        if (!companiesSr.status()) {
            setCompanies([]);
            return;
        }
        setCompanies(
            companiesSr.data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            }))
        );
    };
    return { companies, getCompanies };
};
