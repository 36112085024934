import { TaskFieldTranslations, TranslationKeys } from "app/translation/translationKeys";

type IProps = {
    min: number | null;
    max: number | null;
    measureUnit: string;
    t: Function;
};

export const getAssetTaskfieldMinMaxPlaceholder = ({ max, measureUnit, min, t }: IProps) => {
    if (min != null && max != null) return `${min}/${max} ${measureUnit}`;
    if (min != null)
        return t(TaskFieldTranslations.TASKFIELD_ONLY_MIN_PLACEHOLDER).replace("{0}", `${min} ${measureUnit}`);
    if (max != null)
        return t(TaskFieldTranslations.TASKFIELD_ONLY_MAX_PLACEHOLDER).replace("{0}", `${max} ${measureUnit}`);
    return `${t(TranslationKeys.PLACEHOLDER_REPORT_DATA)}`;
};
